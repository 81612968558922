
@import "../_variables";
.main-content-header-right {
	display: none;
  
	.media {
	  label {
		margin-bottom: 2px;
		font-size: 10px;
		font-weight: 500;
		letter-spacing: .5px;
		text-transform: uppercase;
		color: $gray-500;
	  }
  
	  h6 {
		color: $gray-900;
		margin-bottom: 0;
	  }
  
	  + .media {
		margin-left: 20px;
		padding-left: 20px;
		border-left: 1px solid $gray-200;
	  }
	}
  
	.btn:first-of-type, .sp-container button:first-of-type {
	  margin-left: 30px;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-header-right {
	  display: flex;
	  align-items: center;
	}
  }
  
  .sp-container .main-content-header-right button:first-of-type {
	margin-left: 30px;
  }
  
  .main-content-header-right {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: 5px;
	}
  }
  
  .sp-container .main-content-header-right button + .btn, .main-content-header-right .sp-container .btn + button, .sp-container .main-content-header-right .btn + button, .main-content-header-right .sp-container button + button, .sp-container .main-content-header-right button + button {
	margin-left: 5px;
  }
  
  .main-content {
	&:after {
	  content: "";
	  height: 220px;
	  background: linear-gradient(45deg, #f33057, #3858f9);
	  position: absolute;
	  z-index: -1;
	  width: 100%;
	  top: 0;
	  left: 0;
	}
  
	&.horizontal-content {
	  padding-top: 0 !important;
	  margin-left: 0;
  
	  &:after {
		height: 260px;
	  }
	}
  
	.container, .container-fluid {
	  padding-left: 20px;
	  padding-right: 20px;
	}
  }
  
  @media (max-width: 575px) {
	.main-content-left-show {
	  overflow: hidden;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-content-left-show .main-content-left {
	  display: block;
	  position: fixed;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  background-color: $white;
	  z-index: 800;
	  border-right: 1px solid $gray-200;
	  box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
	  overflow-y: auto;
	}
  }
  
  @media (max-width: 575px) {
	.main-content-left-show .main-content-body {
	  display: none;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-content-body-show {
	  .main-header {
		.main-header-menu-icon {
		  display: none;
		}
  
		.main-header-left .main-header-arrow {
		  display: block;
		}
	  }
  
	  .main-content-left {
		display: none;
	  }
  
	  .main-content-body {
		display: block;
	  }
	}
  }
  
  @media (min-width: 992px) {
	.main-content .container {
	  padding: 0;
	}
  }
  
  @media (max-width: 991.98px) {
	.main-content .container {
	  max-width: none;
	}
  }
  
  .main-content-app {
	padding-top: 20px;
  
	.container, .container-fluid {
	  height: 100%;
	  padding: 0;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-app {
	  padding-top: 0;
	}
  }
  
  .main-content-left {
	width: 100%;
	position: relative;
	flex-shrink: 0;
  }
  
  @media (min-width: 992px) {
	.main-content-left {
	  display: block;
	}
  }
  
  .main-content-header {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
  
	.main-logo {
	  flex: 1;
	  text-align: center;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-header {
	  display: none;
	}
  }
  
  .main-content-body {
	flex: 1;
  }
  
  .main-content-right {
	padding-left: 25px;
	margin-left: 25px;
	width: 200px;
	display: none;
  }
  
  @media (min-width: 992px) {
	.main-content-right {
	  display: block;
	}
  }
  
  .main-content-breadcrumb {
	display: flex;
	align-items: center;
	font-size: 10px;
	font-weight: 500;
	font-family: "Roboto", sans-serif;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #24222f;
	margin-bottom: 10px;
  
	span {
	  position: relative;
  
	  + span::before {
		content: '\f3d1';
		font-family: 'Ionicons';
		font-size: 12px;
		font-weight: 300;
		display: inline;
		margin: 0 5px;
		color: $gray-400;
		line-height: 0;
		position: relative;
		top: 1px;
	  }
  
	  &:last-child {
		color:#24222f;
	  }
	}
  }
  
  .main-content-title {
	color: #423f54;
	font-weight: 500;
	font-size: 32px;
	text-indent: -1px;
	line-height: 1;
	position: relative;
	margin-bottom: 20px;
  }
  
  @media (min-width: 992px) {
	.main-content-title {
	  margin-bottom: 40px;
	}
  }
  
  .main-content-label, .card-table-two .card-title, .card-dashboard-eight .card-title {
	color: #302e40;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1;
	margin-bottom: 10px;
	letter-spacing: .2px;
  }
  
  .main-content-label-sm {
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	color: $gray-500;
	letter-spacing: .5px;
  }
  
  .main-content-text {
	font-size: 12px;
	line-height: 1.4;
	display: block;
	color: $secondary;
  }
  
  .main-home-slider {
	position: relative;
	z-index: 4;
	width: 100%;
	height: 600px;
	max-height: 1800px;
	overflow: hidden;
	background-color: #1904be;
  
	.chart-wrapper {
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: -20px;
	  z-index: 3;
	  display: flex;
	  align-items: flex-end;
	  opacity: .2;
  
	  .flot-chart {
		width: 100%;
		height: 400px;
	  }
	}
  
	.card-columns {
	  column-gap: 5px;
	  column-count: 3;
	  width: 500px;
	  transform: rotate(37deg);
	  position: absolute;
	  top: 9%;
	  left: 24%;
	  z-index: 4;
	  opacity: .25;
  
	  .card {
		position: relative;
		border-width: 0;
		background-color: transparent;
		margin-bottom: 5px;
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-home-slider {
	  height: 600px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-home-slider {
	  height: 100vh;
	  max-height: 780px;
	}
  }
  
  @media (min-width: 1440px) {
	.main-home-slider {
	  max-height: 900px;
	}
  }
  
  @media (min-width: 1680px) {
	.main-home-slider {
	  max-height: 1050px;
	}
  }
  
  @media (min-width: 1920px) {
	.main-home-slider {
	  max-height: 1200px;
	}
  }
  
  @media (min-width: 2560px) {
	.main-home-slider {
	  max-height: 1600px;
	}
  }
  
  @media (min-width: 2880px) {
	.main-home-slider {
	  max-height: 1800px;
	}
  }
  
  @media (min-width: 768px) {
	.main-home-slider .chart-wrapper {
	  opacity: 1;
	}
  }
  
  @media (min-width: 992px) {
	.main-home-slider .chart-wrapper .flot-chart {
	  height: 500px;
	}
  }
  
  @media (min-width: 576px) {
	.main-home-slider .card-columns {
	  width: 120%;
	  left: 16%;
	  transform: rotate(44deg);
	}
  }
  
  @media (min-width: 768px) {
	.main-home-slider .card-columns {
	  transform: rotate(45deg);
	  width: 100%;
	  top: 0;
	  left: auto;
	  right: -45%;
	  opacity: 1;
	}
  }
  
  @media (min-width: 992px) {
	.main-home-slider .card-columns {
	  width: 80%;
	  top: -10%;
	  right: -30%;
	  column-gap: 10px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-home-slider .card-columns {
	  width: 70%;
	  right: -15%;
	  top: -15%;
	}
  }
  
  @media (min-width: 1300px) {
	.main-home-slider .card-columns {
	  right: -5%;
	}
  }
  
  @media (min-width: 992px) {
	.main-home-slider .card-columns .card {
	  margin-bottom: 10px;
	}
  }
  
  .main-home-content {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 5;
	color: $white-7;
	right: 20px;
  
	.main-logo {
	  font-size: 30px;
	  font-weight: 700;
	  display: flex;
	  line-height: 1;
	  color: $white;
	  margin-bottom: 5px;
	  text-indent: -1px;
	  color: $white;
	  letter-spacing: -.5px;
	}
  
	h5 {
	  font-size: 12px;
	  font-weight: 400;
	  letter-spacing: .2px;
	  margin-bottom: 25px;
	}
  
	.logo-group {
	  margin-bottom: 60px;
	  display: flex;
  
	  i {
		font-weight: 400;
		font-size: 32px;
		line-height: .2;
  
		+ i {
		  margin-left: 10px;
		}
	  }
	}
  
	h1 {
	  color: $white;
	  font-weight: 300;
	  font-size: 24px;
	  margin-bottom: 20px;
	}
  
	p {
	  &:first-of-type {
		font-size: 14px;
		line-height: 1.7;
		margin-bottom: 25px;
	  }
  
	  &:last-of-type {
		display: flex;
		margin-bottom: 100px;
  
		.btn, .sp-container button {
		  flex: 1;
		  text-transform: uppercase;
		  font-size: 11px;
		  font-weight: 500;
		  min-height: inherit;
		  letter-spacing: 1px;
		  padding: 10px 15px;
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-home-content {
	  top: 40px;
	  left: 40px;
	  right: auto;
	  width: 400px;
	}
  }
  
  @media (min-width: 768px) {
	.main-home-content {
	  width: 410px;
	}
  }
  
  @media (min-width: 576px) {
	.main-home-content .main-logo {
	  font-size: 36px;
	  letter-spacing: -1px;
	}
  }
  
  @media (min-width: 576px) {
	.main-home-content h5 {
	  font-size: 15px;
	}
  }
  
  @media (min-width: 576px) {
	.main-home-content h1 {
	  font-size: 2.03125rem;
	}
  }
  
  .sp-container .main-home-content p:last-of-type button {
	flex: 1;
	text-transform: uppercase;
	font-size: 11px;
	font-weight: 500;
	min-height: inherit;
	letter-spacing: 1px;
	padding: 10px 15px;
  }
  
  @media (min-width: 768px) {
	.main-home-content p:last-of-type {
	  .btn, .sp-container button {
		padding: 10px 25px;
		flex: none;
	  }
	}
  
	.sp-container .main-home-content p:last-of-type button {
	  padding: 10px 25px;
	  flex: none;
	}
  }
  
  @media (min-width: 992px) {
	.main-home-content p:last-of-type {
	  .btn, .sp-container button {
		flex: 1;
	  }
	}
  
	.sp-container .main-home-content p:last-of-type button {
	  flex: 1;
	}
  }
  
  .main-home-content p:last-of-type {
	.btn + .btn, .sp-container button + .btn {
	  margin-left: 5px;
	}
  }
  
  .sp-container .main-home-content p:last-of-type button + .btn, .main-home-content p:last-of-type .sp-container .btn + button, .sp-container .main-home-content p:last-of-type .btn + button, .main-home-content p:last-of-type .sp-container button + button, .sp-container .main-home-content p:last-of-type button + button {
	margin-left: 5px;
  }
  
  .main-home-content nav:last-child {
	display: flex;
  
	a {
	  font-size: 24px;
	  color: $white-5;
  
	  &:hover, &:focus {
		color: $white;
	  }
  
	  + a {
		margin-left: 8px;
	  }
	}
  }
  
  .main-content-choose-demo {
	position: relative;
	z-index: 5;
	background-color: #0f0373;
	color: $white-5;
	padding: 0;
  
	.container {
	  display: block;
	  padding: 20px;
	}
  
	.title-label {
	  text-transform: uppercase;
	  letter-spacing: 1px;
	  font-size: 10px;
	  color: $primary;
	  margin-bottom: 3px;
	}
  
	.title {
	  font-size: 20px;
	  font-weight: 400;
	  color: $white;
	  letter-spacing: -.5px;
	  margin-bottom: 10px;
	}
  
	.title-text {
	  font-weight: 300;
	  margin-bottom: 20px;
	}
  
	.card {
	  border-width: 0;
	  margin-bottom: 10px;
	  height: 200px;
	  overflow: hidden;
	  position: relative;
  
	  img {
		transition: all 0.2s ease-in-out;
	  }
  
	  figure {
		margin-bottom: 0;
  
		&:hover img, &:focus img {
		  transform: scale(1.75, 1.75);
		}
  
		&:hover figcaption, &:focus figcaption {
		  opacity: 1;
		}
	  }
  
	  figcaption {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(25, 4, 190, 0.75);
		opacity: 0;
		transition: all 0.2s ease-in-out;
  
		.btn, .sp-container button {
		  text-transform: uppercase;
		  letter-spacing: 1.5px;
		  font-size: 11px;
		  font-weight: 500;
		  min-height: inherit;
		  padding: 10px 20px;
		  border-radius: 2px;
		}
	  }
	}
  }
  
  @media (min-width: 576px) {
	.main-content-choose-demo .container {
	  padding: 40px;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-choose-demo .container {
	  padding: 100px 40px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-content-choose-demo .container {
	  padding: 100px 0;
	}
  }
  
  @media (min-width: 768px) {
	.main-content-choose-demo .title-label {
	  font-size: 11px;
	}
  }
  
  @media (min-width: 768px) {
	.main-content-choose-demo .title {
	  font-size: 30px;
	}
  }
  
  @media (min-width: 576px) {
	.main-content-choose-demo .title-text {
	  margin-bottom: 40px;
	}
  }
  
  @media (min-width: 768px) {
	.main-content-choose-demo .title-text {
	  font-size: 16px;
	}
  }
  
  @media (min-width: 992px) {
	.main-content-choose-demo .title-text {
	  margin-bottom: 60px;
	}
  }
  
  @media (min-width: 576px) {
	.main-content-choose-demo .card {
	  height: 155px;
	}
  }
  
  @media (min-width: 768px) {
	.main-content-choose-demo .card {
	  height: 215px;
	  margin-bottom: 15px;
	}
  }
  
  @media (min-width: 1200px) {
	.main-content-choose-demo .card {
	  height: 255px;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-content-choose-demo .card img {
	  transition: none;
	}
  }
  
  @media (prefers-reduced-motion: reduce) {
	.main-content-choose-demo .card figcaption {
	  transition: none;
	}
  }
  
  .sp-container .main-content-choose-demo .card figcaption button {
	text-transform: uppercase;
	letter-spacing: 1.5px;
	font-size: 11px;
	font-weight: 500;
	min-height: inherit;
	padding: 10px 20px;
	border-radius: 2px;
  }
  
  .main-content-choose-demo {
	.card.coming-soon {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  color: $gray-900;
	  background-color: $gray-200;
  
	  .typcn {
		line-height: .9;
		font-size: 48px;
		margin-bottom: 10px;
	  }
  
	  h6 {
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: 800;
		letter-spacing: 1px;
	  }
	}
  
	.main-content-label, .card-table-two .card-title {
	  color: $secondary;
	  margin-bottom: 5px;
	  letter-spacing: 1px;
	}
  }
  
  .card-table-two .main-content-choose-demo .card-title, .main-content-choose-demo .card-dashboard-eight .card-title, .card-dashboard-eight .main-content-choose-demo .card-title {
	color: $secondary;
	margin-bottom: 5px;
	letter-spacing: 1px;
  }
  
  .main-content-choose-demo .main-content-title {
	font-size: 15px;
	font-weight: 400;
	margin-bottom: 0;
	color: $white;
  }
  
  @media (min-width: 768px) {
	.main-content-choose-demo .main-content-title {
	  font-size: 17px;
	}
  }
  
  .main-footer-demo {
	background-color: #0c025b;
	color: $white-4;
	height: 60px;
  
	.container {
	  border-top-width: 0;
	}
  
	a {
	  color: $white-5;
  
	  &:hover, &:focus {
		color: $white;
	  }
	}
  }
  
  .main-content-left-components {
	border-right: 1px solid $gray-200;
	width: 200px;
  
	.component-item {
	  label {
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: .5px;
  
		~ label {
		  margin-top: 30px;
		}
	  }
  
	  .nav-link {
		padding: 0;
		display: block;
		font-size: 13px;
		color: $gray-700;
  
		+ .nav-link {
		  margin-top: 5px;
		}
  
		&:hover {
		  color: $primary;
		}
  
		&.active {
		  color: $primary;
		  font-weight: 500;
		}
	  }
	}
  }