
@import "../_variables";
/* ###### 9.9 Width   ###### */

.wd-1 {
	width: 1px;
  }
  
  .wd-2 {
	width: 2px;
  }
  
  .wd-3 {
	width: 3px;
  }
  
  .wd-4 {
	width: 4px;
  }
  
  .wd-6 {
	width: 6px;
  }
  
  .wd-7 {
	width: 7px;
  }
  
  .wd-8 {
	width: 8px;
  }
  
  .wd-9 {
	width: 9px;
  }
  
  .wd-280 {
	width: 280px;
  }
  
  .wd-5 {
	width: 5px;
  }
  
  .wd-5p {
	width: 5%;
  }
  
  .mx-wd-5p {
	max-width: 5%;
  }
  
  .mn-wd-5p {
	min-width: 5%;
  }
  
  .wd-5-f {
	width: 5px !important;
  }
  
  .wd-5p-f {
	width: 5% !important;
  }
  
  .mx-wd-5p-f {
	max-width: 5% !important;
  }
  
  .mn-wd-5p-f {
	min-width: 5% !important;
  }
  
  .wd-10 {
	width: 10px;
  }
  
  .wd-10p {
	width: 10%;
  }
  
  .mx-wd-10p {
	max-width: 10%;
  }
  
  .mn-wd-10p {
	min-width: 10%;
  }
  
  .wd-10-f {
	width: 10px !important;
  }
  
  .wd-10p-f {
	width: 10% !important;
  }
  
  .mx-wd-10p-f {
	max-width: 10% !important;
  }
  
  .mn-wd-10p-f {
	min-width: 10% !important;
  }
  
  .wd-15 {
	width: 15px;
  }
  
  .wd-15p {
	width: 15%;
  }
  
  .mx-wd-15p {
	max-width: 15%;
  }
  
  .mn-wd-15p {
	min-width: 15%;
  }
  
  .wd-15-f {
	width: 15px !important;
  }
  
  .wd-15p-f {
	width: 15% !important;
  }
  
  .mx-wd-15p-f {
	max-width: 15% !important;
  }
  
  .mn-wd-15p-f {
	min-width: 15% !important;
  }
  
  .wd-20 {
	width: 20px;
  }
  
  .wd-20p {
	width: 20%;
  }
  
  .mx-wd-20p {
	max-width: 20%;
  }
  
  .mn-wd-20p {
	min-width: 20%;
  }
  
  .wd-20-f {
	width: 20px !important;
  }
  
  .wd-20p-f {
	width: 20% !important;
  }
  
  .mx-wd-20p-f {
	max-width: 20% !important;
  }
  
  .mn-wd-20p-f {
	min-width: 20% !important;
  }
  
  .wd-25 {
	width: 25px;
  }
  
  .wd-25p {
	width: 25%;
  }
  
  .mx-wd-25p {
	max-width: 25%;
  }
  
  .mn-wd-25p {
	min-width: 25%;
  }
  
  .wd-25-f {
	width: 25px !important;
  }
  
  .wd-25p-f {
	width: 25% !important;
  }
  
  .mx-wd-25p-f {
	max-width: 25% !important;
  }
  
  .mn-wd-25p-f {
	min-width: 25% !important;
  }
  
  .wd-30 {
	width: 30px;
  }
  
  .wd-30p {
	width: 30%;
  }
  
  .mx-wd-30p {
	max-width: 30%;
  }
  
  .mn-wd-30p {
	min-width: 30%;
  }
  
  .wd-30-f {
	width: 30px !important;
  }
  
  .wd-30p-f {
	width: 30% !important;
  }
  
  .mx-wd-30p-f {
	max-width: 30% !important;
  }
  
  .mn-wd-30p-f {
	min-width: 30% !important;
  }
  
  .wd-35 {
	width: 35px;
  }
  
  .wd-35p {
	width: 35%;
  }
  
  .mx-wd-35p {
	max-width: 35%;
  }
  
  .mn-wd-35p {
	min-width: 35%;
  }
  
  .wd-35-f {
	width: 35px !important;
  }
  
  .wd-35p-f {
	width: 35% !important;
  }
  
  .mx-wd-35p-f {
	max-width: 35% !important;
  }
  
  .mn-wd-35p-f {
	min-width: 35% !important;
  }
  
  .wd-40 {
	width: 40px;
  }
  
  .wd-40p {
	width: 40%;
  }
  
  .mx-wd-40p {
	max-width: 40%;
  }
  
  .mn-wd-40p {
	min-width: 40%;
  }
  
  .wd-40-f {
	width: 40px !important;
  }
  
  .wd-40p-f {
	width: 40% !important;
  }
  
  .mx-wd-40p-f {
	max-width: 40% !important;
  }
  
  .mn-wd-40p-f {
	min-width: 40% !important;
  }
  
  .wd-45 {
	width: 45px;
  }
  
  .wd-45p {
	width: 45%;
  }
  
  .mx-wd-45p {
	max-width: 45%;
  }
  
  .mn-wd-45p {
	min-width: 45%;
  }
  
  .wd-45-f {
	width: 45px !important;
  }
  
  .wd-45p-f {
	width: 45% !important;
  }
  
  .mx-wd-45p-f {
	max-width: 45% !important;
  }
  
  .mn-wd-45p-f {
	min-width: 45% !important;
  }
  
  .wd-50 {
	width: 50px;
  }
  
  .wd-50p {
	width: 50%;
  }
  
  .mx-wd-50p {
	max-width: 50%;
  }
  
  .mn-wd-50p {
	min-width: 50%;
  }
  
  .wd-50-f {
	width: 50px !important;
  }
  
  .wd-50p-f {
	width: 50% !important;
  }
  
  .mx-wd-50p-f {
	max-width: 50% !important;
  }
  
  .mn-wd-50p-f {
	min-width: 50% !important;
  }
  
  .wd-55 {
	width: 55px;
  }
  
  .wd-55p {
	width: 55%;
  }
  
  .mx-wd-55p {
	max-width: 55%;
  }
  
  .mn-wd-55p {
	min-width: 55%;
  }
  
  .wd-55-f {
	width: 55px !important;
  }
  
  .wd-55p-f {
	width: 55% !important;
  }
  
  .mx-wd-55p-f {
	max-width: 55% !important;
  }
  
  .mn-wd-55p-f {
	min-width: 55% !important;
  }
  
  .wd-60 {
	width: 60px;
  }
  
  .wd-60p {
	width: 60%;
  }
  
  .mx-wd-60p {
	max-width: 60%;
  }
  
  .mn-wd-60p {
	min-width: 60%;
  }
  
  .wd-60-f {
	width: 60px !important;
  }
  
  .wd-60p-f {
	width: 60% !important;
  }
  
  .mx-wd-60p-f {
	max-width: 60% !important;
  }
  
  .mn-wd-60p-f {
	min-width: 60% !important;
  }
  
  .wd-65 {
	width: 65px;
  }
  
  .wd-65p {
	width: 65%;
  }
  
  .mx-wd-65p {
	max-width: 65%;
  }
  
  .mn-wd-65p {
	min-width: 65%;
  }
  
  .wd-65-f {
	width: 65px !important;
  }
  
  .wd-65p-f {
	width: 65% !important;
  }
  
  .mx-wd-65p-f {
	max-width: 65% !important;
  }
  
  .mn-wd-65p-f {
	min-width: 65% !important;
  }
  
  .wd-70 {
	width: 70px;
  }
  
  .wd-70p {
	width: 70%;
  }
  
  .mx-wd-70p {
	max-width: 70%;
  }
  
  .mn-wd-70p {
	min-width: 70%;
  }
  
  .wd-70-f {
	width: 70px !important;
  }
  
  .wd-70p-f {
	width: 70% !important;
  }
  
  .mx-wd-70p-f {
	max-width: 70% !important;
  }
  
  .mn-wd-70p-f {
	min-width: 70% !important;
  }
  
  .wd-75 {
	width: 75px;
  }
  
  .wd-75p {
	width: 75%;
  }
  
  .mx-wd-75p {
	max-width: 75%;
  }
  
  .mn-wd-75p {
	min-width: 75%;
  }
  
  .wd-75-f {
	width: 75px !important;
  }
  
  .wd-75p-f {
	width: 75% !important;
  }
  
  .mx-wd-75p-f {
	max-width: 75% !important;
  }
  
  .mn-wd-75p-f {
	min-width: 75% !important;
  }
  
  .wd-80 {
	width: 80px;
  }
  
  .wd-80p {
	width: 80%;
  }
  
  .mx-wd-80p {
	max-width: 80%;
  }
  
  .mn-wd-80p {
	min-width: 80%;
  }
  
  .wd-80-f {
	width: 80px !important;
  }
  
  .wd-80p-f {
	width: 80% !important;
  }
  
  .mx-wd-80p-f {
	max-width: 80% !important;
  }
  
  .mn-wd-80p-f {
	min-width: 80% !important;
  }
  
  .wd-85 {
	width: 85px;
  }
  
  .wd-85p {
	width: 85%;
  }
  
  .mx-wd-85p {
	max-width: 85%;
  }
  
  .mn-wd-85p {
	min-width: 85%;
  }
  
  .wd-85-f {
	width: 85px !important;
  }
  
  .wd-85p-f {
	width: 85% !important;
  }
  
  .mx-wd-85p-f {
	max-width: 85% !important;
  }
  
  .mn-wd-85p-f {
	min-width: 85% !important;
  }
  
  .wd-90 {
	width: 90px;
  }
  
  .wd-90p {
	width: 90%;
  }
  
  .mx-wd-90p {
	max-width: 90%;
  }
  
  .mn-wd-90p {
	min-width: 90%;
  }
  
  .wd-90-f {
	width: 90px !important;
  }
  
  .wd-90p-f {
	width: 90% !important;
  }
  
  .mx-wd-90p-f {
	max-width: 90% !important;
  }
  
  .mn-wd-90p-f {
	min-width: 90% !important;
  }
  
  .wd-95 {
	width: 95px;
  }
  
  .wd-95p {
	width: 95%;
  }
  
  .mx-wd-95p {
	max-width: 95%;
  }
  
  .mn-wd-95p {
	min-width: 95%;
  }
  
  .wd-95-f {
	width: 95px !important;
  }
  
  .wd-95p-f {
	width: 95% !important;
  }
  
  .mx-wd-95p-f {
	max-width: 95% !important;
  }
  
  .mn-wd-95p-f {
	min-width: 95% !important;
  }
  
  .wd-100 {
	width: 100px !important;
  }
  
  .wd-100p {
	width: 100%;
  }
  
  .mx-wd-100p {
	max-width: 100%;
  }
  
  .mn-wd-100p {
	min-width: 100%;
  }
  
  .wd-100-f {
	width: 100px !important;
  }
  
  .wd-100p-f {
	width: 100% !important;
  }
  
  .mx-wd-100p-f {
	max-width: 100% !important;
  }
  
  .mn-wd-100p-f {
	min-width: 100% !important;
  }
  
  .wd-150 {
	width: 150px;
  }
  
  .wd-150-f {
	width: 150px !important;
  }
  
  .wd-200 {
	width: 200px;
  }
  
  .wd-200-f {
	width: 200px !important;
  }
  
  .wd-230 {
	width: 230px;
  }
  
  .wd-250 {
	width: 250px;
  }
  
  .wd-250-f {
	width: 250px !important;
  }
  
  .wd-300 {
	width: 300px;
  }
  
  .wd-300-f {
	width: 300px !important;
  }
  
  .wd-350 {
	width: 350px;
  }
  
  .wd-350-f {
	width: 350px !important;
  }
  
  .wd-400 {
	width: 400px;
  }
  
  .wd-400-f {
	width: 400px !important;
  }
  
  .wd-450 {
	width: 450px;
  }
  
  .wd-450-f {
	width: 450px !important;
  }
  
  .wd-500 {
	width: 500px;
  }
  
  .wd-500-f {
	width: 500px !important;
  }
  
  .wd-550 {
	width: 550px;
  }
  
  .wd-550-f {
	width: 550px !important;
  }
  
  .wd-600 {
	width: 600px;
  }
  
  .wd-600-f {
	width: 600px !important;
  }
  
  .wd-650 {
	width: 650px;
  }
  
  .wd-650-f {
	width: 650px !important;
  }
  
  .wd-700 {
	width: 700px;
  }
  
  .wd-700-f {
	width: 700px !important;
  }
  
  .wd-750 {
	width: 750px;
  }
  
  .wd-750-f {
	width: 750px !important;
  }
  
  .wd-800 {
	width: 800px;
  }
  
  .wd-800-f {
	width: 800px !important;
  }
  
  .wd-850 {
	width: 850px;
  }
  
  .wd-850-f {
	width: 850px !important;
  }
  
  .wd-900 {
	width: 900px;
  }
  
  .wd-900-f {
	width: 900px !important;
  }
  
  .wd-950 {
	width: 950px;
  }
  
  .wd-950-f {
	width: 950px !important;
  }
  
  .wd-1000 {
	width: 1000px;
  }
  
  .wd-1000-f {
	width: 1000px !important;
  }
  
  @media (min-width: 480px) {
	.wd-xs-5 {
	  width: 5px;
	}
  
	.wd-xs-5p {
	  width: 5%;
	}
  
	.mx-wd-xs-5p {
	  max-width: 5%;
	}
  
	.mn-wd-xs-5p {
	  min-width: 5%;
	}
  
	.wd-xs-5-f {
	  width: 5px !important;
	}
  
	.wd-xs-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-xs-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-xs-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-xs-10 {
	  width: 10px;
	}
  
	.wd-xs-10p {
	  width: 10%;
	}
  
	.mx-wd-xs-10p {
	  max-width: 10%;
	}
  
	.mn-wd-xs-10p {
	  min-width: 10%;
	}
  
	.wd-xs-10-f {
	  width: 10px !important;
	}
  
	.wd-xs-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-xs-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-xs-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-xs-15 {
	  width: 15px;
	}
  
	.wd-xs-15p {
	  width: 15%;
	}
  
	.mx-wd-xs-15p {
	  max-width: 15%;
	}
  
	.mn-wd-xs-15p {
	  min-width: 15%;
	}
  
	.wd-xs-15-f {
	  width: 15px !important;
	}
  
	.wd-xs-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-xs-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-xs-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-xs-20 {
	  width: 20px;
	}
  
	.wd-xs-20p {
	  width: 20%;
	}
  
	.mx-wd-xs-20p {
	  max-width: 20%;
	}
  
	.mn-wd-xs-20p {
	  min-width: 20%;
	}
  
	.wd-xs-20-f {
	  width: 20px !important;
	}
  
	.wd-xs-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-xs-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-xs-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-xs-25 {
	  width: 25px;
	}
  
	.wd-xs-25p {
	  width: 25%;
	}
  
	.mx-wd-xs-25p {
	  max-width: 25%;
	}
  
	.mn-wd-xs-25p {
	  min-width: 25%;
	}
  
	.wd-xs-25-f {
	  width: 25px !important;
	}
  
	.wd-xs-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-xs-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-xs-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-xs-30 {
	  width: 30px;
	}
  
	.wd-xs-30p {
	  width: 30%;
	}
  
	.mx-wd-xs-30p {
	  max-width: 30%;
	}
  
	.mn-wd-xs-30p {
	  min-width: 30%;
	}
  
	.wd-xs-30-f {
	  width: 30px !important;
	}
  
	.wd-xs-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-xs-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-xs-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-xs-35 {
	  width: 35px;
	}
  
	.wd-xs-35p {
	  width: 35%;
	}
  
	.mx-wd-xs-35p {
	  max-width: 35%;
	}
  
	.mn-wd-xs-35p {
	  min-width: 35%;
	}
  
	.wd-xs-35-f {
	  width: 35px !important;
	}
  
	.wd-xs-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-xs-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-xs-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-xs-40 {
	  width: 40px;
	}
  
	.wd-xs-40p {
	  width: 40%;
	}
  
	.mx-wd-xs-40p {
	  max-width: 40%;
	}
  
	.mn-wd-xs-40p {
	  min-width: 40%;
	}
  
	.wd-xs-40-f {
	  width: 40px !important;
	}
  
	.wd-xs-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-xs-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-xs-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-xs-45 {
	  width: 45px;
	}
  
	.wd-xs-45p {
	  width: 45%;
	}
  
	.mx-wd-xs-45p {
	  max-width: 45%;
	}
  
	.mn-wd-xs-45p {
	  min-width: 45%;
	}
  
	.wd-xs-45-f {
	  width: 45px !important;
	}
  
	.wd-xs-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-xs-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-xs-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-xs-50 {
	  width: 50px;
	}
  
	.wd-xs-50p {
	  width: 50%;
	}
  
	.mx-wd-xs-50p {
	  max-width: 50%;
	}
  
	.mn-wd-xs-50p {
	  min-width: 50%;
	}
  
	.wd-xs-50-f {
	  width: 50px !important;
	}
  
	.wd-xs-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-xs-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-xs-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-xs-55 {
	  width: 55px;
	}
  
	.wd-xs-55p {
	  width: 55%;
	}
  
	.mx-wd-xs-55p {
	  max-width: 55%;
	}
  
	.mn-wd-xs-55p {
	  min-width: 55%;
	}
  
	.wd-xs-55-f {
	  width: 55px !important;
	}
  
	.wd-xs-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-xs-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-xs-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-xs-60 {
	  width: 60px;
	}
  
	.wd-xs-60p {
	  width: 60%;
	}
  
	.mx-wd-xs-60p {
	  max-width: 60%;
	}
  
	.mn-wd-xs-60p {
	  min-width: 60%;
	}
  
	.wd-xs-60-f {
	  width: 60px !important;
	}
  
	.wd-xs-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-xs-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-xs-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-xs-65 {
	  width: 65px;
	}
  
	.wd-xs-65p {
	  width: 65%;
	}
  
	.mx-wd-xs-65p {
	  max-width: 65%;
	}
  
	.mn-wd-xs-65p {
	  min-width: 65%;
	}
  
	.wd-xs-65-f {
	  width: 65px !important;
	}
  
	.wd-xs-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-xs-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-xs-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-xs-70 {
	  width: 70px;
	}
  
	.wd-xs-70p {
	  width: 70%;
	}
  
	.mx-wd-xs-70p {
	  max-width: 70%;
	}
  
	.mn-wd-xs-70p {
	  min-width: 70%;
	}
  
	.wd-xs-70-f {
	  width: 70px !important;
	}
  
	.wd-xs-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-xs-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-xs-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-xs-75 {
	  width: 75px;
	}
  
	.wd-xs-75p {
	  width: 75%;
	}
  
	.mx-wd-xs-75p {
	  max-width: 75%;
	}
  
	.mn-wd-xs-75p {
	  min-width: 75%;
	}
  
	.wd-xs-75-f {
	  width: 75px !important;
	}
  
	.wd-xs-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-xs-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-xs-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-xs-80 {
	  width: 80px;
	}
  
	.wd-xs-80p {
	  width: 80%;
	}
  
	.mx-wd-xs-80p {
	  max-width: 80%;
	}
  
	.mn-wd-xs-80p {
	  min-width: 80%;
	}
  
	.wd-xs-80-f {
	  width: 80px !important;
	}
  
	.wd-xs-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-xs-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-xs-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-xs-85 {
	  width: 85px;
	}
  
	.wd-xs-85p {
	  width: 85%;
	}
  
	.mx-wd-xs-85p {
	  max-width: 85%;
	}
  
	.mn-wd-xs-85p {
	  min-width: 85%;
	}
  
	.wd-xs-85-f {
	  width: 85px !important;
	}
  
	.wd-xs-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-xs-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-xs-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-xs-90 {
	  width: 90px;
	}
  
	.wd-xs-90p {
	  width: 90%;
	}
  
	.mx-wd-xs-90p {
	  max-width: 90%;
	}
  
	.mn-wd-xs-90p {
	  min-width: 90%;
	}
  
	.wd-xs-90-f {
	  width: 90px !important;
	}
  
	.wd-xs-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-xs-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-xs-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-xs-95 {
	  width: 95px;
	}
  
	.wd-xs-95p {
	  width: 95%;
	}
  
	.mx-wd-xs-95p {
	  max-width: 95%;
	}
  
	.mn-wd-xs-95p {
	  min-width: 95%;
	}
  
	.wd-xs-95-f {
	  width: 95px !important;
	}
  
	.wd-xs-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-xs-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-xs-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-xs-100 {
	  width: 100px;
	}
  
	.wd-xs-100p {
	  width: 100%;
	}
  
	.mx-wd-xs-100p {
	  max-width: 100%;
	}
  
	.mn-wd-xs-100p {
	  min-width: 100%;
	}
  
	.wd-xs-100-f {
	  width: 100px !important;
	}
  
	.wd-xs-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-xs-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-xs-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-xs-150 {
	  width: 150px;
	}
  
	.wd-xs-150p {
	  width: 150%;
	}
  
	.mx-wd-xs-150p {
	  max-width: 150%;
	}
  
	.mn-wd-xs-150p {
	  min-width: 150%;
	}
  
	.wd-xs-150-f {
	  width: 150px !important;
	}
  
	.wd-xs-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-xs-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-xs-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-xs-200 {
	  width: 200px;
	}
  
	.wd-xs-200p {
	  width: 200%;
	}
  
	.mx-wd-xs-200p {
	  max-width: 200%;
	}
  
	.mn-wd-xs-200p {
	  min-width: 200%;
	}
  
	.wd-xs-200-f {
	  width: 200px !important;
	}
  
	.wd-xs-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-xs-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-xs-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-xs-250 {
	  width: 250px;
	}
  
	.wd-xs-250p {
	  width: 250%;
	}
  
	.mx-wd-xs-250p {
	  max-width: 250%;
	}
  
	.mn-wd-xs-250p {
	  min-width: 250%;
	}
  
	.wd-xs-250-f {
	  width: 250px !important;
	}
  
	.wd-xs-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-xs-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-xs-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-xs-300 {
	  width: 300px;
	}
  
	.wd-xs-300p {
	  width: 300%;
	}
  
	.mx-wd-xs-300p {
	  max-width: 300%;
	}
  
	.mn-wd-xs-300p {
	  min-width: 300%;
	}
  
	.wd-xs-300-f {
	  width: 300px !important;
	}
  
	.wd-xs-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-xs-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-xs-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-xs-350 {
	  width: 350px;
	}
  
	.wd-xs-350p {
	  width: 350%;
	}
  
	.mx-wd-xs-350p {
	  max-width: 350%;
	}
  
	.mn-wd-xs-350p {
	  min-width: 350%;
	}
  
	.wd-xs-350-f {
	  width: 350px !important;
	}
  
	.wd-xs-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-xs-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-xs-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-xs-400 {
	  width: 400px;
	}
  
	.wd-xs-400p {
	  width: 400%;
	}
  
	.mx-wd-xs-400p {
	  max-width: 400%;
	}
  
	.mn-wd-xs-400p {
	  min-width: 400%;
	}
  
	.wd-xs-400-f {
	  width: 400px !important;
	}
  
	.wd-xs-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-xs-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-xs-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-xs-450 {
	  width: 450px;
	}
  
	.wd-xs-450p {
	  width: 450%;
	}
  
	.mx-wd-xs-450p {
	  max-width: 450%;
	}
  
	.mn-wd-xs-450p {
	  min-width: 450%;
	}
  
	.wd-xs-450-f {
	  width: 450px !important;
	}
  
	.wd-xs-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-xs-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-xs-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-xs-500 {
	  width: 500px;
	}
  
	.wd-xs-500p {
	  width: 500%;
	}
  
	.mx-wd-xs-500p {
	  max-width: 500%;
	}
  
	.mn-wd-xs-500p {
	  min-width: 500%;
	}
  
	.wd-xs-500-f {
	  width: 500px !important;
	}
  
	.wd-xs-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-xs-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-xs-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-xs-550 {
	  width: 550px;
	}
  
	.wd-xs-550p {
	  width: 550%;
	}
  
	.mx-wd-xs-550p {
	  max-width: 550%;
	}
  
	.mn-wd-xs-550p {
	  min-width: 550%;
	}
  
	.wd-xs-550-f {
	  width: 550px !important;
	}
  
	.wd-xs-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-xs-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-xs-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-xs-600 {
	  width: 600px;
	}
  
	.wd-xs-600p {
	  width: 600%;
	}
  
	.mx-wd-xs-600p {
	  max-width: 600%;
	}
  
	.mn-wd-xs-600p {
	  min-width: 600%;
	}
  
	.wd-xs-600-f {
	  width: 600px !important;
	}
  
	.wd-xs-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-xs-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-xs-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-xs-650 {
	  width: 650px;
	}
  
	.wd-xs-650p {
	  width: 650%;
	}
  
	.mx-wd-xs-650p {
	  max-width: 650%;
	}
  
	.mn-wd-xs-650p {
	  min-width: 650%;
	}
  
	.wd-xs-650-f {
	  width: 650px !important;
	}
  
	.wd-xs-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-xs-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-xs-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-xs-700 {
	  width: 700px;
	}
  
	.wd-xs-700p {
	  width: 700%;
	}
  
	.mx-wd-xs-700p {
	  max-width: 700%;
	}
  
	.mn-wd-xs-700p {
	  min-width: 700%;
	}
  
	.wd-xs-700-f {
	  width: 700px !important;
	}
  
	.wd-xs-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-xs-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-xs-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-xs-750 {
	  width: 750px;
	}
  
	.wd-xs-750p {
	  width: 750%;
	}
  
	.mx-wd-xs-750p {
	  max-width: 750%;
	}
  
	.mn-wd-xs-750p {
	  min-width: 750%;
	}
  
	.wd-xs-750-f {
	  width: 750px !important;
	}
  
	.wd-xs-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-xs-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-xs-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-xs-800 {
	  width: 800px;
	}
  
	.wd-xs-800p {
	  width: 800%;
	}
  
	.mx-wd-xs-800p {
	  max-width: 800%;
	}
  
	.mn-wd-xs-800p {
	  min-width: 800%;
	}
  
	.wd-xs-800-f {
	  width: 800px !important;
	}
  
	.wd-xs-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-xs-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-xs-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-xs-850 {
	  width: 850px;
	}
  
	.wd-xs-850p {
	  width: 850%;
	}
  
	.mx-wd-xs-850p {
	  max-width: 850%;
	}
  
	.mn-wd-xs-850p {
	  min-width: 850%;
	}
  
	.wd-xs-850-f {
	  width: 850px !important;
	}
  
	.wd-xs-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-xs-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-xs-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-xs-900 {
	  width: 900px;
	}
  
	.wd-xs-900p {
	  width: 900%;
	}
  
	.mx-wd-xs-900p {
	  max-width: 900%;
	}
  
	.mn-wd-xs-900p {
	  min-width: 900%;
	}
  
	.wd-xs-900-f {
	  width: 900px !important;
	}
  
	.wd-xs-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-xs-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-xs-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-xs-950 {
	  width: 950px;
	}
  
	.wd-xs-950p {
	  width: 950%;
	}
  
	.mx-wd-xs-950p {
	  max-width: 950%;
	}
  
	.mn-wd-xs-950p {
	  min-width: 950%;
	}
  
	.wd-xs-950-f {
	  width: 950px !important;
	}
  
	.wd-xs-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-xs-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-xs-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-xs-1000 {
	  width: 1000px;
	}
  
	.wd-xs-1000p {
	  width: 1000%;
	}
  
	.mx-wd-xs-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-xs-1000p {
	  min-width: 1000%;
	}
  
	.wd-xs-1000-f {
	  width: 1000px !important;
	}
  
	.wd-xs-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-xs-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-xs-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-xs-auto {
	  width: auto;
	}
  
	.wd-xs-auto-f {
	  width: auto !important;
	}
  }
  
  @media (min-width: 576px) {
	.wd-sm-5 {
	  width: 5px;
	}
  
	.wd-sm-5p {
	  width: 5%;
	}
  
	.mx-wd-sm-5p {
	  max-width: 5%;
	}
  
	.mn-wd-sm-5p {
	  min-width: 5%;
	}
  
	.wd-sm-5-f {
	  width: 5px !important;
	}
  
	.wd-sm-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-sm-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-sm-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-sm-10 {
	  width: 10px;
	}
  
	.wd-sm-10p {
	  width: 10%;
	}
  
	.mx-wd-sm-10p {
	  max-width: 10%;
	}
  
	.mn-wd-sm-10p {
	  min-width: 10%;
	}
  
	.wd-sm-10-f {
	  width: 10px !important;
	}
  
	.wd-sm-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-sm-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-sm-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-sm-15 {
	  width: 15px;
	}
  
	.wd-sm-15p {
	  width: 15%;
	}
  
	.mx-wd-sm-15p {
	  max-width: 15%;
	}
  
	.mn-wd-sm-15p {
	  min-width: 15%;
	}
  
	.wd-sm-15-f {
	  width: 15px !important;
	}
  
	.wd-sm-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-sm-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-sm-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-sm-20 {
	  width: 20px;
	}
  
	.wd-sm-20p {
	  width: 20%;
	}
  
	.mx-wd-sm-20p {
	  max-width: 20%;
	}
  
	.mn-wd-sm-20p {
	  min-width: 20%;
	}
  
	.wd-sm-20-f {
	  width: 20px !important;
	}
  
	.wd-sm-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-sm-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-sm-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-sm-25 {
	  width: 25px;
	}
  
	.wd-sm-25p {
	  width: 25%;
	}
  
	.mx-wd-sm-25p {
	  max-width: 25%;
	}
  
	.mn-wd-sm-25p {
	  min-width: 25%;
	}
  
	.wd-sm-25-f {
	  width: 25px !important;
	}
  
	.wd-sm-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-sm-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-sm-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-sm-30 {
	  width: 30px;
	}
  
	.wd-sm-30p {
	  width: 30%;
	}
  
	.mx-wd-sm-30p {
	  max-width: 30%;
	}
  
	.mn-wd-sm-30p {
	  min-width: 30%;
	}
  
	.wd-sm-30-f {
	  width: 30px !important;
	}
  
	.wd-sm-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-sm-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-sm-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-sm-35 {
	  width: 35px;
	}
  
	.wd-sm-35p {
	  width: 35%;
	}
  
	.mx-wd-sm-35p {
	  max-width: 35%;
	}
  
	.mn-wd-sm-35p {
	  min-width: 35%;
	}
  
	.wd-sm-35-f {
	  width: 35px !important;
	}
  
	.wd-sm-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-sm-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-sm-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-sm-40 {
	  width: 40px;
	}
  
	.wd-sm-40p {
	  width: 40%;
	}
  
	.mx-wd-sm-40p {
	  max-width: 40%;
	}
  
	.mn-wd-sm-40p {
	  min-width: 40%;
	}
  
	.wd-sm-40-f {
	  width: 40px !important;
	}
  
	.wd-sm-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-sm-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-sm-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-sm-45 {
	  width: 45px;
	}
  
	.wd-sm-45p {
	  width: 45%;
	}
  
	.mx-wd-sm-45p {
	  max-width: 45%;
	}
  
	.mn-wd-sm-45p {
	  min-width: 45%;
	}
  
	.wd-sm-45-f {
	  width: 45px !important;
	}
  
	.wd-sm-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-sm-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-sm-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-sm-50 {
	  width: 50px;
	}
  
	.wd-sm-50p {
	  width: 50%;
	}
  
	.mx-wd-sm-50p {
	  max-width: 50%;
	}
  
	.mn-wd-sm-50p {
	  min-width: 50%;
	}
  
	.wd-sm-50-f {
	  width: 50px !important;
	}
  
	.wd-sm-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-sm-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-sm-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-sm-55 {
	  width: 55px;
	}
  
	.wd-sm-55p {
	  width: 55%;
	}
  
	.mx-wd-sm-55p {
	  max-width: 55%;
	}
  
	.mn-wd-sm-55p {
	  min-width: 55%;
	}
  
	.wd-sm-55-f {
	  width: 55px !important;
	}
  
	.wd-sm-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-sm-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-sm-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-sm-60 {
	  width: 60px;
	}
  
	.wd-sm-60p {
	  width: 60%;
	}
  
	.mx-wd-sm-60p {
	  max-width: 60%;
	}
  
	.mn-wd-sm-60p {
	  min-width: 60%;
	}
  
	.wd-sm-60-f {
	  width: 60px !important;
	}
  
	.wd-sm-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-sm-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-sm-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-sm-65 {
	  width: 65px;
	}
  
	.wd-sm-65p {
	  width: 65%;
	}
  
	.mx-wd-sm-65p {
	  max-width: 65%;
	}
  
	.mn-wd-sm-65p {
	  min-width: 65%;
	}
  
	.wd-sm-65-f {
	  width: 65px !important;
	}
  
	.wd-sm-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-sm-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-sm-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-sm-70 {
	  width: 70px;
	}
  
	.wd-sm-70p {
	  width: 70%;
	}
  
	.mx-wd-sm-70p {
	  max-width: 70%;
	}
  
	.mn-wd-sm-70p {
	  min-width: 70%;
	}
  
	.wd-sm-70-f {
	  width: 70px !important;
	}
  
	.wd-sm-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-sm-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-sm-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-sm-75 {
	  width: 75px;
	}
  
	.wd-sm-75p {
	  width: 75%;
	}
  
	.mx-wd-sm-75p {
	  max-width: 75%;
	}
  
	.mn-wd-sm-75p {
	  min-width: 75%;
	}
  
	.wd-sm-75-f {
	  width: 75px !important;
	}
  
	.wd-sm-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-sm-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-sm-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-sm-80 {
	  width: 80px;
	}
  
	.wd-sm-80p {
	  width: 80%;
	}
  
	.mx-wd-sm-80p {
	  max-width: 80%;
	}
  
	.mn-wd-sm-80p {
	  min-width: 80%;
	}
  
	.wd-sm-80-f {
	  width: 80px !important;
	}
  
	.wd-sm-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-sm-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-sm-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-sm-85 {
	  width: 85px;
	}
  
	.wd-sm-85p {
	  width: 85%;
	}
  
	.mx-wd-sm-85p {
	  max-width: 85%;
	}
  
	.mn-wd-sm-85p {
	  min-width: 85%;
	}
  
	.wd-sm-85-f {
	  width: 85px !important;
	}
  
	.wd-sm-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-sm-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-sm-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-sm-90 {
	  width: 90px;
	}
  
	.wd-sm-90p {
	  width: 90%;
	}
  
	.mx-wd-sm-90p {
	  max-width: 90%;
	}
  
	.mn-wd-sm-90p {
	  min-width: 90%;
	}
  
	.wd-sm-90-f {
	  width: 90px !important;
	}
  
	.wd-sm-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-sm-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-sm-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-sm-95 {
	  width: 95px;
	}
  
	.wd-sm-95p {
	  width: 95%;
	}
  
	.mx-wd-sm-95p {
	  max-width: 95%;
	}
  
	.mn-wd-sm-95p {
	  min-width: 95%;
	}
  
	.wd-sm-95-f {
	  width: 95px !important;
	}
  
	.wd-sm-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-sm-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-sm-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-sm-100 {
	  width: 100px;
	}
  
	.wd-sm-100p {
	  width: 100%;
	}
  
	.mx-wd-sm-100p {
	  max-width: 100%;
	}
  
	.mn-wd-sm-100p {
	  min-width: 100%;
	}
  
	.wd-sm-100-f {
	  width: 100px !important;
	}
  
	.wd-sm-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-sm-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-sm-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-sm-150 {
	  width: 150px;
	}
  
	.wd-sm-150p {
	  width: 150%;
	}
  
	.mx-wd-sm-150p {
	  max-width: 150%;
	}
  
	.mn-wd-sm-150p {
	  min-width: 150%;
	}
  
	.wd-sm-150-f {
	  width: 150px !important;
	}
  
	.wd-sm-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-sm-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-sm-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-sm-200 {
	  width: 200px;
	}
  
	.wd-sm-200p {
	  width: 200%;
	}
  
	.mx-wd-sm-200p {
	  max-width: 200%;
	}
  
	.mn-wd-sm-200p {
	  min-width: 200%;
	}
  
	.wd-sm-200-f {
	  width: 200px !important;
	}
  
	.wd-sm-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-sm-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-sm-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-sm-250 {
	  width: 250px;
	}
  
	.wd-sm-250p {
	  width: 250%;
	}
  
	.mx-wd-sm-250p {
	  max-width: 250%;
	}
  
	.mn-wd-sm-250p {
	  min-width: 250%;
	}
  
	.wd-sm-250-f {
	  width: 250px !important;
	}
  
	.wd-sm-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-sm-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-sm-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-sm-300 {
	  width: 300px;
	}
  
	.wd-sm-300p {
	  width: 300%;
	}
  
	.mx-wd-sm-300p {
	  max-width: 300%;
	}
  
	.mn-wd-sm-300p {
	  min-width: 300%;
	}
  
	.wd-sm-300-f {
	  width: 300px !important;
	}
  
	.wd-sm-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-sm-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-sm-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-sm-350 {
	  width: 350px;
	}
  
	.wd-sm-350p {
	  width: 350%;
	}
  
	.mx-wd-sm-350p {
	  max-width: 350%;
	}
  
	.mn-wd-sm-350p {
	  min-width: 350%;
	}
  
	.wd-sm-350-f {
	  width: 350px !important;
	}
  
	.wd-sm-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-sm-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-sm-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-sm-400 {
	  width: 400px;
	}
  
	.wd-sm-400p {
	  width: 400%;
	}
  
	.mx-wd-sm-400p {
	  max-width: 400%;
	}
  
	.mn-wd-sm-400p {
	  min-width: 400%;
	}
  
	.wd-sm-400-f {
	  width: 400px !important;
	}
  
	.wd-sm-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-sm-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-sm-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-sm-450 {
	  width: 450px;
	}
  
	.wd-sm-450p {
	  width: 450%;
	}
  
	.mx-wd-sm-450p {
	  max-width: 450%;
	}
  
	.mn-wd-sm-450p {
	  min-width: 450%;
	}
  
	.wd-sm-450-f {
	  width: 450px !important;
	}
  
	.wd-sm-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-sm-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-sm-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-sm-500 {
	  width: 500px;
	}
  
	.wd-sm-500p {
	  width: 500%;
	}
  
	.mx-wd-sm-500p {
	  max-width: 500%;
	}
  
	.mn-wd-sm-500p {
	  min-width: 500%;
	}
  
	.wd-sm-500-f {
	  width: 500px !important;
	}
  
	.wd-sm-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-sm-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-sm-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-sm-550 {
	  width: 550px;
	}
  
	.wd-sm-550p {
	  width: 550%;
	}
  
	.mx-wd-sm-550p {
	  max-width: 550%;
	}
  
	.mn-wd-sm-550p {
	  min-width: 550%;
	}
  
	.wd-sm-550-f {
	  width: 550px !important;
	}
  
	.wd-sm-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-sm-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-sm-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-sm-600 {
	  width: 600px;
	}
  
	.wd-sm-600p {
	  width: 600%;
	}
  
	.mx-wd-sm-600p {
	  max-width: 600%;
	}
  
	.mn-wd-sm-600p {
	  min-width: 600%;
	}
  
	.wd-sm-600-f {
	  width: 600px !important;
	}
  
	.wd-sm-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-sm-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-sm-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-sm-650 {
	  width: 650px;
	}
  
	.wd-sm-650p {
	  width: 650%;
	}
  
	.mx-wd-sm-650p {
	  max-width: 650%;
	}
  
	.mn-wd-sm-650p {
	  min-width: 650%;
	}
  
	.wd-sm-650-f {
	  width: 650px !important;
	}
  
	.wd-sm-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-sm-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-sm-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-sm-700 {
	  width: 700px;
	}
  
	.wd-sm-700p {
	  width: 700%;
	}
  
	.mx-wd-sm-700p {
	  max-width: 700%;
	}
  
	.mn-wd-sm-700p {
	  min-width: 700%;
	}
  
	.wd-sm-700-f {
	  width: 700px !important;
	}
  
	.wd-sm-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-sm-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-sm-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-sm-750 {
	  width: 750px;
	}
  
	.wd-sm-750p {
	  width: 750%;
	}
  
	.mx-wd-sm-750p {
	  max-width: 750%;
	}
  
	.mn-wd-sm-750p {
	  min-width: 750%;
	}
  
	.wd-sm-750-f {
	  width: 750px !important;
	}
  
	.wd-sm-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-sm-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-sm-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-sm-800 {
	  width: 800px;
	}
  
	.wd-sm-800p {
	  width: 800%;
	}
  
	.mx-wd-sm-800p {
	  max-width: 800%;
	}
  
	.mn-wd-sm-800p {
	  min-width: 800%;
	}
  
	.wd-sm-800-f {
	  width: 800px !important;
	}
  
	.wd-sm-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-sm-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-sm-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-sm-850 {
	  width: 850px;
	}
  
	.wd-sm-850p {
	  width: 850%;
	}
  
	.mx-wd-sm-850p {
	  max-width: 850%;
	}
  
	.mn-wd-sm-850p {
	  min-width: 850%;
	}
  
	.wd-sm-850-f {
	  width: 850px !important;
	}
  
	.wd-sm-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-sm-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-sm-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-sm-900 {
	  width: 900px;
	}
  
	.wd-sm-900p {
	  width: 900%;
	}
  
	.mx-wd-sm-900p {
	  max-width: 900%;
	}
  
	.mn-wd-sm-900p {
	  min-width: 900%;
	}
  
	.wd-sm-900-f {
	  width: 900px !important;
	}
  
	.wd-sm-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-sm-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-sm-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-sm-950 {
	  width: 950px;
	}
  
	.wd-sm-950p {
	  width: 950%;
	}
  
	.mx-wd-sm-950p {
	  max-width: 950%;
	}
  
	.mn-wd-sm-950p {
	  min-width: 950%;
	}
  
	.wd-sm-950-f {
	  width: 950px !important;
	}
  
	.wd-sm-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-sm-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-sm-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-sm-1000 {
	  width: 1000px;
	}
  
	.wd-sm-1000p {
	  width: 1000%;
	}
  
	.mx-wd-sm-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-sm-1000p {
	  min-width: 1000%;
	}
  
	.wd-sm-1000-f {
	  width: 1000px !important;
	}
  
	.wd-sm-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-sm-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-sm-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-sm-auto {
	  width: auto;
	}
  
	.wd-sm-auto-f {
	  width: auto !important;
	}
  }
  
  @media (min-width: 768px) {
	.wd-md-5 {
	  width: 5px;
	}
  
	.wd-md-5p {
	  width: 5%;
	}
  
	.mx-wd-md-5p {
	  max-width: 5%;
	}
  
	.mn-wd-md-5p {
	  min-width: 5%;
	}
  
	.wd-md-5-f {
	  width: 5px !important;
	}
  
	.wd-md-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-md-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-md-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-md-10 {
	  width: 10px;
	}
  
	.wd-md-10p {
	  width: 10%;
	}
  
	.mx-wd-md-10p {
	  max-width: 10%;
	}
  
	.mn-wd-md-10p {
	  min-width: 10%;
	}
  
	.wd-md-10-f {
	  width: 10px !important;
	}
  
	.wd-md-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-md-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-md-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-md-15 {
	  width: 15px;
	}
  
	.wd-md-15p {
	  width: 15%;
	}
  
	.mx-wd-md-15p {
	  max-width: 15%;
	}
  
	.mn-wd-md-15p {
	  min-width: 15%;
	}
  
	.wd-md-15-f {
	  width: 15px !important;
	}
  
	.wd-md-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-md-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-md-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-md-20 {
	  width: 20px;
	}
  
	.wd-md-20p {
	  width: 20%;
	}
  
	.mx-wd-md-20p {
	  max-width: 20%;
	}
  
	.mn-wd-md-20p {
	  min-width: 20%;
	}
  
	.wd-md-20-f {
	  width: 20px !important;
	}
  
	.wd-md-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-md-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-md-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-md-25 {
	  width: 25px;
	}
  
	.wd-md-25p {
	  width: 25%;
	}
  
	.mx-wd-md-25p {
	  max-width: 25%;
	}
  
	.mn-wd-md-25p {
	  min-width: 25%;
	}
  
	.wd-md-25-f {
	  width: 25px !important;
	}
  
	.wd-md-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-md-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-md-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-md-30 {
	  width: 30px;
	}
  
	.wd-md-30p {
	  width: 30%;
	}
  
	.mx-wd-md-30p {
	  max-width: 30%;
	}
  
	.mn-wd-md-30p {
	  min-width: 30%;
	}
  
	.wd-md-30-f {
	  width: 30px !important;
	}
  
	.wd-md-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-md-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-md-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-md-35 {
	  width: 35px;
	}
  
	.wd-md-35p {
	  width: 35%;
	}
  
	.mx-wd-md-35p {
	  max-width: 35%;
	}
  
	.mn-wd-md-35p {
	  min-width: 35%;
	}
  
	.wd-md-35-f {
	  width: 35px !important;
	}
  
	.wd-md-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-md-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-md-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-md-40 {
	  width: 40px;
	}
  
	.wd-md-40p {
	  width: 40%;
	}
  
	.mx-wd-md-40p {
	  max-width: 40%;
	}
  
	.mn-wd-md-40p {
	  min-width: 40%;
	}
  
	.wd-md-40-f {
	  width: 40px !important;
	}
  
	.wd-md-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-md-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-md-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-md-45 {
	  width: 45px;
	}
  
	.wd-md-45p {
	  width: 45%;
	}
  
	.mx-wd-md-45p {
	  max-width: 45%;
	}
  
	.mn-wd-md-45p {
	  min-width: 45%;
	}
  
	.wd-md-45-f {
	  width: 45px !important;
	}
  
	.wd-md-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-md-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-md-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-md-50 {
	  width: 50px;
	}
  
	.wd-md-50p {
	  width: 50%;
	}
  
	.mx-wd-md-50p {
	  max-width: 50%;
	}
  
	.mn-wd-md-50p {
	  min-width: 50%;
	}
  
	.wd-md-50-f {
	  width: 50px !important;
	}
  
	.wd-md-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-md-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-md-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-md-55 {
	  width: 55px;
	}
  
	.wd-md-55p {
	  width: 55%;
	}
  
	.mx-wd-md-55p {
	  max-width: 55%;
	}
  
	.mn-wd-md-55p {
	  min-width: 55%;
	}
  
	.wd-md-55-f {
	  width: 55px !important;
	}
  
	.wd-md-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-md-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-md-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-md-60 {
	  width: 60px;
	}
  
	.wd-md-60p {
	  width: 60%;
	}
  
	.mx-wd-md-60p {
	  max-width: 60%;
	}
  
	.mn-wd-md-60p {
	  min-width: 60%;
	}
  
	.wd-md-60-f {
	  width: 60px !important;
	}
  
	.wd-md-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-md-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-md-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-md-65 {
	  width: 65px;
	}
  
	.wd-md-65p {
	  width: 65%;
	}
  
	.mx-wd-md-65p {
	  max-width: 65%;
	}
  
	.mn-wd-md-65p {
	  min-width: 65%;
	}
  
	.wd-md-65-f {
	  width: 65px !important;
	}
  
	.wd-md-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-md-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-md-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-md-70 {
	  width: 70px;
	}
  
	.wd-md-70p {
	  width: 70%;
	}
  
	.mx-wd-md-70p {
	  max-width: 70%;
	}
  
	.mn-wd-md-70p {
	  min-width: 70%;
	}
  
	.wd-md-70-f {
	  width: 70px !important;
	}
  
	.wd-md-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-md-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-md-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-md-75 {
	  width: 75px;
	}
  
	.wd-md-75p {
	  width: 75%;
	}
  
	.mx-wd-md-75p {
	  max-width: 75%;
	}
  
	.mn-wd-md-75p {
	  min-width: 75%;
	}
  
	.wd-md-75-f {
	  width: 75px !important;
	}
  
	.wd-md-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-md-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-md-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-md-80 {
	  width: 80px;
	}
  
	.wd-md-80p {
	  width: 80%;
	}
  
	.mx-wd-md-80p {
	  max-width: 80%;
	}
  
	.mn-wd-md-80p {
	  min-width: 80%;
	}
  
	.wd-md-80-f {
	  width: 80px !important;
	}
  
	.wd-md-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-md-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-md-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-md-85 {
	  width: 85px;
	}
  
	.wd-md-85p {
	  width: 85%;
	}
  
	.mx-wd-md-85p {
	  max-width: 85%;
	}
  
	.mn-wd-md-85p {
	  min-width: 85%;
	}
  
	.wd-md-85-f {
	  width: 85px !important;
	}
  
	.wd-md-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-md-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-md-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-md-90 {
	  width: 90px;
	}
  
	.wd-md-90p {
	  width: 90%;
	}
  
	.mx-wd-md-90p {
	  max-width: 90%;
	}
  
	.mn-wd-md-90p {
	  min-width: 90%;
	}
  
	.wd-md-90-f {
	  width: 90px !important;
	}
  
	.wd-md-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-md-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-md-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-md-95 {
	  width: 95px;
	}
  
	.wd-md-95p {
	  width: 95%;
	}
  
	.mx-wd-md-95p {
	  max-width: 95%;
	}
  
	.mn-wd-md-95p {
	  min-width: 95%;
	}
  
	.wd-md-95-f {
	  width: 95px !important;
	}
  
	.wd-md-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-md-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-md-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-md-100 {
	  width: 100px;
	}
  
	.wd-md-100p {
	  width: 100%;
	}
  
	.mx-wd-md-100p {
	  max-width: 100%;
	}
  
	.mn-wd-md-100p {
	  min-width: 100%;
	}
  
	.wd-md-100-f {
	  width: 100px !important;
	}
  
	.wd-md-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-md-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-md-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-md-150 {
	  width: 150px;
	}
  
	.wd-md-150p {
	  width: 150%;
	}
  
	.mx-wd-md-150p {
	  max-width: 150%;
	}
  
	.mn-wd-md-150p {
	  min-width: 150%;
	}
  
	.wd-md-150-f {
	  width: 150px !important;
	}
  
	.wd-md-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-md-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-md-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-md-200 {
	  width: 200px;
	}
  
	.wd-md-200p {
	  width: 200%;
	}
  
	.mx-wd-md-200p {
	  max-width: 200%;
	}
  
	.mn-wd-md-200p {
	  min-width: 200%;
	}
  
	.wd-md-200-f {
	  width: 200px !important;
	}
  
	.wd-md-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-md-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-md-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-md-250 {
	  width: 250px;
	}
  
	.wd-md-250p {
	  width: 250%;
	}
  
	.mx-wd-md-250p {
	  max-width: 250%;
	}
  
	.mn-wd-md-250p {
	  min-width: 250%;
	}
  
	.wd-md-250-f {
	  width: 250px !important;
	}
  
	.wd-md-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-md-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-md-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-md-300 {
	  width: 300px;
	}
  
	.wd-md-300p {
	  width: 300%;
	}
  
	.mx-wd-md-300p {
	  max-width: 300%;
	}
  
	.mn-wd-md-300p {
	  min-width: 300%;
	}
  
	.wd-md-300-f {
	  width: 300px !important;
	}
  
	.wd-md-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-md-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-md-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-md-350 {
	  width: 350px;
	}
  
	.wd-md-350p {
	  width: 350%;
	}
  
	.mx-wd-md-350p {
	  max-width: 350%;
	}
  
	.mn-wd-md-350p {
	  min-width: 350%;
	}
  
	.wd-md-350-f {
	  width: 350px !important;
	}
  
	.wd-md-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-md-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-md-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-md-400 {
	  width: 400px;
	}
  
	.wd-md-400p {
	  width: 400%;
	}
  
	.mx-wd-md-400p {
	  max-width: 400%;
	}
  
	.mn-wd-md-400p {
	  min-width: 400%;
	}
  
	.wd-md-400-f {
	  width: 400px !important;
	}
  
	.wd-md-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-md-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-md-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-md-450 {
	  width: 450px;
	}
  
	.wd-md-450p {
	  width: 450%;
	}
  
	.mx-wd-md-450p {
	  max-width: 450%;
	}
  
	.mn-wd-md-450p {
	  min-width: 450%;
	}
  
	.wd-md-450-f {
	  width: 450px !important;
	}
  
	.wd-md-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-md-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-md-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-md-500 {
	  width: 500px;
	}
  
	.wd-md-500p {
	  width: 500%;
	}
  
	.mx-wd-md-500p {
	  max-width: 500%;
	}
  
	.mn-wd-md-500p {
	  min-width: 500%;
	}
  
	.wd-md-500-f {
	  width: 500px !important;
	}
  
	.wd-md-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-md-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-md-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-md-550 {
	  width: 550px;
	}
  
	.wd-md-550p {
	  width: 550%;
	}
  
	.mx-wd-md-550p {
	  max-width: 550%;
	}
  
	.mn-wd-md-550p {
	  min-width: 550%;
	}
  
	.wd-md-550-f {
	  width: 550px !important;
	}
  
	.wd-md-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-md-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-md-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-md-600 {
	  width: 600px;
	}
  
	.wd-md-600p {
	  width: 600%;
	}
  
	.mx-wd-md-600p {
	  max-width: 600%;
	}
  
	.mn-wd-md-600p {
	  min-width: 600%;
	}
  
	.wd-md-600-f {
	  width: 600px !important;
	}
  
	.wd-md-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-md-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-md-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-md-650 {
	  width: 650px;
	}
  
	.wd-md-650p {
	  width: 650%;
	}
  
	.mx-wd-md-650p {
	  max-width: 650%;
	}
  
	.mn-wd-md-650p {
	  min-width: 650%;
	}
  
	.wd-md-650-f {
	  width: 650px !important;
	}
  
	.wd-md-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-md-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-md-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-md-700 {
	  width: 700px;
	}
  
	.wd-md-700p {
	  width: 700%;
	}
  
	.mx-wd-md-700p {
	  max-width: 700%;
	}
  
	.mn-wd-md-700p {
	  min-width: 700%;
	}
  
	.wd-md-700-f {
	  width: 700px !important;
	}
  
	.wd-md-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-md-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-md-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-md-750 {
	  width: 750px;
	}
  
	.wd-md-750p {
	  width: 750%;
	}
  
	.mx-wd-md-750p {
	  max-width: 750%;
	}
  
	.mn-wd-md-750p {
	  min-width: 750%;
	}
  
	.wd-md-750-f {
	  width: 750px !important;
	}
  
	.wd-md-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-md-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-md-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-md-800 {
	  width: 800px;
	}
  
	.wd-md-800p {
	  width: 800%;
	}
  
	.mx-wd-md-800p {
	  max-width: 800%;
	}
  
	.mn-wd-md-800p {
	  min-width: 800%;
	}
  
	.wd-md-800-f {
	  width: 800px !important;
	}
  
	.wd-md-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-md-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-md-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-md-850 {
	  width: 850px;
	}
  
	.wd-md-850p {
	  width: 850%;
	}
  
	.mx-wd-md-850p {
	  max-width: 850%;
	}
  
	.mn-wd-md-850p {
	  min-width: 850%;
	}
  
	.wd-md-850-f {
	  width: 850px !important;
	}
  
	.wd-md-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-md-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-md-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-md-900 {
	  width: 900px;
	}
  
	.wd-md-900p {
	  width: 900%;
	}
  
	.mx-wd-md-900p {
	  max-width: 900%;
	}
  
	.mn-wd-md-900p {
	  min-width: 900%;
	}
  
	.wd-md-900-f {
	  width: 900px !important;
	}
  
	.wd-md-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-md-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-md-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-md-950 {
	  width: 950px;
	}
  
	.wd-md-950p {
	  width: 950%;
	}
  
	.mx-wd-md-950p {
	  max-width: 950%;
	}
  
	.mn-wd-md-950p {
	  min-width: 950%;
	}
  
	.wd-md-950-f {
	  width: 950px !important;
	}
  
	.wd-md-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-md-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-md-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-md-1000 {
	  width: 1000px;
	}
  
	.wd-md-1000p {
	  width: 1000%;
	}
  
	.mx-wd-md-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-md-1000p {
	  min-width: 1000%;
	}
  
	.wd-md-1000-f {
	  width: 1000px !important;
	}
  
	.wd-md-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-md-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-md-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-md-auto {
	  width: auto;
	}
  
	.wd-md-auto-f {
	  width: auto !important;
	}
  
	.wd-md-120 {
	  width: 120px;
	}
  }
  
  @media (min-width: 992px) {
	.wd-lg-5 {
	  width: 5px;
	}
  
	.wd-lg-5p {
	  width: 5%;
	}
  
	.mx-wd-lg-5p {
	  max-width: 5%;
	}
  
	.mn-wd-lg-5p {
	  min-width: 5%;
	}
  
	.wd-lg-5-f {
	  width: 5px !important;
	}
  
	.wd-lg-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-lg-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-lg-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-lg-10 {
	  width: 10px;
	}
  
	.wd-lg-10p {
	  width: 10%;
	}
  
	.mx-wd-lg-10p {
	  max-width: 10%;
	}
  
	.mn-wd-lg-10p {
	  min-width: 10%;
	}
  
	.wd-lg-10-f {
	  width: 10px !important;
	}
  
	.wd-lg-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-lg-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-lg-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-lg-15 {
	  width: 15px;
	}
  
	.wd-lg-15p {
	  width: 15%;
	}
  
	.mx-wd-lg-15p {
	  max-width: 15%;
	}
  
	.mn-wd-lg-15p {
	  min-width: 15%;
	}
  
	.wd-lg-15-f {
	  width: 15px !important;
	}
  
	.wd-lg-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-lg-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-lg-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-lg-20 {
	  width: 20px;
	}
  
	.wd-lg-20p {
	  width: 20%;
	}
  
	.mx-wd-lg-20p {
	  max-width: 20%;
	}
  
	.mn-wd-lg-20p {
	  min-width: 20%;
	}
  
	.wd-lg-20-f {
	  width: 20px !important;
	}
  
	.wd-lg-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-lg-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-lg-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-lg-25 {
	  width: 25px;
	}
  
	.wd-lg-25p {
	  width: 25%;
	}
  
	.mx-wd-lg-25p {
	  max-width: 25%;
	}
  
	.mn-wd-lg-25p {
	  min-width: 25%;
	}
  
	.wd-lg-25-f {
	  width: 25px !important;
	}
  
	.wd-lg-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-lg-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-lg-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-lg-30 {
	  width: 30px;
	}
  
	.wd-lg-30p {
	  width: 30%;
	}
  
	.mx-wd-lg-30p {
	  max-width: 30%;
	}
  
	.mn-wd-lg-30p {
	  min-width: 30%;
	}
  
	.wd-lg-30-f {
	  width: 30px !important;
	}
  
	.wd-lg-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-lg-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-lg-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-lg-35 {
	  width: 35px;
	}
  
	.wd-lg-35p {
	  width: 35%;
	}
  
	.mx-wd-lg-35p {
	  max-width: 35%;
	}
  
	.mn-wd-lg-35p {
	  min-width: 35%;
	}
  
	.wd-lg-35-f {
	  width: 35px !important;
	}
  
	.wd-lg-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-lg-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-lg-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-lg-40 {
	  width: 40px;
	}
  
	.wd-lg-40p {
	  width: 40%;
	}
  
	.mx-wd-lg-40p {
	  max-width: 40%;
	}
  
	.mn-wd-lg-40p {
	  min-width: 40%;
	}
  
	.wd-lg-40-f {
	  width: 40px !important;
	}
  
	.wd-lg-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-lg-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-lg-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-lg-45 {
	  width: 45px;
	}
  
	.wd-lg-45p {
	  width: 45%;
	}
  
	.mx-wd-lg-45p {
	  max-width: 45%;
	}
  
	.mn-wd-lg-45p {
	  min-width: 45%;
	}
  
	.wd-lg-45-f {
	  width: 45px !important;
	}
  
	.wd-lg-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-lg-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-lg-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-lg-50 {
	  width: 50px;
	}
  
	.wd-lg-50p {
	  width: 50%;
	}
  
	.mx-wd-lg-50p {
	  max-width: 50%;
	}
  
	.mn-wd-lg-50p {
	  min-width: 50%;
	}
  
	.wd-lg-50-f {
	  width: 50px !important;
	}
  
	.wd-lg-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-lg-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-lg-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-lg-55 {
	  width: 55px;
	}
  
	.wd-lg-55p {
	  width: 55%;
	}
  
	.mx-wd-lg-55p {
	  max-width: 55%;
	}
  
	.mn-wd-lg-55p {
	  min-width: 55%;
	}
  
	.wd-lg-55-f {
	  width: 55px !important;
	}
  
	.wd-lg-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-lg-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-lg-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-lg-60 {
	  width: 60px;
	}
  
	.wd-lg-60p {
	  width: 60%;
	}
  
	.mx-wd-lg-60p {
	  max-width: 60%;
	}
  
	.mn-wd-lg-60p {
	  min-width: 60%;
	}
  
	.wd-lg-60-f {
	  width: 60px !important;
	}
  
	.wd-lg-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-lg-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-lg-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-lg-65 {
	  width: 65px;
	}
  
	.wd-lg-65p {
	  width: 65%;
	}
  
	.mx-wd-lg-65p {
	  max-width: 65%;
	}
  
	.mn-wd-lg-65p {
	  min-width: 65%;
	}
  
	.wd-lg-65-f {
	  width: 65px !important;
	}
  
	.wd-lg-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-lg-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-lg-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-lg-70 {
	  width: 70px;
	}
  
	.wd-lg-70p {
	  width: 70%;
	}
  
	.mx-wd-lg-70p {
	  max-width: 70%;
	}
  
	.mn-wd-lg-70p {
	  min-width: 70%;
	}
  
	.wd-lg-70-f {
	  width: 70px !important;
	}
  
	.wd-lg-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-lg-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-lg-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-lg-75 {
	  width: 75px;
	}
  
	.wd-lg-75p {
	  width: 75%;
	}
  
	.mx-wd-lg-75p {
	  max-width: 75%;
	}
  
	.mn-wd-lg-75p {
	  min-width: 75%;
	}
  
	.wd-lg-75-f {
	  width: 75px !important;
	}
  
	.wd-lg-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-lg-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-lg-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-lg-80 {
	  width: 80px;
	}
  
	.wd-lg-80p {
	  width: 80%;
	}
  
	.mx-wd-lg-80p {
	  max-width: 80%;
	}
  
	.mn-wd-lg-80p {
	  min-width: 80%;
	}
  
	.wd-lg-80-f {
	  width: 80px !important;
	}
  
	.wd-lg-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-lg-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-lg-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-lg-85 {
	  width: 85px;
	}
  
	.wd-lg-85p {
	  width: 85%;
	}
  
	.mx-wd-lg-85p {
	  max-width: 85%;
	}
  
	.mn-wd-lg-85p {
	  min-width: 85%;
	}
  
	.wd-lg-85-f {
	  width: 85px !important;
	}
  
	.wd-lg-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-lg-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-lg-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-lg-90 {
	  width: 90px;
	}
  
	.wd-lg-90p {
	  width: 90%;
	}
  
	.mx-wd-lg-90p {
	  max-width: 90%;
	}
  
	.mn-wd-lg-90p {
	  min-width: 90%;
	}
  
	.wd-lg-90-f {
	  width: 90px !important;
	}
  
	.wd-lg-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-lg-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-lg-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-lg-95 {
	  width: 95px;
	}
  
	.wd-lg-95p {
	  width: 95%;
	}
  
	.mx-wd-lg-95p {
	  max-width: 95%;
	}
  
	.mn-wd-lg-95p {
	  min-width: 95%;
	}
  
	.wd-lg-95-f {
	  width: 95px !important;
	}
  
	.wd-lg-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-lg-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-lg-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-lg-100 {
	  width: 100px;
	}
  
	.wd-lg-100p {
	  width: 100%;
	}
  
	.mx-wd-lg-100p {
	  max-width: 100%;
	}
  
	.mn-wd-lg-100p {
	  min-width: 100%;
	}
  
	.wd-lg-100-f {
	  width: 100px !important;
	}
  
	.wd-lg-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-lg-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-lg-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-lg-150 {
	  width: 150px;
	}
  
	.wd-lg-150p {
	  width: 150%;
	}
  
	.mx-wd-lg-150p {
	  max-width: 150%;
	}
  
	.mn-wd-lg-150p {
	  min-width: 150%;
	}
  
	.wd-lg-150-f {
	  width: 150px !important;
	}
  
	.wd-lg-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-lg-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-lg-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-lg-200 {
	  width: 200px;
	}
  
	.wd-lg-200p {
	  width: 200%;
	}
  
	.mx-wd-lg-200p {
	  max-width: 200%;
	}
  
	.mn-wd-lg-200p {
	  min-width: 200%;
	}
  
	.wd-lg-200-f {
	  width: 200px !important;
	}
  
	.wd-lg-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-lg-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-lg-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-lg-250 {
	  width: 250px;
	}
  
	.wd-lg-250p {
	  width: 250%;
	}
  
	.mx-wd-lg-250p {
	  max-width: 250%;
	}
  
	.mn-wd-lg-250p {
	  min-width: 250%;
	}
  
	.wd-lg-250-f {
	  width: 250px !important;
	}
  
	.wd-lg-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-lg-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-lg-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-lg-300 {
	  width: 300px;
	}
  
	.wd-lg-300p {
	  width: 300%;
	}
  
	.mx-wd-lg-300p {
	  max-width: 300%;
	}
  
	.mn-wd-lg-300p {
	  min-width: 300%;
	}
  
	.wd-lg-300-f {
	  width: 300px !important;
	}
  
	.wd-lg-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-lg-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-lg-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-lg-350 {
	  width: 350px;
	}
  
	.wd-lg-350p {
	  width: 350%;
	}
  
	.mx-wd-lg-350p {
	  max-width: 350%;
	}
  
	.mn-wd-lg-350p {
	  min-width: 350%;
	}
  
	.wd-lg-350-f {
	  width: 350px !important;
	}
  
	.wd-lg-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-lg-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-lg-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-lg-400 {
	  width: 400px;
	}
  
	.wd-lg-400p {
	  width: 400%;
	}
  
	.mx-wd-lg-400p {
	  max-width: 400%;
	}
  
	.mn-wd-lg-400p {
	  min-width: 400%;
	}
  
	.wd-lg-400-f {
	  width: 400px !important;
	}
  
	.wd-lg-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-lg-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-lg-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-lg-450 {
	  width: 450px;
	}
  
	.wd-lg-450p {
	  width: 450%;
	}
  
	.mx-wd-lg-450p {
	  max-width: 450%;
	}
  
	.mn-wd-lg-450p {
	  min-width: 450%;
	}
  
	.wd-lg-450-f {
	  width: 450px !important;
	}
  
	.wd-lg-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-lg-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-lg-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-lg-500 {
	  width: 500px;
	}
  
	.wd-lg-500p {
	  width: 500%;
	}
  
	.mx-wd-lg-500p {
	  max-width: 500%;
	}
  
	.mn-wd-lg-500p {
	  min-width: 500%;
	}
  
	.wd-lg-500-f {
	  width: 500px !important;
	}
  
	.wd-lg-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-lg-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-lg-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-lg-550 {
	  width: 550px;
	}
  
	.wd-lg-550p {
	  width: 550%;
	}
  
	.mx-wd-lg-550p {
	  max-width: 550%;
	}
  
	.mn-wd-lg-550p {
	  min-width: 550%;
	}
  
	.wd-lg-550-f {
	  width: 550px !important;
	}
  
	.wd-lg-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-lg-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-lg-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-lg-600 {
	  width: 600px;
	}
  
	.wd-lg-600p {
	  width: 600%;
	}
  
	.mx-wd-lg-600p {
	  max-width: 600%;
	}
  
	.mn-wd-lg-600p {
	  min-width: 600%;
	}
  
	.wd-lg-600-f {
	  width: 600px !important;
	}
  
	.wd-lg-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-lg-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-lg-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-lg-650 {
	  width: 650px;
	}
  
	.wd-lg-650p {
	  width: 650%;
	}
  
	.mx-wd-lg-650p {
	  max-width: 650%;
	}
  
	.mn-wd-lg-650p {
	  min-width: 650%;
	}
  
	.wd-lg-650-f {
	  width: 650px !important;
	}
  
	.wd-lg-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-lg-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-lg-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-lg-700 {
	  width: 700px;
	}
  
	.wd-lg-700p {
	  width: 700%;
	}
  
	.mx-wd-lg-700p {
	  max-width: 700%;
	}
  
	.mn-wd-lg-700p {
	  min-width: 700%;
	}
  
	.wd-lg-700-f {
	  width: 700px !important;
	}
  
	.wd-lg-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-lg-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-lg-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-lg-750 {
	  width: 750px;
	}
  
	.wd-lg-750p {
	  width: 750%;
	}
  
	.mx-wd-lg-750p {
	  max-width: 750%;
	}
  
	.mn-wd-lg-750p {
	  min-width: 750%;
	}
  
	.wd-lg-750-f {
	  width: 750px !important;
	}
  
	.wd-lg-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-lg-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-lg-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-lg-800 {
	  width: 800px;
	}
  
	.wd-lg-800p {
	  width: 800%;
	}
  
	.mx-wd-lg-800p {
	  max-width: 800%;
	}
  
	.mn-wd-lg-800p {
	  min-width: 800%;
	}
  
	.wd-lg-800-f {
	  width: 800px !important;
	}
  
	.wd-lg-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-lg-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-lg-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-lg-850 {
	  width: 850px;
	}
  
	.wd-lg-850p {
	  width: 850%;
	}
  
	.mx-wd-lg-850p {
	  max-width: 850%;
	}
  
	.mn-wd-lg-850p {
	  min-width: 850%;
	}
  
	.wd-lg-850-f {
	  width: 850px !important;
	}
  
	.wd-lg-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-lg-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-lg-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-lg-900 {
	  width: 900px;
	}
  
	.wd-lg-900p {
	  width: 900%;
	}
  
	.mx-wd-lg-900p {
	  max-width: 900%;
	}
  
	.mn-wd-lg-900p {
	  min-width: 900%;
	}
  
	.wd-lg-900-f {
	  width: 900px !important;
	}
  
	.wd-lg-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-lg-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-lg-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-lg-950 {
	  width: 950px;
	}
  
	.wd-lg-950p {
	  width: 950%;
	}
  
	.mx-wd-lg-950p {
	  max-width: 950%;
	}
  
	.mn-wd-lg-950p {
	  min-width: 950%;
	}
  
	.wd-lg-950-f {
	  width: 950px !important;
	}
  
	.wd-lg-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-lg-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-lg-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-lg-1000 {
	  width: 1000px;
	}
  
	.wd-lg-1000p {
	  width: 1000%;
	}
  
	.mx-wd-lg-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-lg-1000p {
	  min-width: 1000%;
	}
  
	.wd-lg-1000-f {
	  width: 1000px !important;
	}
  
	.wd-lg-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-lg-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-lg-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-lg-auto {
	  width: auto;
	}
  
	.wd-lg-auto-f {
	  width: auto !important;
	}
  }
  
  @media (min-width: 1200px) {
	.wd-xl-5 {
	  width: 5px;
	}
  
	.wd-xl-5p {
	  width: 5%;
	}
  
	.mx-wd-xl-5p {
	  max-width: 5%;
	}
  
	.mn-wd-xl-5p {
	  min-width: 5%;
	}
  
	.wd-xl-5-f {
	  width: 5px !important;
	}
  
	.wd-xl-5p-f {
	  width: 5% !important;
	}
  
	.mx-wd-xl-5p-f {
	  max-width: 5% !important;
	}
  
	.mn-wd-xl-5p-f {
	  min-width: 5% !important;
	}
  
	.wd-xl-10 {
	  width: 10px;
	}
  
	.wd-xl-10p {
	  width: 10%;
	}
  
	.mx-wd-xl-10p {
	  max-width: 10%;
	}
  
	.mn-wd-xl-10p {
	  min-width: 10%;
	}
  
	.wd-xl-10-f {
	  width: 10px !important;
	}
  
	.wd-xl-10p-f {
	  width: 10% !important;
	}
  
	.mx-wd-xl-10p-f {
	  max-width: 10% !important;
	}
  
	.mn-wd-xl-10p-f {
	  min-width: 10% !important;
	}
  
	.wd-xl-15 {
	  width: 15px;
	}
  
	.wd-xl-15p {
	  width: 15%;
	}
  
	.mx-wd-xl-15p {
	  max-width: 15%;
	}
  
	.mn-wd-xl-15p {
	  min-width: 15%;
	}
  
	.wd-xl-15-f {
	  width: 15px !important;
	}
  
	.wd-xl-15p-f {
	  width: 15% !important;
	}
  
	.mx-wd-xl-15p-f {
	  max-width: 15% !important;
	}
  
	.mn-wd-xl-15p-f {
	  min-width: 15% !important;
	}
  
	.wd-xl-20 {
	  width: 20px;
	}
  
	.wd-xl-20p {
	  width: 20%;
	}
  
	.mx-wd-xl-20p {
	  max-width: 20%;
	}
  
	.mn-wd-xl-20p {
	  min-width: 20%;
	}
  
	.wd-xl-20-f {
	  width: 20px !important;
	}
  
	.wd-xl-20p-f {
	  width: 20% !important;
	}
  
	.mx-wd-xl-20p-f {
	  max-width: 20% !important;
	}
  
	.mn-wd-xl-20p-f {
	  min-width: 20% !important;
	}
  
	.wd-xl-25 {
	  width: 25px;
	}
  
	.wd-xl-25p {
	  width: 25%;
	}
  
	.mx-wd-xl-25p {
	  max-width: 25%;
	}
  
	.mn-wd-xl-25p {
	  min-width: 25%;
	}
  
	.wd-xl-25-f {
	  width: 25px !important;
	}
  
	.wd-xl-25p-f {
	  width: 25% !important;
	}
  
	.mx-wd-xl-25p-f {
	  max-width: 25% !important;
	}
  
	.mn-wd-xl-25p-f {
	  min-width: 25% !important;
	}
  
	.wd-xl-30 {
	  width: 30px;
	}
  
	.wd-xl-30p {
	  width: 30%;
	}
  
	.mx-wd-xl-30p {
	  max-width: 30%;
	}
  
	.mn-wd-xl-30p {
	  min-width: 30%;
	}
  
	.wd-xl-30-f {
	  width: 30px !important;
	}
  
	.wd-xl-30p-f {
	  width: 30% !important;
	}
  
	.mx-wd-xl-30p-f {
	  max-width: 30% !important;
	}
  
	.mn-wd-xl-30p-f {
	  min-width: 30% !important;
	}
  
	.wd-xl-35 {
	  width: 35px;
	}
  
	.wd-xl-35p {
	  width: 35%;
	}
  
	.mx-wd-xl-35p {
	  max-width: 35%;
	}
  
	.mn-wd-xl-35p {
	  min-width: 35%;
	}
  
	.wd-xl-35-f {
	  width: 35px !important;
	}
  
	.wd-xl-35p-f {
	  width: 35% !important;
	}
  
	.mx-wd-xl-35p-f {
	  max-width: 35% !important;
	}
  
	.mn-wd-xl-35p-f {
	  min-width: 35% !important;
	}
  
	.wd-xl-40 {
	  width: 40px;
	}
  
	.wd-xl-40p {
	  width: 40%;
	}
  
	.mx-wd-xl-40p {
	  max-width: 40%;
	}
  
	.mn-wd-xl-40p {
	  min-width: 40%;
	}
  
	.wd-xl-40-f {
	  width: 40px !important;
	}
  
	.wd-xl-40p-f {
	  width: 40% !important;
	}
  
	.mx-wd-xl-40p-f {
	  max-width: 40% !important;
	}
  
	.mn-wd-xl-40p-f {
	  min-width: 40% !important;
	}
  
	.wd-xl-45 {
	  width: 45px;
	}
  
	.wd-xl-45p {
	  width: 45%;
	}
  
	.mx-wd-xl-45p {
	  max-width: 45%;
	}
  
	.mn-wd-xl-45p {
	  min-width: 45%;
	}
  
	.wd-xl-45-f {
	  width: 45px !important;
	}
  
	.wd-xl-45p-f {
	  width: 45% !important;
	}
  
	.mx-wd-xl-45p-f {
	  max-width: 45% !important;
	}
  
	.mn-wd-xl-45p-f {
	  min-width: 45% !important;
	}
  
	.wd-xl-50 {
	  width: 50px;
	}
  
	.wd-xl-50p {
	  width: 50%;
	}
  
	.mx-wd-xl-50p {
	  max-width: 50%;
	}
  
	.mn-wd-xl-50p {
	  min-width: 50%;
	}
  
	.wd-xl-50-f {
	  width: 50px !important;
	}
  
	.wd-xl-50p-f {
	  width: 50% !important;
	}
  
	.mx-wd-xl-50p-f {
	  max-width: 50% !important;
	}
  
	.mn-wd-xl-50p-f {
	  min-width: 50% !important;
	}
  
	.wd-xl-55 {
	  width: 55px;
	}
  
	.wd-xl-55p {
	  width: 55%;
	}
  
	.mx-wd-xl-55p {
	  max-width: 55%;
	}
  
	.mn-wd-xl-55p {
	  min-width: 55%;
	}
  
	.wd-xl-55-f {
	  width: 55px !important;
	}
  
	.wd-xl-55p-f {
	  width: 55% !important;
	}
  
	.mx-wd-xl-55p-f {
	  max-width: 55% !important;
	}
  
	.mn-wd-xl-55p-f {
	  min-width: 55% !important;
	}
  
	.wd-xl-60 {
	  width: 60px;
	}
  
	.wd-xl-60p {
	  width: 60%;
	}
  
	.mx-wd-xl-60p {
	  max-width: 60%;
	}
  
	.mn-wd-xl-60p {
	  min-width: 60%;
	}
  
	.wd-xl-60-f {
	  width: 60px !important;
	}
  
	.wd-xl-60p-f {
	  width: 60% !important;
	}
  
	.mx-wd-xl-60p-f {
	  max-width: 60% !important;
	}
  
	.mn-wd-xl-60p-f {
	  min-width: 60% !important;
	}
  
	.wd-xl-65 {
	  width: 65px;
	}
  
	.wd-xl-65p {
	  width: 65%;
	}
  
	.mx-wd-xl-65p {
	  max-width: 65%;
	}
  
	.mn-wd-xl-65p {
	  min-width: 65%;
	}
  
	.wd-xl-65-f {
	  width: 65px !important;
	}
  
	.wd-xl-65p-f {
	  width: 65% !important;
	}
  
	.mx-wd-xl-65p-f {
	  max-width: 65% !important;
	}
  
	.mn-wd-xl-65p-f {
	  min-width: 65% !important;
	}
  
	.wd-xl-70 {
	  width: 70px;
	}
  
	.wd-xl-70p {
	  width: 70%;
	}
  
	.mx-wd-xl-70p {
	  max-width: 70%;
	}
  
	.mn-wd-xl-70p {
	  min-width: 70%;
	}
  
	.wd-xl-70-f {
	  width: 70px !important;
	}
  
	.wd-xl-70p-f {
	  width: 70% !important;
	}
  
	.mx-wd-xl-70p-f {
	  max-width: 70% !important;
	}
  
	.mn-wd-xl-70p-f {
	  min-width: 70% !important;
	}
  
	.wd-xl-75 {
	  width: 75px;
	}
  
	.wd-xl-75p {
	  width: 75%;
	}
  
	.mx-wd-xl-75p {
	  max-width: 75%;
	}
  
	.mn-wd-xl-75p {
	  min-width: 75%;
	}
  
	.wd-xl-75-f {
	  width: 75px !important;
	}
  
	.wd-xl-75p-f {
	  width: 75% !important;
	}
  
	.mx-wd-xl-75p-f {
	  max-width: 75% !important;
	}
  
	.mn-wd-xl-75p-f {
	  min-width: 75% !important;
	}
  
	.wd-xl-80 {
	  width: 80px;
	}
  
	.wd-xl-80p {
	  width: 80%;
	}
  
	.mx-wd-xl-80p {
	  max-width: 80%;
	}
  
	.mn-wd-xl-80p {
	  min-width: 80%;
	}
  
	.wd-xl-80-f {
	  width: 80px !important;
	}
  
	.wd-xl-80p-f {
	  width: 80% !important;
	}
  
	.mx-wd-xl-80p-f {
	  max-width: 80% !important;
	}
  
	.mn-wd-xl-80p-f {
	  min-width: 80% !important;
	}
  
	.wd-xl-85 {
	  width: 85px;
	}
  
	.wd-xl-85p {
	  width: 85%;
	}
  
	.mx-wd-xl-85p {
	  max-width: 85%;
	}
  
	.mn-wd-xl-85p {
	  min-width: 85%;
	}
  
	.wd-xl-85-f {
	  width: 85px !important;
	}
  
	.wd-xl-85p-f {
	  width: 85% !important;
	}
  
	.mx-wd-xl-85p-f {
	  max-width: 85% !important;
	}
  
	.mn-wd-xl-85p-f {
	  min-width: 85% !important;
	}
  
	.wd-xl-90 {
	  width: 90px;
	}
  
	.wd-xl-90p {
	  width: 90%;
	}
  
	.mx-wd-xl-90p {
	  max-width: 90%;
	}
  
	.mn-wd-xl-90p {
	  min-width: 90%;
	}
  
	.wd-xl-90-f {
	  width: 90px !important;
	}
  
	.wd-xl-90p-f {
	  width: 90% !important;
	}
  
	.mx-wd-xl-90p-f {
	  max-width: 90% !important;
	}
  
	.mn-wd-xl-90p-f {
	  min-width: 90% !important;
	}
  
	.wd-xl-95 {
	  width: 95px;
	}
  
	.wd-xl-95p {
	  width: 95%;
	}
  
	.mx-wd-xl-95p {
	  max-width: 95%;
	}
  
	.mn-wd-xl-95p {
	  min-width: 95%;
	}
  
	.wd-xl-95-f {
	  width: 95px !important;
	}
  
	.wd-xl-95p-f {
	  width: 95% !important;
	}
  
	.mx-wd-xl-95p-f {
	  max-width: 95% !important;
	}
  
	.mn-wd-xl-95p-f {
	  min-width: 95% !important;
	}
  
	.wd-xl-100 {
	  width: 100px;
	}
  
	.wd-xl-100p {
	  width: 100%;
	}
  
	.mx-wd-xl-100p {
	  max-width: 100%;
	}
  
	.mn-wd-xl-100p {
	  min-width: 100%;
	}
  
	.wd-xl-100-f {
	  width: 100px !important;
	}
  
	.wd-xl-100p-f {
	  width: 100% !important;
	}
  
	.mx-wd-xl-100p-f {
	  max-width: 100% !important;
	}
  
	.mn-wd-xl-100p-f {
	  min-width: 100% !important;
	}
  
	.wd-xl-150 {
	  width: 150px;
	}
  
	.wd-xl-150p {
	  width: 150%;
	}
  
	.mx-wd-xl-150p {
	  max-width: 150%;
	}
  
	.mn-wd-xl-150p {
	  min-width: 150%;
	}
  
	.wd-xl-150-f {
	  width: 150px !important;
	}
  
	.wd-xl-150p-f {
	  width: 150% !important;
	}
  
	.mx-wd-xl-150p-f {
	  max-width: 150% !important;
	}
  
	.mn-wd-xl-150p-f {
	  min-width: 150% !important;
	}
  
	.wd-xl-200 {
	  width: 200px;
	}
  
	.wd-xl-200p {
	  width: 200%;
	}
  
	.mx-wd-xl-200p {
	  max-width: 200%;
	}
  
	.mn-wd-xl-200p {
	  min-width: 200%;
	}
  
	.wd-xl-200-f {
	  width: 200px !important;
	}
  
	.wd-xl-200p-f {
	  width: 200% !important;
	}
  
	.mx-wd-xl-200p-f {
	  max-width: 200% !important;
	}
  
	.mn-wd-xl-200p-f {
	  min-width: 200% !important;
	}
  
	.wd-xl-250 {
	  width: 250px;
	}
  
	.wd-xl-250p {
	  width: 250%;
	}
  
	.mx-wd-xl-250p {
	  max-width: 250%;
	}
  
	.mn-wd-xl-250p {
	  min-width: 250%;
	}
  
	.wd-xl-250-f {
	  width: 250px !important;
	}
  
	.wd-xl-250p-f {
	  width: 250% !important;
	}
  
	.mx-wd-xl-250p-f {
	  max-width: 250% !important;
	}
  
	.mn-wd-xl-250p-f {
	  min-width: 250% !important;
	}
  
	.wd-xl-300 {
	  width: 300px;
	}
  
	.wd-xl-300p {
	  width: 300%;
	}
  
	.mx-wd-xl-300p {
	  max-width: 300%;
	}
  
	.mn-wd-xl-300p {
	  min-width: 300%;
	}
  
	.wd-xl-300-f {
	  width: 300px !important;
	}
  
	.wd-xl-300p-f {
	  width: 300% !important;
	}
  
	.mx-wd-xl-300p-f {
	  max-width: 300% !important;
	}
  
	.mn-wd-xl-300p-f {
	  min-width: 300% !important;
	}
  
	.wd-xl-350 {
	  width: 350px;
	}
  
	.wd-xl-350p {
	  width: 350%;
	}
  
	.mx-wd-xl-350p {
	  max-width: 350%;
	}
  
	.mn-wd-xl-350p {
	  min-width: 350%;
	}
  
	.wd-xl-350-f {
	  width: 350px !important;
	}
  
	.wd-xl-350p-f {
	  width: 350% !important;
	}
  
	.mx-wd-xl-350p-f {
	  max-width: 350% !important;
	}
  
	.mn-wd-xl-350p-f {
	  min-width: 350% !important;
	}
  
	.wd-xl-400 {
	  width: 400px;
	}
  
	.wd-xl-400p {
	  width: 400%;
	}
  
	.mx-wd-xl-400p {
	  max-width: 400%;
	}
  
	.mn-wd-xl-400p {
	  min-width: 400%;
	}
  
	.wd-xl-400-f {
	  width: 400px !important;
	}
  
	.wd-xl-400p-f {
	  width: 400% !important;
	}
  
	.mx-wd-xl-400p-f {
	  max-width: 400% !important;
	}
  
	.mn-wd-xl-400p-f {
	  min-width: 400% !important;
	}
  
	.wd-xl-450 {
	  width: 450px;
	}
  
	.wd-xl-450p {
	  width: 450%;
	}
  
	.mx-wd-xl-450p {
	  max-width: 450%;
	}
  
	.mn-wd-xl-450p {
	  min-width: 450%;
	}
  
	.wd-xl-450-f {
	  width: 450px !important;
	}
  
	.wd-xl-450p-f {
	  width: 450% !important;
	}
  
	.mx-wd-xl-450p-f {
	  max-width: 450% !important;
	}
  
	.mn-wd-xl-450p-f {
	  min-width: 450% !important;
	}
  
	.wd-xl-500 {
	  width: 500px;
	}
  
	.wd-xl-500p {
	  width: 500%;
	}
  
	.mx-wd-xl-500p {
	  max-width: 500%;
	}
  
	.mn-wd-xl-500p {
	  min-width: 500%;
	}
  
	.wd-xl-500-f {
	  width: 500px !important;
	}
  
	.wd-xl-500p-f {
	  width: 500% !important;
	}
  
	.mx-wd-xl-500p-f {
	  max-width: 500% !important;
	}
  
	.mn-wd-xl-500p-f {
	  min-width: 500% !important;
	}
  
	.wd-xl-550 {
	  width: 550px;
	}
  
	.wd-xl-550p {
	  width: 550%;
	}
  
	.mx-wd-xl-550p {
	  max-width: 550%;
	}
  
	.mn-wd-xl-550p {
	  min-width: 550%;
	}
  
	.wd-xl-550-f {
	  width: 550px !important;
	}
  
	.wd-xl-550p-f {
	  width: 550% !important;
	}
  
	.mx-wd-xl-550p-f {
	  max-width: 550% !important;
	}
  
	.mn-wd-xl-550p-f {
	  min-width: 550% !important;
	}
  
	.wd-xl-600 {
	  width: 600px;
	}
  
	.wd-xl-600p {
	  width: 600%;
	}
  
	.mx-wd-xl-600p {
	  max-width: 600%;
	}
  
	.mn-wd-xl-600p {
	  min-width: 600%;
	}
  
	.wd-xl-600-f {
	  width: 600px !important;
	}
  
	.wd-xl-600p-f {
	  width: 600% !important;
	}
  
	.mx-wd-xl-600p-f {
	  max-width: 600% !important;
	}
  
	.mn-wd-xl-600p-f {
	  min-width: 600% !important;
	}
  
	.wd-xl-650 {
	  width: 650px;
	}
  
	.wd-xl-650p {
	  width: 650%;
	}
  
	.mx-wd-xl-650p {
	  max-width: 650%;
	}
  
	.mn-wd-xl-650p {
	  min-width: 650%;
	}
  
	.wd-xl-650-f {
	  width: 650px !important;
	}
  
	.wd-xl-650p-f {
	  width: 650% !important;
	}
  
	.mx-wd-xl-650p-f {
	  max-width: 650% !important;
	}
  
	.mn-wd-xl-650p-f {
	  min-width: 650% !important;
	}
  
	.wd-xl-700 {
	  width: 700px;
	}
  
	.wd-xl-700p {
	  width: 700%;
	}
  
	.mx-wd-xl-700p {
	  max-width: 700%;
	}
  
	.mn-wd-xl-700p {
	  min-width: 700%;
	}
  
	.wd-xl-700-f {
	  width: 700px !important;
	}
  
	.wd-xl-700p-f {
	  width: 700% !important;
	}
  
	.mx-wd-xl-700p-f {
	  max-width: 700% !important;
	}
  
	.mn-wd-xl-700p-f {
	  min-width: 700% !important;
	}
  
	.wd-xl-750 {
	  width: 750px;
	}
  
	.wd-xl-750p {
	  width: 750%;
	}
  
	.mx-wd-xl-750p {
	  max-width: 750%;
	}
  
	.mn-wd-xl-750p {
	  min-width: 750%;
	}
  
	.wd-xl-750-f {
	  width: 750px !important;
	}
  
	.wd-xl-750p-f {
	  width: 750% !important;
	}
  
	.mx-wd-xl-750p-f {
	  max-width: 750% !important;
	}
  
	.mn-wd-xl-750p-f {
	  min-width: 750% !important;
	}
  
	.wd-xl-800 {
	  width: 800px;
	}
  
	.wd-xl-800p {
	  width: 800%;
	}
  
	.mx-wd-xl-800p {
	  max-width: 800%;
	}
  
	.mn-wd-xl-800p {
	  min-width: 800%;
	}
  
	.wd-xl-800-f {
	  width: 800px !important;
	}
  
	.wd-xl-800p-f {
	  width: 800% !important;
	}
  
	.mx-wd-xl-800p-f {
	  max-width: 800% !important;
	}
  
	.mn-wd-xl-800p-f {
	  min-width: 800% !important;
	}
  
	.wd-xl-850 {
	  width: 850px;
	}
  
	.wd-xl-850p {
	  width: 850%;
	}
  
	.mx-wd-xl-850p {
	  max-width: 850%;
	}
  
	.mn-wd-xl-850p {
	  min-width: 850%;
	}
  
	.wd-xl-850-f {
	  width: 850px !important;
	}
  
	.wd-xl-850p-f {
	  width: 850% !important;
	}
  
	.mx-wd-xl-850p-f {
	  max-width: 850% !important;
	}
  
	.mn-wd-xl-850p-f {
	  min-width: 850% !important;
	}
  
	.wd-xl-900 {
	  width: 900px;
	}
  
	.wd-xl-900p {
	  width: 900%;
	}
  
	.mx-wd-xl-900p {
	  max-width: 900%;
	}
  
	.mn-wd-xl-900p {
	  min-width: 900%;
	}
  
	.wd-xl-900-f {
	  width: 900px !important;
	}
  
	.wd-xl-900p-f {
	  width: 900% !important;
	}
  
	.mx-wd-xl-900p-f {
	  max-width: 900% !important;
	}
  
	.mn-wd-xl-900p-f {
	  min-width: 900% !important;
	}
  
	.wd-xl-950 {
	  width: 950px;
	}
  
	.wd-xl-950p {
	  width: 950%;
	}
  
	.mx-wd-xl-950p {
	  max-width: 950%;
	}
  
	.mn-wd-xl-950p {
	  min-width: 950%;
	}
  
	.wd-xl-950-f {
	  width: 950px !important;
	}
  
	.wd-xl-950p-f {
	  width: 950% !important;
	}
  
	.mx-wd-xl-950p-f {
	  max-width: 950% !important;
	}
  
	.mn-wd-xl-950p-f {
	  min-width: 950% !important;
	}
  
	.wd-xl-1000 {
	  width: 1000px;
	}
  
	.wd-xl-1000p {
	  width: 1000%;
	}
  
	.mx-wd-xl-1000p {
	  max-width: 1000%;
	}
  
	.mn-wd-xl-1000p {
	  min-width: 1000%;
	}
  
	.wd-xl-1000-f {
	  width: 1000px !important;
	}
  
	.wd-xl-1000p-f {
	  width: 1000% !important;
	}
  
	.mx-wd-xl-1000p-f {
	  max-width: 1000% !important;
	}
  
	.mn-wd-xl-1000p-f {
	  min-width: 1000% !important;
	}
  
	.wd-xl-auto {
	  width: auto;
	  width: auto !important;
	}
  }
  
  .wd-100v {
	width: 100vw;
  }