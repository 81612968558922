@import "../_variables";
/* ###### 3.3 Buttons ###### */

.btn, .sp-container button {
	border-width: 0;
	line-height: 1.538;
	padding: 9px 20px;
	transition: none;
  }
  
  .btn:active, .sp-container button:active, .btn:focus, .sp-container button:focus {
	box-shadow: none;
  }
  
  .btn-light {
	color: #413d5d;
    background-color: #dedbf1;
    border-color: #bdc6d6;
  
	&:hover {
	  color: $gray-900;
	  background-color: #d3cfef;
	  border-color: #d3cfef;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $gray-900;
	  background-color: $gray-300;
	  border-color: #bdc6d6;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $gray-900;
		background-color: #aeb9cc;
		border-color: #96a5be;
	  }
	}
  }
  
  .show > .btn-light.dropdown-toggle {
	color: $gray-900;
	background-color: #aeb9cc;
	border-color: #96a5be;
  }
  
  .btn-light:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
	}
  }
  
  .show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
  }
  
  .btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
  
	&:hover {
	  color: $white;
	  background-color: #ac50bb;
	  border-color: #ac50bb;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $indigo;
	  border-color: $indigo;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	  }
	}
  }
  
  .show > .btn-indigo.dropdown-toggle {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;
  }
  
  .btn-indigo:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
	}
  }
  
  .show > .btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
  }
  
  .btn-purple {
	color: $white;
	background-color: #6f42c1;
	border-color: #643ab0;
  
	&:hover {
	  color: $white;
	  background-color: #5e37a6;
	  border-color: #4e2d89;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: #6f42c1;
	  border-color: #643ab0;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #59339d;
		border-color: #482a80;
	  }
	}
  }
  
  .show > .btn-purple.dropdown-toggle {
	color: $white;
	background-color: #59339d;
	border-color: #482a80;
  }
  
  .btn-purple:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
	}
  }
  
  .show > .btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
  }
  
  .btn-pink {
	color: $white;
	background-color: $pink;
	border-color: #d80069;
  
	&:hover {
	  color: $white;
	  background-color: #cb0062;
	  border-color: #a50050;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $pink;
	  border-color: #d80069;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #be005c;
		border-color: #98004a;
	  }
	}
  }
  
  .show > .btn-pink.dropdown-toggle {
	color: $white;
	background-color: #be005c;
	border-color: #98004a;
  }
  
  .btn-pink:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
	}
  }
  
  .show > .btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
  }
  
  .btn-gray-500 {
	color: $gray-900;
	background-color: $gray-500;
	border-color: #8896af;
  
	&:hover {
	  color: $white;
	  background-color: #808faa;
	  border-color: #697b9a;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $gray-900;
	  background-color: $gray-500;
	  border-color: #8896af;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #7988a4;
		border-color: #637493;
	  }
	}
  }
  
  .show > .btn-gray-500.dropdown-toggle {
	color: $white;
	background-color: #7988a4;
	border-color: #637493;
  }
  
  .btn-gray-500:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
	}
  }
  
  .show > .btn-gray-500.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
  }
  
  .btn-gray-700 {
	color: $white;
	background-color: $gray-700;
	border-color: #4f5c73;
  
	&:hover {
	  color: $white;
	  background-color: #49566b;
	  border-color: #3a4455;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $gray-700;
	  border-color: #4f5c73;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #445064;
		border-color: #353e4d;
	  }
	}
  }
  
  .show > .btn-gray-700.dropdown-toggle {
	color: $white;
	background-color: #445064;
	border-color: #353e4d;
  }
  
  .btn-gray-700:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
	}
  }
  
  .show > .btn-gray-700.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
  }
  
  [class*=btn-outline-] {
	padding: 0.4rem 1rem;
	line-height: 1.657;
	border-width: 1px;
	background-color: transparent;
  
	&:hover, &:focus {
	  color: $white;
	}
  }
  
  .btn-outline-indigo {
	border-color: $indigo;
	color: $indigo;
  
	&:hover, &:focus {
	  background-color: $indigo;
	}
  }
  
  .btn-outline-light {
	border-color: $gray-500;
	color: #928ead;
  
	&:hover, &:focus {
	  background-color: $gray-500;
	}
  }
  
  /* ###### 5.1 Buttons  ###### */
  
  .btn-main-primary {
	color: $white;
	background-color: $primary;
	border-color: #2e50fb;
  
	&:hover {
	  color: $white;
	  background-color: #2e50fb;
	  border-color: #2e50fb;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: #2e50fb;
	  border-color: #2e50fb;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: $primary;
		border-color: #2e50fb;
	  }
	}
  }
  
  .show > .btn-main-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: #2e50fb;
  }
  
  .btn-main-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
	}
  }
  
  .show > .btn-main-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(97, 77, 251, 0.5);
  }
  
  .btn-main-secondary {
	color: $white;
	background-color: #2e50fb;
	border-color: #2e50fb;
  
	&:hover {
	  color: $white;
	  background-color: #2e50fb;
	  border-color: #2e50fb;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: #1a53ff;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #2e50fb;
		border-color: #2e50fb;
	  }
	}
  }
  
  .show > .btn-main-secondary.dropdown-toggle {
	color: $white;
	background-color: #2e50fb;
	border-color: #2e50fb;
  }
  
  .btn-main-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}
  }
  
  .show > .btn-main-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }
  
  .btn-main-secondary {
	color: $white;
	background-color: $primary;
	border-color: #1a53ff;
  
	&:hover {
	  color: $white;
	  background-color: #0d49ff;
	  border-color: #0039e6;
	}
  
	&:focus, &.focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}
  
	&.disabled, &:disabled {
	  color: $white;
	  background-color: $primary;
	  border-color: #1a53ff;
	}
  
	&:not(:disabled):not(.disabled) {
	  &:active, &.active {
		color: $white;
		background-color: #0040ff;
		border-color: #0036d9;
	  }
	}
  }
  
  .show > .btn-main-secondary.dropdown-toggle {
	color: $white;
	background-color: #0040ff;
	border-color: #0036d9;
  }
  
  .btn-main-secondary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
	  box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
	}
  }
  
  .show > .btn-main-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(60, 109, 255, 0.5);
  }
  
  .btn-rounded {
	border-radius: 50px;
  }
  
  .btn-with-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 38px;
  
	i {
	  line-height: 0;
	  font-size: 20px;
	  margin-right: 5px;
  
	  &.typcn {
		line-height: .9;
	  }
  
	  &.fas {
		font-size: 15px;
	  }
  
	  &.icon {
		font-size: 18px;
	  }
	}
  }
  
  .btn-icon {
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
  
	i {
	  line-height: 0;
	  font-size: 1.2rem;
  
	  &.typcn {
		line-height: .95;
	  }
	}
  }