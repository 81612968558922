
@import "../_variables";
/* ###### 9.1 Background  ###### */

.bg-transparent {
	background-color: transparent;
  }
  
  .bg-gray-100 {
	background-color:$gray-100;
  }
  
  .bg-gray-200 {
	background-color: $gray-200;
  }
  
  .bg-gray-300 {
	background-color: $gray-300;
  }
  
  .bg-gray-400 {
	background-color: $gray-400;
  }
  
  .bg-gray-500 {
	background-color: $gray-500;
  }
  
  .bg-gray-600 {
	background-color: $gray-600;
  }
  
  .bg-gray-700 {
	background-color: $gray-700;
  }
  
  .bg-gray-800 {
	background-color: $gray-800;
  }
  
  .bg-gray-900 {
	background-color: $gray-900;
  }
  
  .bg-white-1 {
	background-color: $white-1;;
  }
  
  .bg-white-2 {
	background-color: $white-2;
  }
  
  .bg-white-3 {
	background-color: $white-3;
  }
  
  .bg-white-4 {
	background-color: $white-4;
  }
  
  .bg-white-5 {
	background-color: $white-5;
  }
  
  .bg-white-6 {
	background-color: $white-6;
  }
  
  .bg-white-7 {
	background-color: $white-7;
  }
  
  .bg-white-8 {
	background-color: $white-8;
  }
  
  .bg-white-9 {
	background-color: $white-9;
  }
  
  .bg-black-1 {
	background-color: $black-1;
  }
  
  .bg-black-2 {
	background-color: $black-2;
  }
  
  .bg-black-3 {
	background-color: $black-3;
  }
  
  .bg-black-4 {
	background-color: $black-4;
  }
  
  .bg-black-5 {
	background-color: $black-5;
  }
  
  .bg-black-6 {
	background-color: $black-6;
  }
  
  .bg-black-7 {
	background-color: $black-7;
  }
  
  .bg-black-8 {
	background-color: $black-8;
  }
  
  .bg-black-9 {
	background-color: $black-9;
  }
  .bg-black{
	background-color:$black !important;
  }
  .bg-indigo {
	background-color: $primary;
  }
  
  .bg-purple {
	background-color: #6f42c1;
  }
  
  .bg-pink {
	background-color: $pink;
  }
  
  .bg-orange {
	background-color: $orange;
  }
  
  .bg-teal {
	background-color: $teal !important;
  }
  
  .bg-purple-dark {
	background-color: #59339d;
  }
  
  /* ###### 4.8 transparent colors ###### */
  
  .bg-success-transparent {
	background-color: #d4f2e1 !important;
  }
  
  .bg-danger-transparent {
	background-color: #f9e2e2 !important;
  }
  
  .bg-primary-transparent {
	background-color: #d9e8fe !important;
  }
  
  .bg-warning-transparent {
	background-color: rgb(253, 246, 222) !important;
  }
  
  .bg-pink-transparent {
	background-color: #fee5f1 !important;
  }
  
  .bg-purple-transparent {
	background-color: #e1defe !important;
  }
  
  .bg-teal-transparent {
	background-color: #d2f5f5 !important;
  }
  
  .bg-info-transparent {
	background-color: rgba(23, 162, 184, 0.2) !important;
  }
  
  .bg-orange-transparent {
	background-color: rgba(253, 126, 20, 0.2);
  }
  
  /* ###### 4.8 button-light ###### */
  
  .bg-primary {
	background-color: $primary !important;
  }
  
  a.bg-primary {
	&:hover, &:focus {
	  background-color: #0040ff !important;
	}
  }
  
  button.bg-primary {
	&:hover, &:focus {
	  background-color: #0040ff !important;
	}
  }
  
  .bg-secondary {
	background-color: $secondary !important;
  }
  
  a.bg-secondary {
	&:hover, &:focus {
	  background-color: #5f6d88 !important;
	}
  }
  
  button.bg-secondary {
	&:hover, &:focus {
	  background-color: #5f6d88 !important;
	}
  }
  
  .bg-success {
	background-color: $success !important;
  }
  
  a.bg-success {
	&:hover, &:focus {
	  background-color: #2a7d01 !important;
	}
  }
  
  button.bg-success {
	&:hover, &:focus {
	  background-color: #2a7d01 !important;
	}
  }
  
  .bg-info {
	background-color: $info !important;
  }
  
  a.bg-info {
	&:hover, &:focus {
	  background-color: #117a8b !important;
	}
  }
  
  button.bg-info {
	&:hover, &:focus {
	  background-color: #117a8b !important;
	}
  }
  
  .bg-warning {
	background-color: #ffc107 !important;
  }
  
  a.bg-warning {
	&:hover, &:focus {
	  background-color: #d39e00 !important;
	}
  }
  
  button.bg-warning {
	&:hover, &:focus {
	  background-color: #d39e00 !important;
	}
  }
  
  .bg-danger {
	background-color: $danger !important;
  }
  
  a.bg-danger {
	&:hover, &:focus {
	  background-color: #bd2130 !important;
	}
  }
  
  button.bg-danger {
	&:hover, &:focus {
	  background-color: #bd2130 !important;
	}
  }
  
  .bg-purple {
	background-color: $purple !important;
  }
  
  a.bg-purple {
	&:hover, &:focus {
	  background-color: #5930a2 !important;
	}
  }
  
  button.bg-purple {
	&:hover, &:focus {
	  background-color: #5930a2 !important;
	}
  }
  
  .bg-light {
	background-color:$gray-100 !important;
  }
  
  a.bg-light {
	&:hover, &:focus {
	  background-color: #d5d9e4 !important;
	}
  }
  
  button.bg-light {
	&:hover, &:focus {
	  background-color: #d5d9e4 !important;
	}
  }
  
  .bg-dark {
	background-color: $gray-800 !important;
  }
  
  a.bg-dark {
	&:hover, &:focus {
	  background-color: #283143 !important;
	}
  }
  
  button.bg-dark {
	&:hover, &:focus {
	  background-color: #283143 !important;
	}
  }
  
  .bg-white {
	background-color: $white !important;
  }
  
  .bg-transparent {
	background-color: transparent !important;
  }
  
  /*-----Gradient-badges-----*/
  
  .bg-primary-gradient {
	background: $primary-gradient !important;
  }
  
  a.bg-primary-gradient {
	&:hover, &:focus {
	  background: $primary-gradient !important;
	}
  }
  
  button.bg-primary-gradient {
	&:hover, &:focus {
	  background: $primary-gradient !important;
	}
  }
  
  .bg-secondary-gradient {
	background: $secondary-gradient !important;
  }
  
  a.bg-secondary-gradient {
	&:hover, &:focus {
	  background: $secondary-gradient !important;
	}
  }
  
  button.bg-secondary-gradient {
	&:hover, &:focus {
	  background: $secondary-gradient !important;
	}
  }
  
  .bg-success-gradient {
	background: $success-gradient !important;
  }
  
  a.bg-success-gradient {
	&:hover, &:focus {
	  background: $success-gradient !important;
	}
  }
  
  button.bg-success-gradient {
	&:hover, &:focus {
	  background: $success-gradient !important;
	}
  }
  
  .bg-info-gradient {
	background: $info-gradient;
  }
  
  a.bg-info-gradient {
	&:hover, &:focus {
	  background: $info-gradient;
	}
  }
  
  button.bg-info-gradient {
	&:hover, &:focus {
	  background: $info-gradient;
	}
  }
  
  .bg-warning-gradient {
	background: $warning-gradient
  }
  
  a.bg-warning-gradient {
	&:hover, &:focus {
	  background: $warning-gradient
	}
  }
  
  button.bg-warning-gradient {
	&:hover, &:focus {
	  background: $warning-gradient
	}
  }
  
  .bg-danger-gradient {
	background:$danger-gradient
  }
  
  a.bg-danger-gradient {
	&:hover, &:focus {
	  background:$danger-gradient
	}
  }
  
  button.bg-danger-gradient {
	&:hover, &:focus {
	  background:$danger-gradient
	}
  }
  
  .bg-purple-gradient {
	background: $purple-gradient
  }
  
  a.bg-purple-gradient {
	&:hover, &:focus {
	  background: $purple-gradient
	}
  }
  
  button.bg-purple-gradient {
	&:hover, &:focus {
	  background: $purple-gradient
	}
  }