
@import "../_variables";
/* Thumb Carousel */

.carousel-slider {
    #carousel {
      margin: 0;
    }
  
    #thumbcarousel {
      margin: 10px 0 0 0;
      padding: 0;
  
      .carousel-item {
        text-align: center;
  
        .thumb {
          width: 100%;
          margin: 0 2px;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          max-width: 100px;
  
          &:hover {
            border-color: #e8ebf3;
          }
        }
      }
    }
  
    .carousel-item img {
      width: 100%;
      height: auto;
    }
  }
  
  .carousel-control {
    &:active, &:focus, &:hover {
      color: #333;
    }
  }
  
  .carousel-caption, .carousel-control .fa {
    font: normal normal normal 30px/26px FontAwesome;
  }
  
  #basicSlider img {
    width: 100%;
  }
  
  #thumbcarousel {
    .carousel-control-prev {
      left: 0;
      background: $black-8;
    }
  
    .carousel-control-next {
      right: 0;
      background: $black-8;
    }
  }
  
  .owl-carousel button.owl-dot {
    background: rgba(105, 99, 255, 0.3);
    opacity: 7;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }
  
  .owl-nav button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098d1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  
  /* Timeline */