
@import "../_variables";
/* ###### 9.8 Typography   ###### */

.tx-black {
	font-weight: 900;
  }
  
  .tx-bold {
	font-weight: 700;
  }
  
  .tx-semibold {
	font-weight: 600;
  }
  
  .tx-medium {
	font-weight: 500;
  }
  
  .tx-normal {
	font-weight: 400;
  }
  
  .tx-light {
	font-weight: 300;
  }
  
  .tx-thin {
	font-weight: 200;
  }
  
  .tx-xthin {
	font-weight: 100;
  }
  
  .tx-sserif {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
  
  .tx-roboto {
	font-family: 'Roboto', sans-serif;
  }
  
  .tx-white {
	color: $white;
  }
  
  .tx-black {
	color: $black;
  }
  
  .tx-primary {
	color: $primary;
  }
  
  .tx-success {
	color: $success;
  }
  
  .tx-warning {
	color: #ffc107;
  }
  
  .tx-danger {
	color: $danger;
  }
  
  .tx-info {
	color: $info;
  }
  
  .tx-inverse {
	color: $gray-900;
  }
  
  .tx-teal {
	color: $teal;
  }
  
  .tx-dark {
	color: $gray-900;
  }
  
  .tx-indigo {
	color: $primary;
  }
  
  .tx-purple {
	color: #6f42c1;
  }
  
  .tx-orange {
	color: $orange;
  }
  
  .tx-pink {
	color: $pink;
  }
  
  .tx-gray-100 {
	color:$gray-100;
  }
  
  .tx-gray-200 {
	color: $gray-200;
  }
  
  .tx-gray-300 {
	color: $gray-300;
  }
  
  .tx-gray-400 {
	color: $gray-400;
  }
  
  .tx-gray-500 {
	color: $gray-500;
  }
  
  .tx-gray-600 {
	color: $secondary;
  }
  
  .tx-gray-700 {
	color: $gray-700;
  }
  
  .tx-gray-800 {
	color: $gray-800;
  }
  
  .tx-gray-900 {
	color: $gray-900;
  }
  
  .tx-white-2 {
	color: $white-2;
  }
  
  .tx-white-3 {
	color: $white-3;
  }
  
  .tx-white-4 {
	color: $white-4;
  }
  
  .tx-white-5 {
	color: $white-5;
  }
  
  .tx-white-6 {
	color: $white-6;
  }
  
  .tx-white-7 {
	color: $white-7;
  }
  
  .tx-white-8 {
	color: $white-8;
  }
  
  .tx-spacing-1 {
	letter-spacing: 0.5px;
  }
  
  .tx-spacing-2 {
	letter-spacing: 1px;
  }
  
  .tx-spacing-3 {
	letter-spacing: 1.5px;
  }
  
  .tx-spacing-4 {
	letter-spacing: 2px;
  }
  
  .tx-spacing-5 {
	letter-spacing: 2.5px;
  }
  
  .tx-spacing-6 {
	letter-spacing: 3px;
  }
  
  .tx-spacing-7 {
	letter-spacign: 3.5px;
  }
  
  .tx-spacing-8 {
	letter-spacing: 4px;
  }
  
  .tx-spacing--1 {
	letter-spacing: -0.5px;
  }
  
  .tx-spacing--2 {
	letter-spacing: -1px;
  }
  
  .tx-spacing--3 {
	letter-spacing: -1.5px;
  }
  
  .tx-spacing--4 {
	letter-spacing: -2px;
  }
  
  .tx-spacing--5 {
	letter-spacing: -2.5px;
  }
  
  .tx-spacing--6 {
	letter-spacing: -3px;
  }
  
  .tx-spacing--7 {
	letter-spacing: -3.5px;
  }
  
  .tx-spacing--8 {
	letter-spacing: -4px;
  }
  
  .tx-uppercase {
	text-transform: uppercase;
  }
  
  .tx-lowercase {
	text-transform: lowercase;
  }
  
  .tx-transform-none {
	text-transform: none;
  }
  
  .tx-center {
	text-align: center;
  }
  
  .tx-right {
	text-align: right;
  }
  
  .tx-left {
	text-align: left;
  }
  
  .tx-center-f {
	text-align: center !important;
  }
  
  .tx-right-f {
	text-align: right !important;
  }
  
  .tx-left-f {
	text-align: left !important;
  }
  
  .tx-italic {
	font-style: italic;
  }
  
  .tx-style-normal {
	font-style: normal;
  }
  
  .lh-base {
	line-height: 1.5;
  }
  
  .lh-normal {
	line-height: normal;
  }
  
  .lh-0 {
	line-height: 0;
  }
  
  .lh--1 {
	line-height: .1;
  }
  
  .lh--2 {
	line-height: .2;
  }
  
  .lh--3 {
	line-height: .3;
  }
  
  .lh--4 {
	line-height: .4;
  }
  
  .lh--5 {
	line-height: .5;
  }
  
  .lh--6 {
	line-height: .6;
  }
  
  .lh--7 {
	line-height: .7;
  }
  
  .lh--8 {
	line-height: .8;
  }
  
  .lh--9 {
	line-height: .9;
  }
  
  .lh-1 {
	line-height: 1.1;
  }
  
  .lh-2 {
	line-height: 1.2;
  }
  
  .lh-3 {
	line-height: 1.3;
  }
  
  .lh-4 {
	line-height: 1.4;
  }
  
  .lh-5 {
	line-height: 1.5;
  }
  
  .lh-6 {
	line-height: 1.6;
  }
  
  .lh-7 {
	line-height: 1.7;
  }
  
  .lh-8 {
	line-height: 1.8;
  }
  
  .lh-9 {
	line-height: 1.9;
  }
  
  .lh-10 {
	line-height: 2.0;
  }
  
  .lh-11 {
	line-height: 2.1;
  }
  
  .lh-12 {
	line-height: 2.2;
  }
  
  .lh-13 {
	line-height: 2.3;
  }
  
  .lh-14 {
	line-height: 2.4;
  }
  
  .lh-15 {
	line-height: 2.5;
  }
  
  .valign-top {
	vertical-align: top;
  }
  
  .valign-middle {
	vertical-align: middle;
  }
  
  .valign-bottom {
	vertical-align: baseline;
  }
  
  .valign-top-f {
	vertical-align: top !important;
  }
  
  .valign-middle-f {
	vertical-align: middle !important;
  }
  
  .valign-bottom-f {
	vertical-align: baseline !important;
  }
  
  .tx-base {
	font-size: 0.875rem;
  }
  
  .tx-8 {
	font-size: 8px;
  }
  
  .tx-8-f {
	font-size: 8px !important;
  }
  
  .tx-9 {
	font-size: 9px;
  }
  
  .tx-9-f {
	font-size: 9px !important;
  }
  
  .tx-10 {
	font-size: 10px;
  }
  
  .tx-10-f {
	font-size: 10px !important;
  }
  
  .tx-11 {
	font-size: 11px;
  }
  
  .tx-11-f {
	font-size: 11px !important;
  }
  
  .tx-12 {
	font-size: 12px;
  }
  
  .tx-12-f {
	font-size: 12px !important;
  }
  
  .tx-13 {
	font-size: 13px;
  }
  
  .tx-13-f {
	font-size: 13px !important;
  }
  
  .tx-14 {
	font-size: 14px;
  }
  
  .tx-14-f {
	font-size: 14px !important;
  }
  
  .tx-15 {
	font-size: 15px;
  }
  
  .tx-15-f {
	font-size: 15px !important;
  }
  
  .tx-16 {
	font-size: 16px;
  }
  
  .tx-16-f {
	font-size: 16px !important;
  }
  
  .tx-17 {
	font-size: 17px;
  }
  
  .tx-18 {
	font-size: 18px;
  }
  
  .tx-18-f {
	font-size: 18px !important;
  }
  
  .tx-20 {
	font-size: 20px;
  }
  
  .tx-20-f {
	font-size: 20px !important;
  }
  
  .tx-21 {
	font-size: 21px;
  }
  
  .tx-21-f {
	font-size: 21px !important;
  }
  
  .tx-22 {
	font-size: 22px;
  }
  
  .tx-22-f {
	font-size: 22px !important;
  }
  
  .tx-24 {
	font-size: 24px;
  }
  
  .tx-24-f {
	font-size: 24px !important;
  }
  
  .tx-26 {
	font-size: 26px;
  }
  
  .tx-26-f {
	font-size: 26px !important;
  }
  
  .tx-28 {
	font-size: 28px;
  }
  
  .tx-28-f {
	font-size: 28px !important;
  }
  
  .tx-30 {
	font-size: 30px;
  }
  
  .tx-30-f {
	font-size: 30px !important;
  }
  
  .tx-32 {
	font-size: 32px;
  }
  
  .tx-32-f {
	font-size: 32px !important;
  }
  
  .tx-34 {
	font-size: 34px;
  }
  
  .tx-34-f {
	font-size: 34px !important;
  }
  
  .tx-36 {
	font-size: 36px;
  }
  
  .tx-36-f {
	font-size: 36px !important;
  }
  
  .tx-38 {
	font-size: 38px;
  }
  
  .tx-38-f {
	font-size: 38px !important;
  }
  
  .tx-40 {
	font-size: 40px;
  }
  
  .tx-40-f {
	font-size: 40px !important;
  }
  
  .tx-42 {
	font-size: 42px;
  }
  
  .tx-42-f {
	font-size: 42px !important;
  }
  
  .tx-44 {
	font-size: 44px;
  }
  
  .tx-44-f {
	font-size: 44px !important;
  }
  
  .tx-46 {
	font-size: 46px;
  }
  
  .tx-46-f {
	font-size: 46px !important;
  }
  
  .tx-48 {
	font-size: 48px;
  }
  
  .tx-48-f {
	font-size: 48px !important;
  }
  
  .tx-50 {
	font-size: 50px;
  }
  
  .tx-50-f {
	font-size: 50px !important;
  }
  
  .tx-52 {
	font-size: 52px;
  }
  
  .tx-52-f {
	font-size: 52px !important;
  }
  
  .tx-54 {
	font-size: 54px;
  }
  
  .tx-54-f {
	font-size: 54px !important;
  }
  
  .tx-56 {
	font-size: 56px;
  }
  
  .tx-56-f {
	font-size: 56px !important;
  }
  
  .tx-58 {
	font-size: 58px;
  }
  
  .tx-58-f {
	font-size: 58px !important;
  }
  
  .tx-60 {
	font-size: 60px;
  }
  
  .tx-60-f {
	font-size: 60px !important;
  }
  
  .tx-62 {
	font-size: 62px;
  }
  
  .tx-62-f {
	font-size: 62px !important;
  }
  
  .tx-64 {
	font-size: 64px;
  }
  
  .tx-64-f {
	font-size: 64px !important;
  }
  
  .tx-66 {
	font-size: 66px;
  }
  
  .tx-66-f {
	font-size: 66px !important;
  }
  
  .tx-68 {
	font-size: 68px;
  }
  
  .tx-68-f {
	font-size: 68px !important;
  }
  
  .tx-70 {
	font-size: 70px;
  }
  
  .tx-70-f {
	font-size: 70px !important;
  }
  
  .tx-72 {
	font-size: 72px;
  }
  
  .tx-72-f {
	font-size: 72px !important;
  }
  
  .tx-74 {
	font-size: 74px;
  }
  
  .tx-74-f {
	font-size: 74px !important;
  }
  
  .tx-76 {
	font-size: 76px;
  }
  
  .tx-76-f {
	font-size: 76px !important;
  }
  
  .tx-78 {
	font-size: 78px;
  }
  
  .tx-78-f {
	font-size: 78px !important;
  }
  
  .tx-80 {
	font-size: 80px;
  }
  
  .tx-80-f {
	font-size: 80px !important;
  }
  
  .tx-82 {
	font-size: 82px;
  }
  
  .tx-82-f {
	font-size: 82px !important;
  }
  
  .tx-84 {
	font-size: 84px;
  }
  
  .tx-84-f {
	font-size: 84px !important;
  }
  
  .tx-86 {
	font-size: 86px;
  }
  
  .tx-86-f {
	font-size: 86px !important;
  }
  
  .tx-88 {
	font-size: 88px;
  }
  
  .tx-88-f {
	font-size: 88px !important;
  }
  
  .tx-90 {
	font-size: 90px;
  }
  
  .tx-90-f {
	font-size: 90px !important;
  }
  
  .tx-92 {
	font-size: 92px;
  }
  
  .tx-92-f {
	font-size: 92px !important;
  }
  
  .tx-94 {
	font-size: 94px;
  }
  
  .tx-94-f {
	font-size: 94px !important;
  }
  
  .tx-96 {
	font-size: 96px;
  }
  
  .tx-96-f {
	font-size: 96px !important;
  }
  
  .tx-98 {
	font-size: 98px;
  }
  
  .tx-98-f {
	font-size: 98px !important;
  }
  
  .tx-100 {
	font-size: 100px;
  }
  
  .tx-100-f {
	font-size: 100px !important;
  }
  
  .tx-102 {
	font-size: 102px;
  }
  
  .tx-102-f {
	font-size: 102px !important;
  }
  
  .tx-104 {
	font-size: 104px;
  }
  
  .tx-104-f {
	font-size: 104px !important;
  }
  
  .tx-106 {
	font-size: 106px;
  }
  
  .tx-106-f {
	font-size: 106px !important;
  }
  
  .tx-108 {
	font-size: 108px;
  }
  
  .tx-108-f {
	font-size: 108px !important;
  }
  
  .tx-110 {
	font-size: 110px;
  }
  
  .tx-110-f {
	font-size: 110px !important;
  }
  
  .tx-112 {
	font-size: 112px;
  }
  
  .tx-112-f {
	font-size: 112px !important;
  }
  
  .tx-114 {
	font-size: 114px;
  }
  
  .tx-114-f {
	font-size: 114px !important;
  }
  
  .tx-116 {
	font-size: 116px;
  }
  
  .tx-116-f {
	font-size: 116px !important;
  }
  
  .tx-118 {
	font-size: 118px;
  }
  
  .tx-118-f {
	font-size: 118px !important;
  }
  
  .tx-120 {
	font-size: 120px;
  }
  
  .tx-120-f {
	font-size: 120px !important;
  }
  
  .tx-122 {
	font-size: 122px;
  }
  
  .tx-122-f {
	font-size: 122px !important;
  }
  
  .tx-124 {
	font-size: 124px;
  }
  
  .tx-124-f {
	font-size: 124px !important;
  }
  
  .tx-126 {
	font-size: 126px;
  }
  
  .tx-126-f {
	font-size: 126px !important;
  }
  
  .tx-128 {
	font-size: 128px;
  }
  
  .tx-128-f {
	font-size: 128px !important;
  }
  
  .tx-130 {
	font-size: 130px;
  }
  
  .tx-130-f {
	font-size: 130px !important;
  }
  
  .tx-132 {
	font-size: 132px;
  }
  
  .tx-132-f {
	font-size: 132px !important;
  }
  
  .tx-134 {
	font-size: 134px;
  }
  
  .tx-134-f {
	font-size: 134px !important;
  }
  
  .tx-136 {
	font-size: 136px;
  }
  
  .tx-136-f {
	font-size: 136px !important;
  }
  
  .tx-138 {
	font-size: 138px;
  }
  
  .tx-138-f {
	font-size: 138px !important;
  }
  
  .tx-140 {
	font-size: 140px;
  }
  
  .tx-140-f {
	font-size: 140px !important;
  }
  
  @media (min-width: 480px) {
	.tx-xs-8 {
	  font-size: 8px;
	}
  
	.tx-xs-8-f {
	  font-size: 8px !important;
	}
  
	.tx-xs-9 {
	  font-size: 9px;
	}
  
	.tx-xs-9-f {
	  font-size: 9px !important;
	}
  
	.tx-xs-10 {
	  font-size: 10px;
	}
  
	.tx-xs-10-f {
	  font-size: 10px !important;
	}
  
	.tx-xs-11 {
	  font-size: 11px;
	}
  
	.tx-xs-11-f {
	  font-size: 11px !important;
	}
  
	.tx-xs-12 {
	  font-size: 12px;
	}
  
	.tx-xs-12-f {
	  font-size: 12px !important;
	}
  
	.tx-xs-13 {
	  font-size: 13px;
	}
  
	.tx-xs-13-f {
	  font-size: 13px !important;
	}
  
	.tx-xs-14 {
	  font-size: 14px;
	}
  
	.tx-xs-14-f {
	  font-size: 14px !important;
	}
  
	.tx-xs-15 {
	  font-size: 15px;
	}
  
	.tx-xs-15-f {
	  font-size: 15px !important;
	}
  
	.tx-xs-16 {
	  font-size: 16px;
	}
  
	.tx-xs-16-f {
	  font-size: 16px !important;
	}
  
	.tx-xs-18 {
	  font-size: 18px;
	}
  
	.tx-xs-18-f {
	  font-size: 18px !important;
	}
  
	.tx-xs-20 {
	  font-size: 20px;
	}
  
	.tx-xs-20-f {
	  font-size: 20px !important;
	}
  
	.tx-xs-22 {
	  font-size: 22px;
	}
  
	.tx-xs-22-f {
	  font-size: 22px !important;
	}
  
	.tx-xs-24 {
	  font-size: 24px;
	}
  
	.tx-xs-24-f {
	  font-size: 24px !important;
	}
  
	.tx-xs-26 {
	  font-size: 26px;
	}
  
	.tx-xs-26-f {
	  font-size: 26px !important;
	}
  
	.tx-xs-28 {
	  font-size: 28px;
	}
  
	.tx-xs-28-f {
	  font-size: 28px !important;
	}
  
	.tx-xs-30 {
	  font-size: 30px;
	}
  
	.tx-xs-30-f {
	  font-size: 30px !important;
	}
  
	.tx-xs-32 {
	  font-size: 32px;
	}
  
	.tx-xs-32-f {
	  font-size: 32px !important;
	}
  
	.tx-xs-34 {
	  font-size: 34px;
	}
  
	.tx-xs-34-f {
	  font-size: 34px !important;
	}
  
	.tx-xs-36 {
	  font-size: 36px;
	}
  
	.tx-xs-36-f {
	  font-size: 36px !important;
	}
  
	.tx-xs-38 {
	  font-size: 38px;
	}
  
	.tx-xs-38-f {
	  font-size: 38px !important;
	}
  
	.tx-xs-40 {
	  font-size: 40px;
	}
  
	.tx-xs-40-f {
	  font-size: 40px !important;
	}
  
	.tx-xs-42 {
	  font-size: 42px;
	}
  
	.tx-xs-42-f {
	  font-size: 42px !important;
	}
  
	.tx-xs-44 {
	  font-size: 44px;
	}
  
	.tx-xs-44-f {
	  font-size: 44px !important;
	}
  
	.tx-xs-46 {
	  font-size: 46px;
	}
  
	.tx-xs-46-f {
	  font-size: 46px !important;
	}
  
	.tx-xs-48 {
	  font-size: 48px;
	}
  
	.tx-xs-48-f {
	  font-size: 48px !important;
	}
  
	.tx-xs-50 {
	  font-size: 50px;
	}
  
	.tx-xs-50-f {
	  font-size: 50px !important;
	}
  
	.tx-xs-52 {
	  font-size: 52px;
	}
  
	.tx-xs-52-f {
	  font-size: 52px !important;
	}
  
	.tx-xs-54 {
	  font-size: 54px;
	}
  
	.tx-xs-54-f {
	  font-size: 54px !important;
	}
  
	.tx-xs-56 {
	  font-size: 56px;
	}
  
	.tx-xs-56-f {
	  font-size: 56px !important;
	}
  
	.tx-xs-58 {
	  font-size: 58px;
	}
  
	.tx-xs-58-f {
	  font-size: 58px !important;
	}
  
	.tx-xs-60 {
	  font-size: 60px;
	}
  
	.tx-xs-60-f {
	  font-size: 60px !important;
	}
  
	.tx-xs-62 {
	  font-size: 62px;
	}
  
	.tx-xs-62-f {
	  font-size: 62px !important;
	}
  
	.tx-xs-64 {
	  font-size: 64px;
	}
  
	.tx-xs-64-f {
	  font-size: 64px !important;
	}
  
	.tx-xs-66 {
	  font-size: 66px;
	}
  
	.tx-xs-66-f {
	  font-size: 66px !important;
	}
  
	.tx-xs-68 {
	  font-size: 68px;
	}
  
	.tx-xs-68-f {
	  font-size: 68px !important;
	}
  
	.tx-xs-70 {
	  font-size: 70px;
	}
  
	.tx-xs-70-f {
	  font-size: 70px !important;
	}
  
	.tx-xs-72 {
	  font-size: 72px;
	}
  
	.tx-xs-72-f {
	  font-size: 72px !important;
	}
  
	.tx-xs-74 {
	  font-size: 74px;
	}
  
	.tx-xs-74-f {
	  font-size: 74px !important;
	}
  
	.tx-xs-76 {
	  font-size: 76px;
	}
  
	.tx-xs-76-f {
	  font-size: 76px !important;
	}
  
	.tx-xs-78 {
	  font-size: 78px;
	}
  
	.tx-xs-78-f {
	  font-size: 78px !important;
	}
  
	.tx-xs-80 {
	  font-size: 80px;
	}
  
	.tx-xs-80-f {
	  font-size: 80px !important;
	}
  
	.tx-xs-82 {
	  font-size: 82px;
	}
  
	.tx-xs-82-f {
	  font-size: 82px !important;
	}
  
	.tx-xs-84 {
	  font-size: 84px;
	}
  
	.tx-xs-84-f {
	  font-size: 84px !important;
	}
  
	.tx-xs-86 {
	  font-size: 86px;
	}
  
	.tx-xs-86-f {
	  font-size: 86px !important;
	}
  
	.tx-xs-88 {
	  font-size: 88px;
	}
  
	.tx-xs-88-f {
	  font-size: 88px !important;
	}
  
	.tx-xs-90 {
	  font-size: 90px;
	}
  
	.tx-xs-90-f {
	  font-size: 90px !important;
	}
  
	.tx-xs-92 {
	  font-size: 92px;
	}
  
	.tx-xs-92-f {
	  font-size: 92px !important;
	}
  
	.tx-xs-94 {
	  font-size: 94px;
	}
  
	.tx-xs-94-f {
	  font-size: 94px !important;
	}
  
	.tx-xs-96 {
	  font-size: 96px;
	}
  
	.tx-xs-96-f {
	  font-size: 96px !important;
	}
  
	.tx-xs-98 {
	  font-size: 98px;
	}
  
	.tx-xs-98-f {
	  font-size: 98px !important;
	}
  
	.tx-xs-100 {
	  font-size: 100px;
	}
  
	.tx-xs-100-f {
	  font-size: 100px !important;
	}
  
	.tx-xs-102 {
	  font-size: 102px;
	}
  
	.tx-xs-102-f {
	  font-size: 102px !important;
	}
  
	.tx-xs-104 {
	  font-size: 104px;
	}
  
	.tx-xs-104-f {
	  font-size: 104px !important;
	}
  
	.tx-xs-106 {
	  font-size: 106px;
	}
  
	.tx-xs-106-f {
	  font-size: 106px !important;
	}
  
	.tx-xs-108 {
	  font-size: 108px;
	}
  
	.tx-xs-108-f {
	  font-size: 108px !important;
	}
  
	.tx-xs-110 {
	  font-size: 110px;
	}
  
	.tx-xs-110-f {
	  font-size: 110px !important;
	}
  
	.tx-xs-112 {
	  font-size: 112px;
	}
  
	.tx-xs-112-f {
	  font-size: 112px !important;
	}
  
	.tx-xs-114 {
	  font-size: 114px;
	}
  
	.tx-xs-114-f {
	  font-size: 114px !important;
	}
  
	.tx-xs-116 {
	  font-size: 116px;
	}
  
	.tx-xs-116-f {
	  font-size: 116px !important;
	}
  
	.tx-xs-118 {
	  font-size: 118px;
	}
  
	.tx-xs-118-f {
	  font-size: 118px !important;
	}
  
	.tx-xs-120 {
	  font-size: 120px;
	}
  
	.tx-xs-120-f {
	  font-size: 120px !important;
	}
  
	.tx-xs-122 {
	  font-size: 122px;
	}
  
	.tx-xs-122-f {
	  font-size: 122px !important;
	}
  
	.tx-xs-124 {
	  font-size: 124px;
	}
  
	.tx-xs-124-f {
	  font-size: 124px !important;
	}
  
	.tx-xs-126 {
	  font-size: 126px;
	}
  
	.tx-xs-126-f {
	  font-size: 126px !important;
	}
  
	.tx-xs-128 {
	  font-size: 128px;
	}
  
	.tx-xs-128-f {
	  font-size: 128px !important;
	}
  
	.tx-xs-130 {
	  font-size: 130px;
	}
  
	.tx-xs-130-f {
	  font-size: 130px !important;
	}
  
	.tx-xs-132 {
	  font-size: 132px;
	}
  
	.tx-xs-132-f {
	  font-size: 132px !important;
	}
  
	.tx-xs-134 {
	  font-size: 134px;
	}
  
	.tx-xs-134-f {
	  font-size: 134px !important;
	}
  
	.tx-xs-136 {
	  font-size: 136px;
	}
  
	.tx-xs-136-f {
	  font-size: 136px !important;
	}
  
	.tx-xs-138 {
	  font-size: 138px;
	}
  
	.tx-xs-138-f {
	  font-size: 138px !important;
	}
  
	.tx-xs-140 {
	  font-size: 140px;
	}
  
	.tx-xs-140-f {
	  font-size: 140px !important;
	}
  
	.tx-xs-base {
	  font-size: 0.875rem;
	}
  
	.tx-xs-bold {
	  font-weight: 700;
	}
  
	.tx-xs-semibold {
	  font-weight: 600;
	}
  
	.tx-xs-medium {
	  font-weight: 500;
	}
  
	.tx-xs-normal {
	  font-weight: 400;
	}
  
	.tx-xs-light {
	  font-weight: 300;
	}
  
	.tx-xs-thin {
	  font-weight: 200;
	}
  
	.tx-xs-xthin {
	  font-weight: 100;
	}
  
	.lh-xs-0 {
	  line-height: 0;
	}
  
	.lh-xs-1 {
	  line-height: 1.1;
	}
  
	.lh-xs-2 {
	  line-height: 1.2;
	}
  
	.lh-xs-3 {
	  line-height: 1.3;
	}
  
	.lh-xs-4 {
	  line-height: 1.4;
	}
  
	.lh-xs-5 {
	  line-height: 1.5;
	}
  
	.lh-xs-6 {
	  line-height: 1.6;
	}
  
	.lh-xs-7 {
	  line-height: 1.7;
	}
  
	.lh-xs-8 {
	  line-height: 1.8;
	}
  
	.lh-xs-9 {
	  line-height: 1.9;
	}
  
	.lh-xs-10 {
	  line-height: 2.0;
	}
  
	.lh-xs-11 {
	  line-height: 2.1;
	}
  
	.lh-xs-12 {
	  line-height: 2.2;
	}
  
	.lh-xs-13 {
	  line-height: 2.3;
	}
  
	.lh-xs-14 {
	  line-height: 2.4;
	}
  
	.lh-xs-15 {
	  line-height: 2.5;
	}
  
	.tx-xs-center {
	  text-align: center;
	}
  
	.tx-xs-right {
	  text-align: right;
	}
  
	.tx-xs-left {
	  text-align: left;
	}
  
	.tx-xs-center-f {
	  text-align: center !important;
	}
  
	.tx-xs-right-f {
	  text-align: right !important;
	}
  
	.tx-xs-left-f {
	  text-align: left !important;
	}
  }
  
  @media (min-width: 576px) {
	.tx-sm-8 {
	  font-size: 8px;
	}
  
	.tx-sm-8-f {
	  font-size: 8px !important;
	}
  
	.tx-sm-9 {
	  font-size: 9px;
	}
  
	.tx-sm-9-f {
	  font-size: 9px !important;
	}
  
	.tx-sm-10 {
	  font-size: 10px;
	}
  
	.tx-sm-10-f {
	  font-size: 10px !important;
	}
  
	.tx-sm-11 {
	  font-size: 11px;
	}
  
	.tx-sm-11-f {
	  font-size: 11px !important;
	}
  
	.tx-sm-12 {
	  font-size: 12px;
	}
  
	.tx-sm-12-f {
	  font-size: 12px !important;
	}
  
	.tx-sm-13 {
	  font-size: 13px;
	}
  
	.tx-sm-13-f {
	  font-size: 13px !important;
	}
  
	.tx-sm-14 {
	  font-size: 14px;
	}
  
	.tx-sm-14-f {
	  font-size: 14px !important;
	}
  
	.tx-sm-15 {
	  font-size: 15px;
	}
  
	.tx-sm-15-f {
	  font-size: 15px !important;
	}
  
	.tx-sm-16 {
	  font-size: 16px;
	}
  
	.tx-sm-16-f {
	  font-size: 16px !important;
	}
  
	.tx-sm-18 {
	  font-size: 18px;
	}
  
	.tx-sm-18-f {
	  font-size: 18px !important;
	}
  
	.tx-sm-20 {
	  font-size: 20px;
	}
  
	.tx-sm-20-f {
	  font-size: 20px !important;
	}
  
	.tx-sm-22 {
	  font-size: 22px;
	}
  
	.tx-sm-22-f {
	  font-size: 22px !important;
	}
  
	.tx-sm-24 {
	  font-size: 24px;
	}
  
	.tx-sm-24-f {
	  font-size: 24px !important;
	}
  
	.tx-sm-26 {
	  font-size: 26px;
	}
  
	.tx-sm-26-f {
	  font-size: 26px !important;
	}
  
	.tx-sm-28 {
	  font-size: 28px;
	}
  
	.tx-sm-28-f {
	  font-size: 28px !important;
	}
  
	.tx-sm-30 {
	  font-size: 30px;
	}
  
	.tx-sm-30-f {
	  font-size: 30px !important;
	}
  
	.tx-sm-32 {
	  font-size: 32px;
	}
  
	.tx-sm-32-f {
	  font-size: 32px !important;
	}
  
	.tx-sm-34 {
	  font-size: 34px;
	}
  
	.tx-sm-34-f {
	  font-size: 34px !important;
	}
  
	.tx-sm-36 {
	  font-size: 36px;
	}
  
	.tx-sm-36-f {
	  font-size: 36px !important;
	}
  
	.tx-sm-38 {
	  font-size: 38px;
	}
  
	.tx-sm-38-f {
	  font-size: 38px !important;
	}
  
	.tx-sm-40 {
	  font-size: 40px;
	}
  
	.tx-sm-40-f {
	  font-size: 40px !important;
	}
  
	.tx-sm-42 {
	  font-size: 42px;
	}
  
	.tx-sm-42-f {
	  font-size: 42px !important;
	}
  
	.tx-sm-44 {
	  font-size: 44px;
	}
  
	.tx-sm-44-f {
	  font-size: 44px !important;
	}
  
	.tx-sm-46 {
	  font-size: 46px;
	}
  
	.tx-sm-46-f {
	  font-size: 46px !important;
	}
  
	.tx-sm-48 {
	  font-size: 48px;
	}
  
	.tx-sm-48-f {
	  font-size: 48px !important;
	}
  
	.tx-sm-50 {
	  font-size: 50px;
	}
  
	.tx-sm-50-f {
	  font-size: 50px !important;
	}
  
	.tx-sm-52 {
	  font-size: 52px;
	}
  
	.tx-sm-52-f {
	  font-size: 52px !important;
	}
  
	.tx-sm-54 {
	  font-size: 54px;
	}
  
	.tx-sm-54-f {
	  font-size: 54px !important;
	}
  
	.tx-sm-56 {
	  font-size: 56px;
	}
  
	.tx-sm-56-f {
	  font-size: 56px !important;
	}
  
	.tx-sm-58 {
	  font-size: 58px;
	}
  
	.tx-sm-58-f {
	  font-size: 58px !important;
	}
  
	.tx-sm-60 {
	  font-size: 60px;
	}
  
	.tx-sm-60-f {
	  font-size: 60px !important;
	}
  
	.tx-sm-62 {
	  font-size: 62px;
	}
  
	.tx-sm-62-f {
	  font-size: 62px !important;
	}
  
	.tx-sm-64 {
	  font-size: 64px;
	}
  
	.tx-sm-64-f {
	  font-size: 64px !important;
	}
  
	.tx-sm-66 {
	  font-size: 66px;
	}
  
	.tx-sm-66-f {
	  font-size: 66px !important;
	}
  
	.tx-sm-68 {
	  font-size: 68px;
	}
  
	.tx-sm-68-f {
	  font-size: 68px !important;
	}
  
	.tx-sm-70 {
	  font-size: 70px;
	}
  
	.tx-sm-70-f {
	  font-size: 70px !important;
	}
  
	.tx-sm-72 {
	  font-size: 72px;
	}
  
	.tx-sm-72-f {
	  font-size: 72px !important;
	}
  
	.tx-sm-74 {
	  font-size: 74px;
	}
  
	.tx-sm-74-f {
	  font-size: 74px !important;
	}
  
	.tx-sm-76 {
	  font-size: 76px;
	}
  
	.tx-sm-76-f {
	  font-size: 76px !important;
	}
  
	.tx-sm-78 {
	  font-size: 78px;
	}
  
	.tx-sm-78-f {
	  font-size: 78px !important;
	}
  
	.tx-sm-80 {
	  font-size: 80px;
	}
  
	.tx-sm-80-f {
	  font-size: 80px !important;
	}
  
	.tx-sm-82 {
	  font-size: 82px;
	}
  
	.tx-sm-82-f {
	  font-size: 82px !important;
	}
  
	.tx-sm-84 {
	  font-size: 84px;
	}
  
	.tx-sm-84-f {
	  font-size: 84px !important;
	}
  
	.tx-sm-86 {
	  font-size: 86px;
	}
  
	.tx-sm-86-f {
	  font-size: 86px !important;
	}
  
	.tx-sm-88 {
	  font-size: 88px;
	}
  
	.tx-sm-88-f {
	  font-size: 88px !important;
	}
  
	.tx-sm-90 {
	  font-size: 90px;
	}
  
	.tx-sm-90-f {
	  font-size: 90px !important;
	}
  
	.tx-sm-92 {
	  font-size: 92px;
	}
  
	.tx-sm-92-f {
	  font-size: 92px !important;
	}
  
	.tx-sm-94 {
	  font-size: 94px;
	}
  
	.tx-sm-94-f {
	  font-size: 94px !important;
	}
  
	.tx-sm-96 {
	  font-size: 96px;
	}
  
	.tx-sm-96-f {
	  font-size: 96px !important;
	}
  
	.tx-sm-98 {
	  font-size: 98px;
	}
  
	.tx-sm-98-f {
	  font-size: 98px !important;
	}
  
	.tx-sm-100 {
	  font-size: 100px;
	}
  
	.tx-sm-100-f {
	  font-size: 100px !important;
	}
  
	.tx-sm-102 {
	  font-size: 102px;
	}
  
	.tx-sm-102-f {
	  font-size: 102px !important;
	}
  
	.tx-sm-104 {
	  font-size: 104px;
	}
  
	.tx-sm-104-f {
	  font-size: 104px !important;
	}
  
	.tx-sm-106 {
	  font-size: 106px;
	}
  
	.tx-sm-106-f {
	  font-size: 106px !important;
	}
  
	.tx-sm-108 {
	  font-size: 108px;
	}
  
	.tx-sm-108-f {
	  font-size: 108px !important;
	}
  
	.tx-sm-110 {
	  font-size: 110px;
	}
  
	.tx-sm-110-f {
	  font-size: 110px !important;
	}
  
	.tx-sm-112 {
	  font-size: 112px;
	}
  
	.tx-sm-112-f {
	  font-size: 112px !important;
	}
  
	.tx-sm-114 {
	  font-size: 114px;
	}
  
	.tx-sm-114-f {
	  font-size: 114px !important;
	}
  
	.tx-sm-116 {
	  font-size: 116px;
	}
  
	.tx-sm-116-f {
	  font-size: 116px !important;
	}
  
	.tx-sm-118 {
	  font-size: 118px;
	}
  
	.tx-sm-118-f {
	  font-size: 118px !important;
	}
  
	.tx-sm-120 {
	  font-size: 120px;
	}
  
	.tx-sm-120-f {
	  font-size: 120px !important;
	}
  
	.tx-sm-122 {
	  font-size: 122px;
	}
  
	.tx-sm-122-f {
	  font-size: 122px !important;
	}
  
	.tx-sm-124 {
	  font-size: 124px;
	}
  
	.tx-sm-124-f {
	  font-size: 124px !important;
	}
  
	.tx-sm-126 {
	  font-size: 126px;
	}
  
	.tx-sm-126-f {
	  font-size: 126px !important;
	}
  
	.tx-sm-128 {
	  font-size: 128px;
	}
  
	.tx-sm-128-f {
	  font-size: 128px !important;
	}
  
	.tx-sm-130 {
	  font-size: 130px;
	}
  
	.tx-sm-130-f {
	  font-size: 130px !important;
	}
  
	.tx-sm-132 {
	  font-size: 132px;
	}
  
	.tx-sm-132-f {
	  font-size: 132px !important;
	}
  
	.tx-sm-134 {
	  font-size: 134px;
	}
  
	.tx-sm-134-f {
	  font-size: 134px !important;
	}
  
	.tx-sm-136 {
	  font-size: 136px;
	}
  
	.tx-sm-136-f {
	  font-size: 136px !important;
	}
  
	.tx-sm-138 {
	  font-size: 138px;
	}
  
	.tx-sm-138-f {
	  font-size: 138px !important;
	}
  
	.tx-sm-140 {
	  font-size: 140px;
	}
  
	.tx-sm-140-f {
	  font-size: 140px !important;
	}
  
	.tx-sm-base {
	  font-size: 0.875rem;
	}
  
	.tx-sm-bold {
	  font-weight: 700;
	}
  
	.tx-sm-semibold {
	  font-weight: 600;
	}
  
	.tx-sm-medium {
	  font-weight: 500;
	}
  
	.tx-sm-normal {
	  font-weight: 400;
	}
  
	.tx-sm-light {
	  font-weight: 300;
	}
  
	.tx-sm-thin {
	  font-weight: 200;
	}
  
	.tx-sm-xthin {
	  font-weight: 100;
	}
  
	.lh-sm-0 {
	  line-height: 0;
	}
  
	.lh-sm-1 {
	  line-height: 1.1;
	}
  
	.lh-sm-2 {
	  line-height: 1.2;
	}
  
	.lh-sm-3 {
	  line-height: 1.3;
	}
  
	.lh-sm-4 {
	  line-height: 1.4;
	}
  
	.lh-sm-5 {
	  line-height: 1.5;
	}
  
	.lh-sm-6 {
	  line-height: 1.6;
	}
  
	.lh-sm-7 {
	  line-height: 1.7;
	}
  
	.lh-sm-8 {
	  line-height: 1.8;
	}
  
	.lh-sm-9 {
	  line-height: 1.9;
	}
  
	.lh-sm-10 {
	  line-height: 2.0;
	}
  
	.lh-sm-11 {
	  line-height: 2.1;
	}
  
	.lh-sm-12 {
	  line-height: 2.2;
	}
  
	.lh-sm-13 {
	  line-height: 2.3;
	}
  
	.lh-sm-14 {
	  line-height: 2.4;
	}
  
	.lh-sm-15 {
	  line-height: 2.5;
	}
  
	.tx-sm-center {
	  text-align: center;
	}
  
	.tx-sm-right {
	  text-align: right;
	}
  
	.tx-sm-left {
	  text-align: left;
	}
  
	.tx-sm-center-f {
	  text-align: center !important;
	}
  
	.tx-sm-right-f {
	  text-align: right !important;
	}
  
	.tx-sm-left-f {
	  text-align: left !important;
	}
  }
  
  @media (min-width: 768px) {
	.tx-md-8 {
	  font-size: 8px;
	}
  
	.tx-md-8-f {
	  font-size: 8px !important;
	}
  
	.tx-md-9 {
	  font-size: 9px;
	}
  
	.tx-md-9-f {
	  font-size: 9px !important;
	}
  
	.tx-md-10 {
	  font-size: 10px;
	}
  
	.tx-md-10-f {
	  font-size: 10px !important;
	}
  
	.tx-md-11 {
	  font-size: 11px;
	}
  
	.tx-md-11-f {
	  font-size: 11px !important;
	}
  
	.tx-md-12 {
	  font-size: 12px;
	}
  
	.tx-md-12-f {
	  font-size: 12px !important;
	}
  
	.tx-md-13 {
	  font-size: 13px;
	}
  
	.tx-md-13-f {
	  font-size: 13px !important;
	}
  
	.tx-md-14 {
	  font-size: 14px;
	}
  
	.tx-md-14-f {
	  font-size: 14px !important;
	}
  
	.tx-md-15 {
	  font-size: 15px;
	}
  
	.tx-md-15-f {
	  font-size: 15px !important;
	}
  
	.tx-md-16 {
	  font-size: 16px;
	}
  
	.tx-md-16-f {
	  font-size: 16px !important;
	}
  
	.tx-md-18 {
	  font-size: 18px;
	}
  
	.tx-md-18-f {
	  font-size: 18px !important;
	}
  
	.tx-md-20 {
	  font-size: 20px;
	}
  
	.tx-md-20-f {
	  font-size: 20px !important;
	}
  
	.tx-md-22 {
	  font-size: 22px;
	}
  
	.tx-md-22-f {
	  font-size: 22px !important;
	}
  
	.tx-md-24 {
	  font-size: 24px;
	}
  
	.tx-md-24-f {
	  font-size: 24px !important;
	}
  
	.tx-md-26 {
	  font-size: 26px;
	}
  
	.tx-md-26-f {
	  font-size: 26px !important;
	}
  
	.tx-md-28 {
	  font-size: 28px;
	}
  
	.tx-md-28-f {
	  font-size: 28px !important;
	}
  
	.tx-md-30 {
	  font-size: 30px;
	}
  
	.tx-md-30-f {
	  font-size: 30px !important;
	}
  
	.tx-md-32 {
	  font-size: 32px;
	}
  
	.tx-md-32-f {
	  font-size: 32px !important;
	}
  
	.tx-md-34 {
	  font-size: 34px;
	}
  
	.tx-md-34-f {
	  font-size: 34px !important;
	}
  
	.tx-md-36 {
	  font-size: 36px;
	}
  
	.tx-md-36-f {
	  font-size: 36px !important;
	}
  
	.tx-md-38 {
	  font-size: 38px;
	}
  
	.tx-md-38-f {
	  font-size: 38px !important;
	}
  
	.tx-md-40 {
	  font-size: 40px;
	}
  
	.tx-md-40-f {
	  font-size: 40px !important;
	}
  
	.tx-md-42 {
	  font-size: 42px;
	}
  
	.tx-md-42-f {
	  font-size: 42px !important;
	}
  
	.tx-md-44 {
	  font-size: 44px;
	}
  
	.tx-md-44-f {
	  font-size: 44px !important;
	}
  
	.tx-md-46 {
	  font-size: 46px;
	}
  
	.tx-md-46-f {
	  font-size: 46px !important;
	}
  
	.tx-md-48 {
	  font-size: 48px;
	}
  
	.tx-md-48-f {
	  font-size: 48px !important;
	}
  
	.tx-md-50 {
	  font-size: 50px;
	}
  
	.tx-md-50-f {
	  font-size: 50px !important;
	}
  
	.tx-md-52 {
	  font-size: 52px;
	}
  
	.tx-md-52-f {
	  font-size: 52px !important;
	}
  
	.tx-md-54 {
	  font-size: 54px;
	}
  
	.tx-md-54-f {
	  font-size: 54px !important;
	}
  
	.tx-md-56 {
	  font-size: 56px;
	}
  
	.tx-md-56-f {
	  font-size: 56px !important;
	}
  
	.tx-md-58 {
	  font-size: 58px;
	}
  
	.tx-md-58-f {
	  font-size: 58px !important;
	}
  
	.tx-md-60 {
	  font-size: 60px;
	}
  
	.tx-md-60-f {
	  font-size: 60px !important;
	}
  
	.tx-md-62 {
	  font-size: 62px;
	}
  
	.tx-md-62-f {
	  font-size: 62px !important;
	}
  
	.tx-md-64 {
	  font-size: 64px;
	}
  
	.tx-md-64-f {
	  font-size: 64px !important;
	}
  
	.tx-md-66 {
	  font-size: 66px;
	}
  
	.tx-md-66-f {
	  font-size: 66px !important;
	}
  
	.tx-md-68 {
	  font-size: 68px;
	}
  
	.tx-md-68-f {
	  font-size: 68px !important;
	}
  
	.tx-md-70 {
	  font-size: 70px;
	}
  
	.tx-md-70-f {
	  font-size: 70px !important;
	}
  
	.tx-md-72 {
	  font-size: 72px;
	}
  
	.tx-md-72-f {
	  font-size: 72px !important;
	}
  
	.tx-md-74 {
	  font-size: 74px;
	}
  
	.tx-md-74-f {
	  font-size: 74px !important;
	}
  
	.tx-md-76 {
	  font-size: 76px;
	}
  
	.tx-md-76-f {
	  font-size: 76px !important;
	}
  
	.tx-md-78 {
	  font-size: 78px;
	}
  
	.tx-md-78-f {
	  font-size: 78px !important;
	}
  
	.tx-md-80 {
	  font-size: 80px;
	}
  
	.tx-md-80-f {
	  font-size: 80px !important;
	}
  
	.tx-md-82 {
	  font-size: 82px;
	}
  
	.tx-md-82-f {
	  font-size: 82px !important;
	}
  
	.tx-md-84 {
	  font-size: 84px;
	}
  
	.tx-md-84-f {
	  font-size: 84px !important;
	}
  
	.tx-md-86 {
	  font-size: 86px;
	}
  
	.tx-md-86-f {
	  font-size: 86px !important;
	}
  
	.tx-md-88 {
	  font-size: 88px;
	}
  
	.tx-md-88-f {
	  font-size: 88px !important;
	}
  
	.tx-md-90 {
	  font-size: 90px;
	}
  
	.tx-md-90-f {
	  font-size: 90px !important;
	}
  
	.tx-md-92 {
	  font-size: 92px;
	}
  
	.tx-md-92-f {
	  font-size: 92px !important;
	}
  
	.tx-md-94 {
	  font-size: 94px;
	}
  
	.tx-md-94-f {
	  font-size: 94px !important;
	}
  
	.tx-md-96 {
	  font-size: 96px;
	}
  
	.tx-md-96-f {
	  font-size: 96px !important;
	}
  
	.tx-md-98 {
	  font-size: 98px;
	}
  
	.tx-md-98-f {
	  font-size: 98px !important;
	}
  
	.tx-md-100 {
	  font-size: 100px;
	}
  
	.tx-md-100-f {
	  font-size: 100px !important;
	}
  
	.tx-md-102 {
	  font-size: 102px;
	}
  
	.tx-md-102-f {
	  font-size: 102px !important;
	}
  
	.tx-md-104 {
	  font-size: 104px;
	}
  
	.tx-md-104-f {
	  font-size: 104px !important;
	}
  
	.tx-md-106 {
	  font-size: 106px;
	}
  
	.tx-md-106-f {
	  font-size: 106px !important;
	}
  
	.tx-md-108 {
	  font-size: 108px;
	}
  
	.tx-md-108-f {
	  font-size: 108px !important;
	}
  
	.tx-md-110 {
	  font-size: 110px;
	}
  
	.tx-md-110-f {
	  font-size: 110px !important;
	}
  
	.tx-md-112 {
	  font-size: 112px;
	}
  
	.tx-md-112-f {
	  font-size: 112px !important;
	}
  
	.tx-md-114 {
	  font-size: 114px;
	}
  
	.tx-md-114-f {
	  font-size: 114px !important;
	}
  
	.tx-md-116 {
	  font-size: 116px;
	}
  
	.tx-md-116-f {
	  font-size: 116px !important;
	}
  
	.tx-md-118 {
	  font-size: 118px;
	}
  
	.tx-md-118-f {
	  font-size: 118px !important;
	}
  
	.tx-md-120 {
	  font-size: 120px;
	}
  
	.tx-md-120-f {
	  font-size: 120px !important;
	}
  
	.tx-md-122 {
	  font-size: 122px;
	}
  
	.tx-md-122-f {
	  font-size: 122px !important;
	}
  
	.tx-md-124 {
	  font-size: 124px;
	}
  
	.tx-md-124-f {
	  font-size: 124px !important;
	}
  
	.tx-md-126 {
	  font-size: 126px;
	}
  
	.tx-md-126-f {
	  font-size: 126px !important;
	}
  
	.tx-md-128 {
	  font-size: 128px;
	}
  
	.tx-md-128-f {
	  font-size: 128px !important;
	}
  
	.tx-md-130 {
	  font-size: 130px;
	}
  
	.tx-md-130-f {
	  font-size: 130px !important;
	}
  
	.tx-md-132 {
	  font-size: 132px;
	}
  
	.tx-md-132-f {
	  font-size: 132px !important;
	}
  
	.tx-md-134 {
	  font-size: 134px;
	}
  
	.tx-md-134-f {
	  font-size: 134px !important;
	}
  
	.tx-md-136 {
	  font-size: 136px;
	}
  
	.tx-md-136-f {
	  font-size: 136px !important;
	}
  
	.tx-md-138 {
	  font-size: 138px;
	}
  
	.tx-md-138-f {
	  font-size: 138px !important;
	}
  
	.tx-md-140 {
	  font-size: 140px;
	}
  
	.tx-md-140-f {
	  font-size: 140px !important;
	}
  
	.tx-md-base {
	  font-size: 0.875rem;
	}
  
	.tx-md-bold {
	  font-weight: 700;
	}
  
	.tx-md-semibold {
	  font-weight: 600;
	}
  
	.tx-md-medium {
	  font-weight: 500;
	}
  
	.tx-md-normal {
	  font-weight: 400;
	}
  
	.tx-md-light {
	  font-weight: 300;
	}
  
	.tx-md-thin {
	  font-weight: 200;
	}
  
	.tx-md-xthin {
	  font-weight: 100;
	}
  
	.lh-md-0 {
	  line-height: 0;
	}
  
	.lh-md-1 {
	  line-height: 1.1;
	}
  
	.lh-md-2 {
	  line-height: 1.2;
	}
  
	.lh-md-3 {
	  line-height: 1.3;
	}
  
	.lh-md-4 {
	  line-height: 1.4;
	}
  
	.lh-md-5 {
	  line-height: 1.5;
	}
  
	.lh-md-6 {
	  line-height: 1.6;
	}
  
	.lh-md-7 {
	  line-height: 1.7;
	}
  
	.lh-md-8 {
	  line-height: 1.8;
	}
  
	.lh-md-9 {
	  line-height: 1.9;
	}
  
	.lh-md-10 {
	  line-height: 2.0;
	}
  
	.lh-md-11 {
	  line-height: 2.1;
	}
  
	.lh-md-12 {
	  line-height: 2.2;
	}
  
	.lh-md-13 {
	  line-height: 2.3;
	}
  
	.lh-md-14 {
	  line-height: 2.4;
	}
  
	.lh-md-15 {
	  line-height: 2.5;
	}
  
	.tx-md-center {
	  text-align: center;
	}
  
	.tx-md-right {
	  text-align: right;
	}
  
	.tx-md-left {
	  text-align: left;
	}
  
	.tx-md-center-f {
	  text-align: center !important;
	}
  
	.tx-md-right-f {
	  text-align: right !important;
	}
  
	.tx-md-left-f {
	  text-align: left !important;
	}
  }
  
  @media (min-width: 992px) {
	.tx-lg-8 {
	  font-size: 8px;
	}
  
	.tx-lg-8-f {
	  font-size: 8px !important;
	}
  
	.tx-lg-9 {
	  font-size: 9px;
	}
  
	.tx-lg-9-f {
	  font-size: 9px !important;
	}
  
	.tx-lg-10 {
	  font-size: 10px;
	}
  
	.tx-lg-10-f {
	  font-size: 10px !important;
	}
  
	.tx-lg-11 {
	  font-size: 11px;
	}
  
	.tx-lg-11-f {
	  font-size: 11px !important;
	}
  
	.tx-lg-12 {
	  font-size: 12px;
	}
  
	.tx-lg-12-f {
	  font-size: 12px !important;
	}
  
	.tx-lg-13 {
	  font-size: 13px;
	}
  
	.tx-lg-13-f {
	  font-size: 13px !important;
	}
  
	.tx-lg-14 {
	  font-size: 14px;
	}
  
	.tx-lg-14-f {
	  font-size: 14px !important;
	}
  
	.tx-lg-15 {
	  font-size: 15px;
	}
  
	.tx-lg-15-f {
	  font-size: 15px !important;
	}
  
	.tx-lg-16 {
	  font-size: 16px;
	}
  
	.tx-lg-16-f {
	  font-size: 16px !important;
	}
  
	.tx-lg-18 {
	  font-size: 18px;
	}
  
	.tx-lg-18-f {
	  font-size: 18px !important;
	}
  
	.tx-lg-20 {
	  font-size: 20px;
	}
  
	.tx-lg-20-f {
	  font-size: 20px !important;
	}
  
	.tx-lg-22 {
	  font-size: 22px;
	}
  
	.tx-lg-22-f {
	  font-size: 22px !important;
	}
  
	.tx-lg-24 {
	  font-size: 24px;
	}
  
	.tx-lg-24-f {
	  font-size: 24px !important;
	}
  
	.tx-lg-26 {
	  font-size: 26px;
	}
  
	.tx-lg-26-f {
	  font-size: 26px !important;
	}
  
	.tx-lg-28 {
	  font-size: 28px;
	}
  
	.tx-lg-28-f {
	  font-size: 28px !important;
	}
  
	.tx-lg-30 {
	  font-size: 30px;
	}
  
	.tx-lg-30-f {
	  font-size: 30px !important;
	}
  
	.tx-lg-32 {
	  font-size: 32px;
	}
  
	.tx-lg-32-f {
	  font-size: 32px !important;
	}
  
	.tx-lg-34 {
	  font-size: 34px;
	}
  
	.tx-lg-34-f {
	  font-size: 34px !important;
	}
  
	.tx-lg-36 {
	  font-size: 36px;
	}
  
	.tx-lg-36-f {
	  font-size: 36px !important;
	}
  
	.tx-lg-38 {
	  font-size: 38px;
	}
  
	.tx-lg-38-f {
	  font-size: 38px !important;
	}
  
	.tx-lg-40 {
	  font-size: 40px;
	}
  
	.tx-lg-40-f {
	  font-size: 40px !important;
	}
  
	.tx-lg-42 {
	  font-size: 42px;
	}
  
	.tx-lg-42-f {
	  font-size: 42px !important;
	}
  
	.tx-lg-44 {
	  font-size: 44px;
	}
  
	.tx-lg-44-f {
	  font-size: 44px !important;
	}
  
	.tx-lg-46 {
	  font-size: 46px;
	}
  
	.tx-lg-46-f {
	  font-size: 46px !important;
	}
  
	.tx-lg-48 {
	  font-size: 48px;
	}
  
	.tx-lg-48-f {
	  font-size: 48px !important;
	}
  
	.tx-lg-50 {
	  font-size: 50px;
	}
  
	.tx-lg-50-f {
	  font-size: 50px !important;
	}
  
	.tx-lg-52 {
	  font-size: 52px;
	}
  
	.tx-lg-52-f {
	  font-size: 52px !important;
	}
  
	.tx-lg-54 {
	  font-size: 54px;
	}
  
	.tx-lg-54-f {
	  font-size: 54px !important;
	}
  
	.tx-lg-56 {
	  font-size: 56px;
	}
  
	.tx-lg-56-f {
	  font-size: 56px !important;
	}
  
	.tx-lg-58 {
	  font-size: 58px;
	}
  
	.tx-lg-58-f {
	  font-size: 58px !important;
	}
  
	.tx-lg-60 {
	  font-size: 60px;
	}
  
	.tx-lg-60-f {
	  font-size: 60px !important;
	}
  
	.tx-lg-62 {
	  font-size: 62px;
	}
  
	.tx-lg-62-f {
	  font-size: 62px !important;
	}
  
	.tx-lg-64 {
	  font-size: 64px;
	}
  
	.tx-lg-64-f {
	  font-size: 64px !important;
	}
  
	.tx-lg-66 {
	  font-size: 66px;
	}
  
	.tx-lg-66-f {
	  font-size: 66px !important;
	}
  
	.tx-lg-68 {
	  font-size: 68px;
	}
  
	.tx-lg-68-f {
	  font-size: 68px !important;
	}
  
	.tx-lg-70 {
	  font-size: 70px;
	}
  
	.tx-lg-70-f {
	  font-size: 70px !important;
	}
  
	.tx-lg-72 {
	  font-size: 72px;
	}
  
	.tx-lg-72-f {
	  font-size: 72px !important;
	}
  
	.tx-lg-74 {
	  font-size: 74px;
	}
  
	.tx-lg-74-f {
	  font-size: 74px !important;
	}
  
	.tx-lg-76 {
	  font-size: 76px;
	}
  
	.tx-lg-76-f {
	  font-size: 76px !important;
	}
  
	.tx-lg-78 {
	  font-size: 78px;
	}
  
	.tx-lg-78-f {
	  font-size: 78px !important;
	}
  
	.tx-lg-80 {
	  font-size: 80px;
	}
  
	.tx-lg-80-f {
	  font-size: 80px !important;
	}
  
	.tx-lg-82 {
	  font-size: 82px;
	}
  
	.tx-lg-82-f {
	  font-size: 82px !important;
	}
  
	.tx-lg-84 {
	  font-size: 84px;
	}
  
	.tx-lg-84-f {
	  font-size: 84px !important;
	}
  
	.tx-lg-86 {
	  font-size: 86px;
	}
  
	.tx-lg-86-f {
	  font-size: 86px !important;
	}
  
	.tx-lg-88 {
	  font-size: 88px;
	}
  
	.tx-lg-88-f {
	  font-size: 88px !important;
	}
  
	.tx-lg-90 {
	  font-size: 90px;
	}
  
	.tx-lg-90-f {
	  font-size: 90px !important;
	}
  
	.tx-lg-92 {
	  font-size: 92px;
	}
  
	.tx-lg-92-f {
	  font-size: 92px !important;
	}
  
	.tx-lg-94 {
	  font-size: 94px;
	}
  
	.tx-lg-94-f {
	  font-size: 94px !important;
	}
  
	.tx-lg-96 {
	  font-size: 96px;
	}
  
	.tx-lg-96-f {
	  font-size: 96px !important;
	}
  
	.tx-lg-98 {
	  font-size: 98px;
	}
  
	.tx-lg-98-f {
	  font-size: 98px !important;
	}
  
	.tx-lg-100 {
	  font-size: 100px;
	}
  
	.tx-lg-100-f {
	  font-size: 100px !important;
	}
  
	.tx-lg-102 {
	  font-size: 102px;
	}
  
	.tx-lg-102-f {
	  font-size: 102px !important;
	}
  
	.tx-lg-104 {
	  font-size: 104px;
	}
  
	.tx-lg-104-f {
	  font-size: 104px !important;
	}
  
	.tx-lg-106 {
	  font-size: 106px;
	}
  
	.tx-lg-106-f {
	  font-size: 106px !important;
	}
  
	.tx-lg-108 {
	  font-size: 108px;
	}
  
	.tx-lg-108-f {
	  font-size: 108px !important;
	}
  
	.tx-lg-110 {
	  font-size: 110px;
	}
  
	.tx-lg-110-f {
	  font-size: 110px !important;
	}
  
	.tx-lg-112 {
	  font-size: 112px;
	}
  
	.tx-lg-112-f {
	  font-size: 112px !important;
	}
  
	.tx-lg-114 {
	  font-size: 114px;
	}
  
	.tx-lg-114-f {
	  font-size: 114px !important;
	}
  
	.tx-lg-116 {
	  font-size: 116px;
	}
  
	.tx-lg-116-f {
	  font-size: 116px !important;
	}
  
	.tx-lg-118 {
	  font-size: 118px;
	}
  
	.tx-lg-118-f {
	  font-size: 118px !important;
	}
  
	.tx-lg-120 {
	  font-size: 120px;
	}
  
	.tx-lg-120-f {
	  font-size: 120px !important;
	}
  
	.tx-lg-122 {
	  font-size: 122px;
	}
  
	.tx-lg-122-f {
	  font-size: 122px !important;
	}
  
	.tx-lg-124 {
	  font-size: 124px;
	}
  
	.tx-lg-124-f {
	  font-size: 124px !important;
	}
  
	.tx-lg-126 {
	  font-size: 126px;
	}
  
	.tx-lg-126-f {
	  font-size: 126px !important;
	}
  
	.tx-lg-128 {
	  font-size: 128px;
	}
  
	.tx-lg-128-f {
	  font-size: 128px !important;
	}
  
	.tx-lg-130 {
	  font-size: 130px;
	}
  
	.tx-lg-130-f {
	  font-size: 130px !important;
	}
  
	.tx-lg-132 {
	  font-size: 132px;
	}
  
	.tx-lg-132-f {
	  font-size: 132px !important;
	}
  
	.tx-lg-134 {
	  font-size: 134px;
	}
  
	.tx-lg-134-f {
	  font-size: 134px !important;
	}
  
	.tx-lg-136 {
	  font-size: 136px;
	}
  
	.tx-lg-136-f {
	  font-size: 136px !important;
	}
  
	.tx-lg-138 {
	  font-size: 138px;
	}
  
	.tx-lg-138-f {
	  font-size: 138px !important;
	}
  
	.tx-lg-140 {
	  font-size: 140px;
	}
  
	.tx-lg-140-f {
	  font-size: 140px !important;
	}
  
	.tx-lg-base {
	  font-size: 0.875rem;
	}
  
	.tx-lg-bold {
	  font-weight: 700;
	}
  
	.tx-lg-semibold {
	  font-weight: 600;
	}
  
	.tx-lg-medium {
	  font-weight: 500;
	}
  
	.tx-lg-normal {
	  font-weight: 400;
	}
  
	.tx-lg-light {
	  font-weight: 300;
	}
  
	.tx-lg-thin {
	  font-weight: 200;
	}
  
	.tx-lg-xthin {
	  font-weight: 100;
	}
  
	.lh-lg-0 {
	  line-height: 0;
	}
  
	.lh-lg-1 {
	  line-height: 1.1;
	}
  
	.lh-lg-2 {
	  line-height: 1.2;
	}
  
	.lh-lg-3 {
	  line-height: 1.3;
	}
  
	.lh-lg-4 {
	  line-height: 1.4;
	}
  
	.lh-lg-5 {
	  line-height: 1.5;
	}
  
	.lh-lg-6 {
	  line-height: 1.6;
	}
  
	.lh-lg-7 {
	  line-height: 1.7;
	}
  
	.lh-lg-8 {
	  line-height: 1.8;
	}
  
	.lh-lg-9 {
	  line-height: 1.9;
	}
  
	.lh-lg-10 {
	  line-height: 2.0;
	}
  
	.lh-lg-11 {
	  line-height: 2.1;
	}
  
	.lh-lg-12 {
	  line-height: 2.2;
	}
  
	.lh-lg-13 {
	  line-height: 2.3;
	}
  
	.lh-lg-14 {
	  line-height: 2.4;
	}
  
	.lh-lg-15 {
	  line-height: 2.5;
	}
  
	.tx-lg-center {
	  text-align: center;
	}
  
	.tx-lg-right {
	  text-align: right;
	}
  
	.tx-lg-left {
	  text-align: left;
	}
  
	.tx-lg-center-f {
	  text-align: center !important;
	}
  
	.tx-lg-right-f {
	  text-align: right !important;
	}
  
	.tx-lg-left-f {
	  text-align: left !important;
	}
  }
  
  @media (min-width: 1200px) {
	.tx-xl-8 {
	  font-size: 8px;
	}
  
	.tx-xl-8-f {
	  font-size: 8px !important;
	}
  
	.tx-xl-9 {
	  font-size: 9px;
	}
  
	.tx-xl-9-f {
	  font-size: 9px !important;
	}
  
	.tx-xl-10 {
	  font-size: 10px;
	}
  
	.tx-xl-10-f {
	  font-size: 10px !important;
	}
  
	.tx-xl-11 {
	  font-size: 11px;
	}
  
	.tx-xl-11-f {
	  font-size: 11px !important;
	}
  
	.tx-xl-12 {
	  font-size: 12px;
	}
  
	.tx-xl-12-f {
	  font-size: 12px !important;
	}
  
	.tx-xl-13 {
	  font-size: 13px;
	}
  
	.tx-xl-13-f {
	  font-size: 13px !important;
	}
  
	.tx-xl-14 {
	  font-size: 14px;
	}
  
	.tx-xl-14-f {
	  font-size: 14px !important;
	}
  
	.tx-xl-15 {
	  font-size: 15px;
	}
  
	.tx-xl-15-f {
	  font-size: 15px !important;
	}
  
	.tx-xl-16 {
	  font-size: 16px;
	}
  
	.tx-xl-16-f {
	  font-size: 16px !important;
	}
  
	.tx-xl-18 {
	  font-size: 18px;
	}
  
	.tx-xl-18-f {
	  font-size: 18px !important;
	}
  
	.tx-xl-20 {
	  font-size: 20px;
	}
  
	.tx-xl-20-f {
	  font-size: 20px !important;
	}
  
	.tx-xl-22 {
	  font-size: 22px;
	}
  
	.tx-xl-22-f {
	  font-size: 22px !important;
	}
  
	.tx-xl-24 {
	  font-size: 24px;
	}
  
	.tx-xl-24-f {
	  font-size: 24px !important;
	}
  
	.tx-xl-26 {
	  font-size: 26px;
	}
  
	.tx-xl-26-f {
	  font-size: 26px !important;
	}
  
	.tx-xl-28 {
	  font-size: 28px;
	}
  
	.tx-xl-28-f {
	  font-size: 28px !important;
	}
  
	.tx-xl-30 {
	  font-size: 30px;
	}
  
	.tx-xl-30-f {
	  font-size: 30px !important;
	}
  
	.tx-xl-32 {
	  font-size: 32px;
	}
  
	.tx-xl-32-f {
	  font-size: 32px !important;
	}
  
	.tx-xl-34 {
	  font-size: 34px;
	}
  
	.tx-xl-34-f {
	  font-size: 34px !important;
	}
  
	.tx-xl-36 {
	  font-size: 36px;
	}
  
	.tx-xl-36-f {
	  font-size: 36px !important;
	}
  
	.tx-xl-38 {
	  font-size: 38px;
	}
  
	.tx-xl-38-f {
	  font-size: 38px !important;
	}
  
	.tx-xl-40 {
	  font-size: 40px;
	}
  
	.tx-xl-40-f {
	  font-size: 40px !important;
	}
  
	.tx-xl-42 {
	  font-size: 42px;
	}
  
	.tx-xl-42-f {
	  font-size: 42px !important;
	}
  
	.tx-xl-44 {
	  font-size: 44px;
	}
  
	.tx-xl-44-f {
	  font-size: 44px !important;
	}
  
	.tx-xl-46 {
	  font-size: 46px;
	}
  
	.tx-xl-46-f {
	  font-size: 46px !important;
	}
  
	.tx-xl-48 {
	  font-size: 48px;
	}
  
	.tx-xl-48-f {
	  font-size: 48px !important;
	}
  
	.tx-xl-50 {
	  font-size: 50px;
	}
  
	.tx-xl-50-f {
	  font-size: 50px !important;
	}
  
	.tx-xl-52 {
	  font-size: 52px;
	}
  
	.tx-xl-52-f {
	  font-size: 52px !important;
	}
  
	.tx-xl-54 {
	  font-size: 54px;
	}
  
	.tx-xl-54-f {
	  font-size: 54px !important;
	}
  
	.tx-xl-56 {
	  font-size: 56px;
	}
  
	.tx-xl-56-f {
	  font-size: 56px !important;
	}
  
	.tx-xl-58 {
	  font-size: 58px;
	}
  
	.tx-xl-58-f {
	  font-size: 58px !important;
	}
  
	.tx-xl-60 {
	  font-size: 60px;
	}
  
	.tx-xl-60-f {
	  font-size: 60px !important;
	}
  
	.tx-xl-62 {
	  font-size: 62px;
	}
  
	.tx-xl-62-f {
	  font-size: 62px !important;
	}
  
	.tx-xl-64 {
	  font-size: 64px;
	}
  
	.tx-xl-64-f {
	  font-size: 64px !important;
	}
  
	.tx-xl-66 {
	  font-size: 66px;
	}
  
	.tx-xl-66-f {
	  font-size: 66px !important;
	}
  
	.tx-xl-68 {
	  font-size: 68px;
	}
  
	.tx-xl-68-f {
	  font-size: 68px !important;
	}
  
	.tx-xl-70 {
	  font-size: 70px;
	}
  
	.tx-xl-70-f {
	  font-size: 70px !important;
	}
  
	.tx-xl-72 {
	  font-size: 72px;
	}
  
	.tx-xl-72-f {
	  font-size: 72px !important;
	}
  
	.tx-xl-74 {
	  font-size: 74px;
	}
  
	.tx-xl-74-f {
	  font-size: 74px !important;
	}
  
	.tx-xl-76 {
	  font-size: 76px;
	}
  
	.tx-xl-76-f {
	  font-size: 76px !important;
	}
  
	.tx-xl-78 {
	  font-size: 78px;
	}
  
	.tx-xl-78-f {
	  font-size: 78px !important;
	}
  
	.tx-xl-80 {
	  font-size: 80px;
	}
  
	.tx-xl-80-f {
	  font-size: 80px !important;
	}
  
	.tx-xl-82 {
	  font-size: 82px;
	}
  
	.tx-xl-82-f {
	  font-size: 82px !important;
	}
  
	.tx-xl-84 {
	  font-size: 84px;
	}
  
	.tx-xl-84-f {
	  font-size: 84px !important;
	}
  
	.tx-xl-86 {
	  font-size: 86px;
	}
  
	.tx-xl-86-f {
	  font-size: 86px !important;
	}
  
	.tx-xl-88 {
	  font-size: 88px;
	}
  
	.tx-xl-88-f {
	  font-size: 88px !important;
	}
  
	.tx-xl-90 {
	  font-size: 90px;
	}
  
	.tx-xl-90-f {
	  font-size: 90px !important;
	}
  
	.tx-xl-92 {
	  font-size: 92px;
	}
  
	.tx-xl-92-f {
	  font-size: 92px !important;
	}
  
	.tx-xl-94 {
	  font-size: 94px;
	}
  
	.tx-xl-94-f {
	  font-size: 94px !important;
	}
  
	.tx-xl-96 {
	  font-size: 96px;
	}
  
	.tx-xl-96-f {
	  font-size: 96px !important;
	}
  
	.tx-xl-98 {
	  font-size: 98px;
	}
  
	.tx-xl-98-f {
	  font-size: 98px !important;
	}
  
	.tx-xl-100 {
	  font-size: 100px;
	}
  
	.tx-xl-100-f {
	  font-size: 100px !important;
	}
  
	.tx-xl-102 {
	  font-size: 102px;
	}
  
	.tx-xl-102-f {
	  font-size: 102px !important;
	}
  
	.tx-xl-104 {
	  font-size: 104px;
	}
  
	.tx-xl-104-f {
	  font-size: 104px !important;
	}
  
	.tx-xl-106 {
	  font-size: 106px;
	}
  
	.tx-xl-106-f {
	  font-size: 106px !important;
	}
  
	.tx-xl-108 {
	  font-size: 108px;
	}
  
	.tx-xl-108-f {
	  font-size: 108px !important;
	}
  
	.tx-xl-110 {
	  font-size: 110px;
	}
  
	.tx-xl-110-f {
	  font-size: 110px !important;
	}
  
	.tx-xl-112 {
	  font-size: 112px;
	}
  
	.tx-xl-112-f {
	  font-size: 112px !important;
	}
  
	.tx-xl-114 {
	  font-size: 114px;
	}
  
	.tx-xl-114-f {
	  font-size: 114px !important;
	}
  
	.tx-xl-116 {
	  font-size: 116px;
	}
  
	.tx-xl-116-f {
	  font-size: 116px !important;
	}
  
	.tx-xl-118 {
	  font-size: 118px;
	}
  
	.tx-xl-118-f {
	  font-size: 118px !important;
	}
  
	.tx-xl-120 {
	  font-size: 120px;
	}
  
	.tx-xl-120-f {
	  font-size: 120px !important;
	}
  
	.tx-xl-122 {
	  font-size: 122px;
	}
  
	.tx-xl-122-f {
	  font-size: 122px !important;
	}
  
	.tx-xl-124 {
	  font-size: 124px;
	}
  
	.tx-xl-124-f {
	  font-size: 124px !important;
	}
  
	.tx-xl-126 {
	  font-size: 126px;
	}
  
	.tx-xl-126-f {
	  font-size: 126px !important;
	}
  
	.tx-xl-128 {
	  font-size: 128px;
	}
  
	.tx-xl-128-f {
	  font-size: 128px !important;
	}
  
	.tx-xl-130 {
	  font-size: 130px;
	}
  
	.tx-xl-130-f {
	  font-size: 130px !important;
	}
  
	.tx-xl-132 {
	  font-size: 132px;
	}
  
	.tx-xl-132-f {
	  font-size: 132px !important;
	}
  
	.tx-xl-134 {
	  font-size: 134px;
	}
  
	.tx-xl-134-f {
	  font-size: 134px !important;
	}
  
	.tx-xl-136 {
	  font-size: 136px;
	}
  
	.tx-xl-136-f {
	  font-size: 136px !important;
	}
  
	.tx-xl-138 {
	  font-size: 138px;
	}
  
	.tx-xl-138-f {
	  font-size: 138px !important;
	}
  
	.tx-xl-140 {
	  font-size: 140px;
	}
  
	.tx-xl-140-f {
	  font-size: 140px !important;
	}
  
	.tx-xl-base {
	  font-size: 0.875rem;
	}
  
	.tx-xl-bold {
	  font-weight: 700;
	}
  
	.tx-xl-semibold {
	  font-weight: 600;
	}
  
	.tx-xl-medium {
	  font-weight: 500;
	}
  
	.tx-xl-normal {
	  font-weight: 400;
	}
  
	.tx-xl-light {
	  font-weight: 300;
	}
  
	.tx-xl-thin {
	  font-weight: 200;
	}
  
	.tx-xl-xthin {
	  font-weight: 100;
	}
  
	.lh-xl-0 {
	  line-height: 0;
	}
  
	.lh-xl-1 {
	  line-height: 1.1;
	}
  
	.lh-xl-2 {
	  line-height: 1.2;
	}
  
	.lh-xl-3 {
	  line-height: 1.3;
	}
  
	.lh-xl-4 {
	  line-height: 1.4;
	}
  
	.lh-xl-5 {
	  line-height: 1.5;
	}
  
	.lh-xl-6 {
	  line-height: 1.6;
	}
  
	.lh-xl-7 {
	  line-height: 1.7;
	}
  
	.lh-xl-8 {
	  line-height: 1.8;
	}
  
	.lh-xl-9 {
	  line-height: 1.9;
	}
  
	.lh-xl-10 {
	  line-height: 2.0;
	}
  
	.lh-xl-11 {
	  line-height: 2.1;
	}
  
	.lh-xl-12 {
	  line-height: 2.2;
	}
  
	.lh-xl-13 {
	  line-height: 2.3;
	}
  
	.lh-xl-14 {
	  line-height: 2.4;
	}
  
	.lh-xl-15 {
	  line-height: 2.5;
	}
  
	.tx-xl-center {
	  text-align: center;
	}
  
	.tx-xl-right {
	  text-align: right;
	}
  
	.tx-xl-left {
	  text-align: left;
	}
  
	.tx-xl-center-f {
	  text-align: center !important;
	}
  
	.tx-xl-right-f {
	  text-align: right !important;
	}
  
	.tx-xl-left-f {
	  text-align: left !important;
	}
  }