@import "../_variables";

.badge {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 3px 5px 4px;
  }
  
  .badge-light {
    background-color: $gray-300;
  }
  
  .badge-pill {
    padding-left: 8px;
    padding-right: 8px;
  }
  
  /*////////////////////badgelight//////////////////*/
  
  .badge-primary-transparent {
    color: $primary;
    background-color: rgb(217, 232, 254);
  }
  
  .badge-success-transparent {
    background-color: rgb(212, 242, 225) !important;
    color: $success;
  }
  
  .badge-teal-transparent {
    background-color: #d2f5f5 !important;
    color: $teal;
  }
  
  .badge-warning-transparent {
    background-color: rgba(255, 193, 7, 0.15) !important;
    color: #ffc107;
  }
  
  .badge-danger-transparent {
    color: $danger;
    background-color: #f9e2e2;
  }
  
  .badge-purple-transparent {
    color: $purple;
    background-color: #e1defe;
  }
  
  .badge-info-transparent {
    color: #0a7ffb;
    background-color: rgba(3, 85, 208, 0.1);
  }
  
  .badge-pink-transparent {
    color: $pink;
    background-color: rgb(254, 229, 241);
  }