
@import "../_variables";
/* ###### 3.12 Popover ###### */

.popover {
	font-size: 12px;
	padding: 0;
	border-radius: 0;
  }
  
  .popover-header {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
	color: $gray-800;
	letter-spacing: 0.5px;
	padding: 12px 15px;
	background-color: $white;
	border-color: rgba(28, 39, 60, 0.2);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
  
	&::before {
	  display: none !important;
	}
  }
  
  .popover-body {
	padding: 15px;
  
	p:last-child {
	  margin-bottom: 0;
	}
  }
  
  .popover-head-primary {
	.popover-header {
	  color: $white;
	  background-color: $primary;
	}
  
	&.bs-popover-top .arrow, &.bs-popover-auto[x-placement^="top"] .arrow {
	  bottom: -7px;
	}
  
	&.bs-popover-left .arrow, &.bs-popover-auto[x-placement^="left"] .arrow {
	  right: -7px;
	}
  
	&.bs-popover-right .arrow, &.bs-popover-auto[x-placement^="right"] .arrow {
	  left: -7px;
	}
  
	&.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	  border-bottom-color: $primary;
	}
  }
  
  .popover-head-secondary {
	.popover-header {
	  color: $white;
	  background-color: $primary;
	}
  
	&.bs-popover-top .arrow, &.bs-popover-auto[x-placement^="top"] .arrow {
	  bottom: -7px;
	}
  
	&.bs-popover-left .arrow, &.bs-popover-auto[x-placement^="left"] .arrow {
	  right: -7px;
	}
  
	&.bs-popover-right .arrow, &.bs-popover-auto[x-placement^="right"] .arrow {
	  left: -7px;
	}
  
	&.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	  border-bottom-color: $primary;
	}
  }
  
  .popover-head-primary, .popover-head-secondary {
	border: 0;
  
	.popover-header {
	  border: 0;
	}
  }
  
  .popover-head-primary .popover-body, .popover-head-secondary .popover-body {
	border: 1px solid rgba(28, 39, 60, 0.2);
	border-top-width: 0;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
  }
  
  .popover-head-primary {
	&.bs-popover-bottom .arrow::before, &.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	  display: none;
	}
  }
  
  .popover-head-secondary {
	&.bs-popover-bottom .arrow::before, &.bs-popover-auto[x-placement^="bottom"] .arrow::before {
	  display: none;
	}
  }
  
  .popover-primary {
	background-color: $primary;
	border-width: 0;
	padding: 15px;
  
	.popover-header {
	  background-color: transparent;
	  border-bottom-width: 0;
	  padding: 0 0 15px;
	  color: $white;
	}
  
	.popover-body {
	  padding: 0;
	  color: rgba(255, 255, 255, 0.75);
	}
  
	.arrow::before {
	  display: none;
	}
  
	&.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
	  border-top-color: $primary;
	}
  
	&.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	  border-bottom-color: $primary;
	}
  
	&.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
	  border-left-color: $primary;
	}
  
	&.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
	  border-right-color: $primary;
	}
  }
  
  .popover-secondary {
	background-color: $primary;
	border-width: 0;
	padding: 15px;
  
	.popover-header {
	  background-color: transparent;
	  border-bottom-width: 0;
	  padding: 0 0 15px;
	  color: $white;
	}
  
	.popover-body {
	  padding: 0;
	  color: rgba(255, 255, 255, 0.75);
	}
  
	.arrow::before {
	  display: none;
	}
  
	&.bs-popover-top .arrow::after, &.bs-popover-auto[x-placement^="top"] .arrow::after {
	  border-top-color: $primary;
	}
  
	&.bs-popover-bottom .arrow::after, &.bs-popover-auto[x-placement^="bottom"] .arrow::after {
	  border-bottom-color: $primary;
	}
  
	&.bs-popover-left .arrow::after, &.bs-popover-auto[x-placement^="left"] .arrow::after {
	  border-left-color: $primary;
	}
  
	&.bs-popover-right .arrow::after, &.bs-popover-auto[x-placement^="right"] .arrow::after {
	  border-right-color: $primary;
	}
  }
  
  .popover-static-demo {
	background-color: $gray-200;
	padding-top: 40px;
	padding-bottom: 40px;
	text-align: center;
  
	.popover {
	  z-index: 0;
	  opacity: 1;
	  position: relative;
	  display: inline-block;
	  margin: 0 10px;
	}
  
	.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
	  left: 50%;
	  margin-left: -5px;
	}
  
	.bs-popover-left, .bs-popover-auto[x-placement^="left"], .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
	  margin-top: 5px;
	}
  
	.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow, .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
	  top: 50%;
	  margin-top: -5px;
	}
  }